

import { SVGProps, memo } from 'react';

const Disgust = (props: SVGProps<SVGSVGElement>) => (

<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="20" cy="20" r="20" fill="#F7F37D"/>
<ellipse cx="20" cy="28" rx="9" ry="6" fill="#424242"/>
<path d="M7 12C7 12 10.5369 11.4777 12.3491 10.25C13.974 9.14914 15.6604 6.5 15.6604 6.5" stroke="#424242" stroke-width="2" stroke-linecap="round"/>
<path d="M34 12C34 12 30.4631 11.4777 28.6509 10.25C27.026 9.14914 25.3396 6.5 25.3396 6.5" stroke="#424242" stroke-width="2" stroke-linecap="round"/>
<path d="M10 14L16 18L9 22" stroke="#424242" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M30 14L24 18L31 22" stroke="#424242" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M15 22.9474C15 22.9474 17 22 20 22C23 22 25 22.9474 25 22.9474V40H15V22.9474Z" fill="#65DD32"/>
<path d="M15 23C15 23 17 22 20 22C23 22 25 23 25 23V25.5H15L15 23Z" fill="#4EBD1F"/>
<rect x="8" y="35" width="24" height="5" rx="2.5" fill="#65DD32"/>
<circle cx="9" cy="32" r="2" fill="#65DD32"/>
<circle cx="5" cy="37" r="1" fill="#65DD32"/>
<circle cx="2" cy="2" r="2" transform="matrix(-1 0 0 1 33 30)" fill="#65DD32"/>
<circle cx="1" cy="1" r="1" transform="matrix(-1 0 0 1 36 36)" fill="#65DD32"/>
</svg>

);

export default memo(Disgust);