import { Modal, Box, Typography, Stack, Button, CircularProgress, Backdrop } from '@mui/material';

const ConfirmDeleteModal = ({
  showDeleteDialog,
  handleCloseClick,
  handleConfirmedDeleteClick,
  isDeleting
}) => {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'calc(100% - 32px)',
    margin: 'auto',
    maxWidth: '600px',
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: '10px',
    p: 4,
  };

  return (
    <Modal
      sx={{ padding: '16px' }}
      open={showDeleteDialog}
      slots={{ backdrop: Backdrop }}
      slotProps={{ backdrop: { timeout: 500 } }}
    >
      <Box sx={style} display='flex' flexDirection='column' justifyContent='center' gap='20px'>
        <Box>
          <strong>Delete Journal Entry?</strong>
          <Typography variant='body2'>
            Are you sure you want to permanently delete this journal entry?
          </Typography>
        </Box>
        <Stack direction='row' justifyContent='flex-end' spacing={1.25} paddingTop='32px'>
          <Button color='error' variant='contained' onClick={handleConfirmedDeleteClick} disabled={isDeleting}>
            Delete
            {isDeleting && <CircularProgress size={18} sx={{ position: 'absolute' }} />}
          </Button>
          <Button
            variant='contained'
            sx={{ borderRadius: '30px', height: '40px !important' }}
            onClick={handleCloseClick}
            disabled={isDeleting}
          >
            No
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
};

export default ConfirmDeleteModal;
