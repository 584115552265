import { Box, Button, LinearProgress, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useAuth } from "../../hooks/useAuth";
import { grey } from "@mui/material/colors";
import { Permission } from "../../config/roles";
import { TierContext } from "../../context/TierContext";
import { Timestamp } from 'firebase/firestore';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { USER_TYPE } from "../../config/const";

const ChatUsage: React.FC = () => {
  const [tierName, setTierName] = useState<string>('Free Tier');
  const [usage, setUsage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(500);
  const [upgradeTier, setUpgradeTier] = useState<Permission>("limit:chatLimitStandard")
  const { authUser } = useAuth();
  const { checkPermission } = useContext(TierContext);

  useEffect(() => {
    // // Set Usage Limit
    if (authUser?.subscription?.pricing_plan) {
      // check expiration
      if (authUser.subscription.pricing_plan.expiration_date >= Timestamp.fromDate(new Date())){
        switch (authUser.subscription.pricing_plan.tier){
          case 100:
            setTierName('Free Tier')
            setUpgradeTier("limit:chatLimitStandard")
            setLimit(500)
            break;
          case 1000:
            setTierName('Standard Tier')
            setUpgradeTier("limit:chatLimitPremium")
            setLimit(1500)
            break;
          case 2000:
            setTierName('Premium Tier')
            setUpgradeTier("limit:chatLimitUltimate")
            setLimit(5000)
            break;
          case 3000:
            setTierName('Ultimate Tier')
            setUpgradeTier(null)
            setLimit(50000)
            break;
          case 4000:
            setTierName('Developer')
            setUpgradeTier(null)
            setLimit(50000)
            break;
          default:
            setTierName('Free Tier')
            setLimit(500)
        }
      }
    } else {
      setTierName('Free Tier')
      setLimit(500)
    }
    // Set Actual Usage
    if (authUser?.chat_usage){
      // check if last_updated is more than 1 month away from expiration
      if (!false){
        setUsage(authUser.chat_usage.amount || 0)
      } else {
        setUsage(0)
      }
    }
  }, [authUser])

  const usageText = () => {
    return (usage > 1000 ?
      (limit > 1500 ?
        (Math.floor(usage/1000).toString() + 'K') :
        ((Math.floor(usage/100)/10).toString() + 'K')) :
      usage)
  }

  const limitText = () => {
    switch (limit) {
      case (500):
        return '500';
      case (1500):
        return '1.5K';
      case (5000):
        return '5K';
      case (50000):
        return '50K';
      case (500000):
        return 'unlimited';
      default:
        return '500';
    }
  }

  return (
    (authUser?.userType !== USER_TYPE.CREATOR) ?
    <></> :
    <Box
      display='flex'
      flexDirection='row'
      py={1}
      px={2}
      alignItems={'center'}
      justifyContent={'space-between'}
      sx={{
        width: '100%',
        backgroundColor: (theme) => theme.palette.background["paper-elevation-7"],
        maxWidth: '100%',
        overflow: 'hidden',
      }}
    >
      <Box
        flexGrow={1}
        display='flex'
        flexDirection='column'
        gap={0.5}
        py={0.5}
        sx={{
          maxWidth: 'calc(100% - 55px)',
          overflow: 'hidden',
        }}
      >
        <Typography
          variant='body3'
          sx={{
            color: (theme) => theme.palette.text.secondary
          }}
        >
          {tierName}
        </Typography>
        <LinearProgress
          variant="determinate"
          value={Math.min(usage/limit, 1)*100}
          sx={{
            height: 6,
            backgroundColor: grey[400],
            borderRadius: 8,
            '& .MuiLinearProgress-bar':{
              backgroundColor: (usage/limit) <= 0.5 ? 'green' : ((usage/limit) <= 0.8 ? 'orange' : 'red'),
              borderRadius: 8,
            },
          }}
        />
        <Typography
          variant='body3'
          textOverflow='ellipsis'
          whiteSpace='nowrap'
          sx={{
            color: (theme) => theme.palette.text.secondary,
            overflow: 'hidden',
          }}
        >
          {`${usageText()} of ${limitText()} chat responses`}
        </Typography>
      </Box>
      {
        <Button
          variant='contained'
          sx={{
            ml: 1,
            padding: '6px 10px !important',
            minWidth: '40px !important',
            boxShadow: (theme) => `${theme.shadows[2]} !important`,
            '& span.MuiButton-startIcon': {
              m: 0,
            },
          }}
          onClick={() => {
            if (upgradeTier) {
              checkPermission(upgradeTier)
            } else {
              checkPermission('justOpen')
            }
          }}
          startIcon={ <SettingsOutlinedIcon sx={{fontSize: '21px'}}/> }
        >
        </Button>
      }
    </Box>
  )
}

export default ChatUsage;
