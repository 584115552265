import { Box, Stack, Typography, Button, useTheme } from "@mui/material";
import Aristo from "../../svg/Aristo";
import JournalIcon2 from "../../svg/JournalIcon2";

const EmptyListPage = ({ onNewEntryClick }) => {
  const theme = useTheme();

  return (
      <Stack
        direction="column"
        sx={{
          width: "100%",
          maxWidth: "360px",
          px: 2,
          py: 4,
          textAlign: "center",
          overflowY: "auto",
          maxHeight: "100vh",
          margin: 'auto',
        }}
      >
        <Box sx={{ width: "100%", margin: 'auto' }}>
        <Aristo/>
        </Box>

        <Typography variant="h6" color="primary" sx={{ width: "100%", mt: 2 }}>
          Train your AI Persona by journaling!
        </Typography>

        <Typography variant="body1" color="text.secondary" sx={{ width: "100%" }}>
          Journal entries train your AI persona when you don’t have a document ready to go.
        </Typography>

        <Typography variant="body1" color="text.secondary" sx={{ width: "100%" }}>
          Write about anything to give your AI persona insights into your life.
        </Typography>

        <Button
          variant="contained"
          color="primary"
          size="medium"
          startIcon={
            <JournalIcon2
              width="16px"
              height="16px"
              stroke={theme.palette.primary.contrastText}
            />
          }
          onClick={onNewEntryClick}
          sx={{ mt: 2, alignSelf: "center", width: "auto", px: 2  }}
        >
          New Entry
        </Button>
      </Stack>
  );
};

export default EmptyListPage;
