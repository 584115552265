import React, {
  useRef,
  useState,
} from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';

import { auth } from '../../utils/firebase';

import '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css';
import {
  Avatar,
  Box,
  Grid,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import WidgetLoading from '../../assets/animations/widgetLoading.json';

import Lottie from 'lottie-react';
import { toast } from 'react-toastify';

import { useProfileData } from '../../hooks/useProfileData';
import { useAuth } from '../../hooks/useAuth';
import { UserFormType } from '../creatorOnBoarding/signup.types';
import SignInSignUpModalForWidget from '../chatWidget/signSignUpModalForWidget';
import StanfordLogo from '../../assets/images/Stanfordlogo.png';
import PremiumCreatorInfo from '../../component/premiumCreatorInfo';
import { Timestamp } from 'firebase/firestore';
import MainChatInterface from './mainChatInterface';
interface ChatScreenProps {
  isWidget?: boolean;
  handleSignInModalWidget?: boolean;
  setHandleSignInModalWidget?: React.Dispatch<React.SetStateAction<boolean>>;
  setWidgetVoiceMode?: React.Dispatch<React.SetStateAction<boolean>>;
  shouldStopAudio?: boolean;
  setShouldStopAudio?: React.Dispatch<React.SetStateAction<boolean>>;
}

const ChatScreen: React.FC<ChatScreenProps> = (props) => {
  const {
    isWidget = false,
    handleSignInModalWidget,
    setHandleSignInModalWidget,
    setWidgetVoiceMode,
    shouldStopAudio = false,
    setShouldStopAudio,
  } = props;
  const { userName: userNameParam } = useParams();
  const userName = userNameParam?.toLowerCase();

  const location = useLocation();
  const navigate = useNavigate();

  const [showVerifyEmail, setShowVerifyEmail] = useState<boolean>(false);
  const [value, setValue] = React.useState('RFIs');

  const containerRef = useRef(null);

  const {
    authUser,
    authLoading,
    isAnonymousUser,
    setIsAnonymousUser,
    setAnonymousChat,
    resetAnonymousData,
    setResetAnonymousData,
    setLoginModalVisible,
  } = useAuth();
  const {
    fetchProfileDataFromUserName,
    creatorProfileData,
  } = useProfileData();

  const [requiredPremiumKey, setRequiredPremiumKey] = useState<string>(
    creatorProfileData?.subscription?.custom_product_id || '');
  const [premiumAccess, setPremiumAccess] = useState<boolean>(false);

  const hasLoadedCreatorProfile: () => boolean = () => {
    return (creatorProfileData?.userName || "").toLowerCase() === userName?.toLowerCase()
  }

  React.useEffect(() => {
    if (!authLoading && userName ) {
      if (!hasLoadedCreatorProfile()) {
        checkValidFanUserAndFetchCreatorProfileData();
      } else {
        checkValidFanUser();
      }
    }
  }, [authUser, userName, authLoading, creatorProfileData]); //eslint-disable-line react-hooks/exhaustive-deps


  React.useEffect(() => {
    // Check the current path and update the radio selection accordingly
    if (location.pathname === '/sambatv/chat') {
      setValue('rfis');
    } else if (location.pathname === '/sambatv-mar/chat') {
      setValue('communications');
    } else if (location.pathname === '/ronjon/chat') {
      setValue('Ronjon');
    } else if (location.pathname === '/sortino-chat/chat') {
      setValue('Sortino');
    } else if (location.pathname === '/carlos_seligo/chat') {
      setValue('Carlos');
    }
  }, [location.pathname]);

  //verify if the user is logged in and email is not verified then return to verify email
  // or username not exists then redirect to create-profile screen
  React.useEffect(() => {
    if (authLoading) {
      return;
    }
    if (
      authUser?.documentId &&
      auth?.currentUser?.emailVerified !== undefined &&
      auth.currentUser.emailVerified === false
    ) {
      if (isWidget) {
        setShowVerifyEmail(true);
        setHandleSignInModalWidget(true);
      } else {
        toast.error('Please verify your email to continue');
        navigate('/verify-email');
      }
    } else if (!isWidget && authUser?.documentId && !authUser?.userName) {
      navigate('/create-profile');
    }
  }, [authUser, auth?.currentUser, authLoading]); //eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (resetAnonymousData) {
      setHandleSignInModal(false);
      setIsAnonymousUser(false);
      setAnonymousChat(null);
      setResetAnonymousData(false);
    }
  }, [resetAnonymousData]); //eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    // set requiredPremiumKey information
    if (!isWidget && !isStanfordUrl && !isSambaTvUrl) {
      if (creatorProfileData?.subscription?.custom_product_id) {
        let premiumKey = creatorProfileData.subscription.custom_product_id;
        setRequiredPremiumKey(premiumKey);
        let access = false;
        if (creatorProfileData?.userName === authUser?.userName) {
          access = true;
        } else if (authUser?.premiumPurchases) {
          let currentTimeStamp = Timestamp.fromDate(new Date());
          if (authUser.premiumPurchases[premiumKey]) {
            // check if not expired
            if (authUser.premiumPurchases[premiumKey] > currentTimeStamp) {
              access = true;
            }
          }
        }
        setPremiumAccess(access);
      } else {
        setRequiredPremiumKey('');
        setPremiumAccess(false);
      }
    } else {
      setRequiredPremiumKey('');
      setPremiumAccess(false);
    }
  }, [creatorProfileData, authUser]); //eslint-disable-line react-hooks/exhaustive-deps


  const checkValidFanUserAndFetchCreatorProfileData = async () => {
    await fetchProfileDataFromUserName(userName, true, true, navigate);
    if (isAnonymousUser !== !authUser) setIsAnonymousUser(!authUser);
  };

  const checkValidFanUser = async () => {
    if (isAnonymousUser !== !authUser) setIsAnonymousUser(!authUser);
  }

  const setHandleSignInModal = (state: boolean) => {
    if (state) {
      setLoginModalVisible({
        isOpen: true,
        formType: 'signin',
        currentStep: 1,
      })
    } else {
      setLoginModalVisible({
        isOpen: false,
        formType: 'signin',
        currentStep: 1,
      })
    }
  }

  // Check if the URL starts with 'sambatv.mimio.ai'
  const isSambaTvUrl =
    window.location.href.startsWith('https://sambatv.mimio.ai') &&
    window.innerWidth >= 968;

  // Check if the URL starts with 'stanford.edu'
  const isStanfordUrl =
    window.location.href.startsWith('https://stanford.soopra.ai') &&
    window.innerWidth >= 968;

  const handleChange = (event, newValue) => {
    setValue(newValue);

    // Navigate based on the value or update the state of your app
    switch (newValue) {
      case 'ronjon':
        navigate('/ronjon/chat');
        break;
      case 'sortino':
        navigate('/sortino-chat/chat');
        break;
      case 'carlos':
        navigate('/carlos_seligo/chat');
        break;
      case 'rfis':
        navigate('/sambatv/chat');
        break;
      case 'communications':
        navigate('/sambatv-mar/chat');
        break;
      // ... handle other cases
      default:
        break;
    }
  };

  const tabBackgroundColor = (text) => {
    if (
      (text.toLowerCase() === 'ronjon' &&
        location.pathname === '/ronjon/chat') ||
      (text.toLowerCase() === 'sortino' &&
        location.pathname === '/sortino-chat/chat') ||
      (text.toLowerCase() === 'carlos' &&
        location.pathname === '/carlos_seligo/chat')
    ) {
      return '#8C1515';
    }
    return value === text.toLowerCase() ? '#00B1FF' : 'inherit';
  };

  return (
    <Box
      className='messages-section'
      sx={
        isWidget
          ? {
              height: '100%',
              width: '100%',
              background: 'white',
              marginTop: '40px',
              overflow: 'hidden',
            }
          : {
            width: '100%',
            height: '100%',
            overflow: 'hidden',
          }
      }
    >
      <Box
        display='flex'
        alignItems={'stretch'}
        sx={{
          height: '100%',
          width: '100%',
          flexDirection: {xs: 'column', md: 'row'}
        }}
      >
        {/* Creator Info Side */}
        <Box
          sx={{
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
            backgroundColor:
              isSambaTvUrl || isStanfordUrl ? '#F6F6F6' : 'inherit',
            zIndex: 500,
            maxHeight: { xs: '66px', md: '100%' },
            width: {xs: '100%', md: '226px'},
            minWidth: {md: '226px'},
          }}
        >
          {/* for samba and stanford. 2.55 */}
          {(isSambaTvUrl || isStanfordUrl) && (
            <>
              {!isStanfordUrl && !isWidget && (
                <Box
                  sx={{
                    position: 'fixed',
                    display: 'flex',
                    justifyContent: 'center',
                    width: '15%',
                  }}
                >
                  <UserInfoHeader
                    userData={creatorProfileData}
                    isSambaTvUrl={isSambaTvUrl}
                    isStanfordUrl={false}
                    isWidget={isWidget}
                  />
                </Box>
              )}
              {!isStanfordUrl && (
                <Box
                  sx={{
                    position: 'fixed',
                    display: 'flex',
                    justifyContent: 'center',
                    width: '15%',
                  }}
                >
                  <UserInfoHeader
                    userData={creatorProfileData}
                    isSambaTvUrl={isSambaTvUrl}
                    isStanfordUrl={isStanfordUrl}
                    isWidget={isWidget}
                  />
                </Box>
              )}
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  marginTop: isStanfordUrl ? '0' : '175px',
                }}
              >
                {!isStanfordUrl && (
                  <Typography sx={{}}>__________________</Typography>
                )}

                <Typography
                  sx={{
                    fontSize: '1rem',
                    fontWeight: 'bold',
                    pt: 4,
                    pb: 4,
                    mr: 2,
                    color: 'black',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  {isStanfordUrl && (
                    <img
                      src={StanfordLogo}
                      alt='Stanford Logo'
                      style={{ paddingRight: '6px', height: '3.5rem' }}
                    />
                  )}
                  {isSambaTvUrl ? 'SambaTV Personas' : 'Stanford University'}
                </Typography>
                <Tabs
                  orientation='vertical'
                  variant='scrollable'
                  value={value}
                  onChange={handleChange}
                  aria-label='Stanford Personas'
                  sx={{
                    borderRadius: '10px',
                    border: '0px solid grey',
                    width: '90%',
                    '& .Mui-selected': {
                      color: 'white',
                      backgroundColor: isStanfordUrl ? '#8C1515' : '#00B1FF',
                    },
                    '& .MuiTabs-indicator': {
                      backgroundColor: 'transparent',
                    },
                  }}
                >
                  {isSambaTvUrl
                    ? ['RFIs', 'Communications']
                    : [
                        {
                          text: 'Ronjon',
                          profilePic:
                            'https://firebasestorage.googleapis.com/v0/b/mimio---db.appspot.com/o/images%2FerWA7J0RzebLjC3nddYqMneVGd23%2Fronjon.webp?alt=media&token=6efc192f-750e-4df5-9927-3e5d1c1b0c7b', // Hardcoded profilePic URL for Ronjon
                          fullName: 'Ronjon Nag',
                          userName: 'ronjon',
                        },
                        {
                          text: 'Sortino',
                          profilePic:
                            'https://firebasestorage.googleapis.com/v0/b/mimio---db.appspot.com/o/images%2FEdPwrOi2oyXchOxXldvc8jY1FBm2%2Fprofile_pic.jpg?alt=media&token=822c8d4a-565e-4a89-bf8f-5e5630385cf5', // Dynamic profilePic URL for Sortino
                          fullName: 'Frank Sortino',
                          userName: 'sortino-chat',
                        },
                        {
                          text: 'Carlos',
                          profilePic:
                            'https://firebasestorage.googleapis.com/v0/b/mimio---db.appspot.com/o/images%2Fp3Kj2GPP8oQJUzkQeDS9RUEwn1U2%2Fprofile_pic.png?alt=media&token=cb01e471-2515-46e8-9897-8125fb8ababe', // Dynamic profilePic URL for Carlos
                          fullName: 'Carlos Seligo',
                          userName: 'carlos_seligo',
                        },
                      ].map((item, index, arr) => (
                        <Tab
                          key={item.text}
                          label={
                            <Grid
                              container
                              alignItems='center'
                              justifyContent='center'
                              wrap='nowrap'
                              sx={{ width: '100%' }}
                            >
                              <Grid item>
                                <Avatar
                                  src={item.profilePic}
                                  alt={item.fullName}
                                  sx={{ width: 40, height: 40, mr: 1 }}
                                />
                              </Grid>
                              <Grid
                                item
                                sx={{ flexGrow: 1, overflow: 'hidden' }}
                              >
                                <Typography
                                  variant='body1'
                                  noWrap
                                  sx={{
                                    color:
                                      tabBackgroundColor(item.text) ===
                                      '#8C1515'
                                        ? 'white'
                                        : 'black',
                                    textTransform: 'none',
                                  }}
                                >
                                  {item.fullName}
                                </Typography>
                                <Typography
                                  variant='body2'
                                  color='textSecondary'
                                  noWrap
                                  sx={{
                                    color:
                                      tabBackgroundColor(item.text) ===
                                      '#8C1515'
                                        ? 'white'
                                        : 'inherit',
                                  }}
                                >
                                  @{item.userName}
                                </Typography>
                              </Grid>
                            </Grid>
                          }
                          value={item.text.toLowerCase()}
                          sx={{
                            justifyContent: 'flex-start',
                            textAlign: 'left',
                            alignItems: 'center',
                            p: 1,
                            minHeight: '48px',
                            backgroundColor: tabBackgroundColor(item.text),
                            borderBottom: index === 0 ? '1px' : 'none',
                            borderRadius: '10px 10px 10px 10px',
                          }}
                        />
                      ))}
                </Tabs>
              </Box>
            </>
          )}

          {
            !isSambaTvUrl &&
            !isStanfordUrl &&
            !isWidget &&
            <PremiumCreatorInfo
              anchorEl={containerRef.current}
              requiredPremiumKey={requiredPremiumKey}
              premiumAccess={premiumAccess}
              setShowLogin={setHandleSignInModal}
            />
          }
        </Box>

        {/* Chat Section */}
        <MainChatInterface
          hasLoadedCreatorProfile={hasLoadedCreatorProfile}
          containerRef={containerRef}
          isWidget={isWidget}
          handleSignInModalWidget={handleSignInModalWidget}
          setHandleSignInModalWidget={setHandleSignInModalWidget}
          shouldStopAudio={shouldStopAudio}
          setShouldStopAudio={setShouldStopAudio}
          requiredPremiumKey={requiredPremiumKey}
          premiumAccess={premiumAccess}
        />
      </Box>

      {
        isWidget &&
          <SignInSignUpModalForWidget
            handleSignInModal={handleSignInModalWidget}
            setHandleSignInModal={setHandleSignInModalWidget}
            showVerifyEmail={showVerifyEmail}
            setShowVerifyEmail={setShowVerifyEmail}
          />
      }
    </Box>
  );
};

export default ChatScreen;

interface UserInfoHeaderProps {
  userData: UserFormType;
  isSambaTvUrl: boolean;
  isStanfordUrl: boolean;
  isWidget: boolean;
}

export const UserInfoHeader: React.FC<UserInfoHeaderProps> = React.memo((props) => {
  let { userData, isSambaTvUrl, isStanfordUrl, isWidget } = props;
  if (!userData?.userName) {
    return <></>;
  }
  // If on SambaTV URL or Stanford URL, render vertical layout
  if (isSambaTvUrl || isStanfordUrl) {
    return (
      <Box
        className={isWidget ? 'chat-widget-profile-header' : ''}
        mt={1}
        display='flex'
        flexDirection='column'
        alignItems='center'
        sx={{ marginLeft: '20px' }}
      >
        {
          <Link
            id={`href-user-profile-${userData?.userName}`}
            to={`/${userData?.userName}`}
            style={{
              cursor: 'pointer',
            }}
          >
            <Avatar
              src={userData?.profilePhotoURL}
              alt={userData?.fullName}
              sx={{ m: 1, width: 70, height: 70, fontSize: '1.25rem' }}
            >
              {userData?.fullName ? userData?.fullName[0] : ''}
            </Avatar>
          </Link>
        }
        {
          <Link
            id={`href-user-profile-${userData?.userName}`}
            to={`/${userData?.userName}`}
            style={{
              cursor: 'pointer',
            }}
          >
            <Typography
              component='h6'
              variant='h5'
              fontWeight={400}
              fontSize={'1.1rem'}
            >
              {userData?.fullName ? (
                <>
                  {userData.fullName.split(' ')[0]}
                  <br />
                  {userData.fullName.split(' ')[1]}
                </>
              ) : (
                ''
              )}
            </Typography>
          </Link>
        }
        {
          <Link
            id={`href-user-profile-${userData?.userName}`}
            to={`/${userData?.userName}`}
            style={{
              cursor: 'pointer',
            }}
          >
            <Typography
              component='h6'
              variant='body2'
              fontWeight={400}
              color={'#808080'}
            >
              {`@${userData?.userName || ''}`}
            </Typography>
          </Link>
        }
      </Box>
    );
  } else {
    // If not on SambaTV URL or Stanford URL, render horizontal layout (as is)
    return (
      <Box
        mt={1}
        height={isWidget ? '270px' : 'auto'}
        display='flex'
        alignItems='center'
      >
        {isWidget ? (
          <Box position='absolute' left='0px' width='100%'>
            <Lottie
              animationData={WidgetLoading}
              loop={true}
              style={{
                width: '100%',
              }}
            />
          </Box>
        ) : (
          <></>
        )}

        {
          <Link
            id={`href-user-profile-${userData?.userName}`}
            to={`/${userData?.userName}`}
            style={{
              cursor: 'pointer',
            }}
          >
            <Avatar
              src={userData?.profilePhotoURL}
              alt={userData?.fullName}
              sx={
                isWidget
                  ? {
                      m: 1,
                      width: 98,
                      height: 98,
                      fontSize: '3rem',
                      zIndex: 2,
                      boxShadow: '0px 1px 4px 0px rgba(0, 0, 0, 0.25)',
                    }
                  : { m: 1, width: 40, height: 40, fontSize: '1.25rem' }
              }
            >
              {userData?.fullName ? userData?.fullName[0] : ''}
            </Avatar>
          </Link>
        }
        {isWidget ? (
          <></>
        ) : (
          <Box>
            {
              <Link
                id={`href-user-profile-${userData?.userName}`}
                to={`/${userData?.userName}`}
                style={{
                  cursor: 'pointer',
                }}
              >
                <Typography
                  component='h6'
                  variant='h5'
                  fontWeight={400}
                  fontSize={'1.1rem'}
                >
                  {userData?.fullName || ''}
                </Typography>
              </Link>
            }
            {
              <Link
                id={`href-user-profile-${userData?.userName}`}
                to={`/${userData?.userName}`}
                style={{
                  cursor: 'pointer',
                }}
              >
                <Typography
                  component='h6'
                  variant='body2'
                  fontWeight={400}
                  color={'#808080'}
                >
                  {`@${userData?.userName || ''}`}
                </Typography>
              </Link>
            }
          </Box>
        )}
      </Box>
    );
  }
});
