import { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const DetectScreenSize = () => {
  const theme = useTheme();

  const isXs = useMediaQuery(theme.breakpoints.down('sm'));
  const isSm = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const isMd = useMediaQuery(theme.breakpoints.between('md', 'lg'));
  const isLg = useMediaQuery(theme.breakpoints.between('lg', 'xl'));
  const isXl = useMediaQuery(theme.breakpoints.up("xl"));

  const getSize = () => {
    if (isXs) return 'xs';
    if (isSm) return 'sm';
    if (isMd) return 'md';
    if (isLg) return 'lg';
    if (isXl) return "xl";
    return 'xl';
  };

  const [screenSize, setScreenSize] = useState(getSize);

  useEffect(() => {
    setScreenSize(getSize());
  }, [isXs, isSm, isMd, isLg, , isXl]);

  return screenSize;
};

export default DetectScreenSize;
