import { Stack, Typography, Button, IconButton, useTheme, CircularProgress } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DeleteIcon from '@mui/icons-material/Delete';

const EditEntryHeader = ({
  handleBackClick,
  isSaveEnabled,
  isSaving,
  handleSave,
}) => {
  const theme = useTheme();

  return (
    <Stack
      direction='row'
      alignItems='center'
      justifyContent='space-between'
      sx={{ width: '100%', height: '56px', px: '20px', position: 'relative' }}
    >
      <IconButton
        onClick={handleBackClick}
        sx={{
          color: 'primary.main',
          backgroundColor: 'secondary.light',
          '&:hover': {
            backgroundColor: 'secondary.main',
          },
        }}
      >
        <ArrowBackIcon />
      </IconButton>

      <Typography
        variant='h7'
        color='text.primary'
        sx={{
          position: 'absolute',
          left: '50%',
          transform: 'translateX(-50%)',
          whiteSpace: 'nowrap',
        }}
      >
        Edit Entry
      </Typography>

      <Button
        variant='contained'
        color='primary'
        size='medium'
        disabled={!isSaveEnabled || isSaving} 
        onClick={handleSave} 
      >
        Save
        {isSaving && (
          <CircularProgress size={18} sx={{ position: 'absolute' }} />
        )}
      </Button>
    </Stack>
  );
};

export default EditEntryHeader;
