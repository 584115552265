import { useEffect, useRef } from 'react';

/**
 * InfiniteScroll component that triggers a callback when the user scrolls to the bottom
 * of the content.
 * 
 * @param {Object} props
 * @param {Function} props.onReachBottom - Callback function to call when bottom is reached
 * @param {boolean} props.isLoading - Whether data is currently being loaded
 * @param {boolean} props.isFullyLoaded - Whether all data has been loaded
 * @param {number} props.rootMargin - Distance in pixels from the bottom to trigger the callback
 * @param {string} props.className - Optional CSS class for the bottom element
 * @returns {JSX.Element}
 */
const DetectBottomScrollDiv = ({
  onReachBottom,
  isLoading = false,
  isFullyLoaded = false,
  rootMargin = '400px',
  className = 'infinite-scroll-bottom',
}) => {
  const bottomRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && !isLoading && !isFullyLoaded) {
          onReachBottom();
        }
      },
      {
        rootMargin,
      }
    );
    
    if (bottomRef.current) {
      observer.observe(bottomRef.current);
    }
    
    return () => {
      if (bottomRef.current) {
        observer.unobserve(bottomRef.current);
      }
    };
  }, [onReachBottom, isLoading, isFullyLoaded, rootMargin]);

  return <div className={className} ref={bottomRef} />;
};

export default DetectBottomScrollDiv;