import { Box, Button, Typography } from "@mui/material"
import { LoginFormProps } from ".";
import { useNavigate } from "react-router-dom";
import CallToActionIcon from "../../svg/CallToActionIcon";


const AccountCreated: React.FC<LoginFormProps> = ({handleClose}) => {
  const navigate = useNavigate();

  const handleCreate = () => {
    navigate('/creator-onboarding')
    handleClose(null, null, false)
  };

  return (
    <Box
      p={4}
      px={5}
      display='flex'
      flexDirection='column'
      width='100%'
      maxWidth={'sm'}
      gap={3}
    >
      <Box
        display='flex'
        flexDirection='column'
        width='100%'
      >
        <Typography variant="h5" textAlign='center'>
          Account Created
        </Typography>
        <Typography variant="body2" textAlign='center'
          sx={{
            color: (theme) => theme.palette.text.secondary
          }}
        >
          Welcome to Soopra.
        </Typography>
      </Box>

      <Box
        display='flex'
        flexDirection='column'
        width='100%'
        gap={1.5}
      >
        <Button
          variant="contained"
          size="large"
          startIcon={<CallToActionIcon />}
          sx={{
            boxShadow: (theme) => `${theme.shadows[2]} !important`,
          }}
          onClick={handleCreate}
        >
          Create My AI Persona
        </Button>
        <Button
          variant="contained"
          size="large"
          color="secondary"
          sx={{
            boxShadow: (theme) => `${theme.shadows[2]} !important`,
          }}
          onClick={() => handleClose(null, null, true)}
        >
          Maybe Later
        </Button>
      </Box>
    </Box>
  )
}

export default AccountCreated;
