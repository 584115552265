import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { Box, Typography, Button, Avatar, Grid, Tooltip, Grid2 } from "@mui/material";
import { fetchCoursesByCreator, deleteCourse, fetchCourseResultsByStudent, getCourseInfo, getSortedCoursesList, fetchCourseProgress, updateCurriculum } from '../../utils/api';
import { COURSE_PRICES, USER_TYPE } from '../../config/const';
import SoopraIcon from '../../assets/images/soopra-icon.png';
import { TierContext } from "../../context/TierContext";

interface UserCourseDataProps {
  authUser?;
  profileData?;
}

const showMyCourse = process.env.REACT_APP_SHOW_MY_COURSE === 'true';
const allowSelfPublishedCourses = process.env.REACT_APP_ALLOW_SELF_PUBLISHED_COURSES === 'true';

export const setLoadingState = (
    setFunc: React.Dispatch<React.SetStateAction<{data: any, isLoading: boolean}>>,
    state: boolean
  ) => {
  setFunc((info) => {
    let copy = {...info};
    copy.isLoading = state;
    return copy;
  })
}

const UserCourseData: React.FC<UserCourseDataProps> = (props) => {
  const { authUser, profileData } = props;
  const [courses, setCourses] = useState({data: [], isLoading: false});
  const [enrolledCourses, setEnrolledCourses] = useState({data: [], isLoading: false});
  const [filteredEnrolledCourses, setFilteredEnrolledCourses] = useState({data: [], isLoading: false});

  const {
    documentId = '',
    fullName = '',
    profilePhotoURL = '',
    userName = '',
    index = '',
  } = profileData || {};
  const { documentId: authDocumentId = '' } = authUser || {};

  const { checkPermission } = useContext(TierContext);

  React.useEffect(() => {
    if (authUser?.userType === USER_TYPE.CREATOR && documentId === authDocumentId) {
      setLoadingState(setCourses, true);
      fetchCoursesByCreator(documentId)
        .then(data => {
          setCourses((info) => {
            let copy = {...info};
            copy.data = data;
            return copy;
          });
        })
        .catch(error => {
          console.error('Error fetching courses:', error);
        })
        .finally(() => {
          setLoadingState(setCourses, false)
        })
    }
  }, [documentId]); //eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (authUser && documentId === authDocumentId) {
      setLoadingState(setEnrolledCourses, true)

      fetchCourseResultsByStudent(authUser.uid)
        .then(async (results) => {
          const enrolledCoursesWithDetails = await Promise.all(results.map(async (course) => {
            const courseInfoRes = await getCourseInfo(course.courseId);
            const courseInfo = await courseInfoRes.json();
            const courseProgressRes = await fetchCourseProgress(`course_${course.courseId}.${authUser.documentId}`);
            const courseProgress = courseProgressRes.progress;

            return {
              ...course,
              name: courseInfo.name,
              subject: courseInfo.subject,
              grade: courseInfo.grade,
              creator_name: courseInfo.creator_name,
              creatorAvatar: courseInfo.creator_avatar,
              module_count: courseInfo.module_count,
              course_completed: courseProgress.course_completed,
            };
          }));
          setEnrolledCourses(
            {
            data: enrolledCoursesWithDetails,
            isLoading: true}
          );
        })
        .catch(error => {
          console.error('Error fetching enrolled courses:', error);
        })
        .finally(() => {
          setLoadingState(setEnrolledCourses, false)
        })
    }
  }, [authUser]); //eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    const fetchFilteredEnrolledCourses = async () => {
      if (enrolledCourses.data.length > 0) {
        const sortedCoursesResponse = await getSortedCoursesList(1, 100);
        const sortedCourses = await sortedCoursesResponse.json();
        const filteredCourses = enrolledCourses.data.filter(course =>
          sortedCourses.data.some(sortedCourse => sortedCourse.course_id === course.courseId)
        );
        setFilteredEnrolledCourses({data: filteredCourses, isLoading: false});
      }
    };

    fetchFilteredEnrolledCourses();
  }, [enrolledCourses]);

  const handleDeleteCourse = async (courseId) => {
    if (window.confirm("Are you sure you want to delete this course?")) {
      try {
        await deleteCourse(courseId);
        setCourses({data: courses.data.filter(course => course.course_id !== courseId), isLoading: false});
        alert('Course deleted successfully!');
      } catch (error) {
        console.error('Error deleting course:', error);
        alert('Failed to delete course');
      }
    }
  };

  const shouldPublishCourse = async (course: any, shouldPublish: boolean) => {
    if (!checkPermission("publish:ownCourse")){
      return;
    }
    if (window.confirm(`Are you sure you want to ${shouldPublish ? "" : "un"}publish your course on ${course?.name}?`)) {
      try {
        if (!course?.course_id) throw new Error('Course not found');
        if (shouldPublish && !course?.creation_done) throw new Error('Cannot publish incomplete course');
        if (!shouldPublish && course?.student_count) throw new Error('Cannot unpublish course with enrolled students');

        let updatedCourseInfo = { is_published: shouldPublish };
        await updateCurriculum(course.course_id, updatedCourseInfo, true);

        setCourses({
          data: courses.data.map(tmpCourse => (
            tmpCourse.course_id === course.course_id
              ? { ...tmpCourse, is_published: shouldPublish }
              : tmpCourse
          )),
          isLoading: false,
          }
        );
        alert(`Course ${shouldPublish ? "" : "un"}published successfully!`);
      } catch (error) {
        console.error(`Error ${shouldPublish ? "" : "un"}publishing course: `, error);
        alert(`Failed to ${shouldPublish ? "" : "un"}publish course`);
      }
    }
  };

  const CourseCard = ({ course, isEnrolled }: { course: any; isEnrolled: boolean }) => (
    <Grid item xs={12} sm={6} md={3} sx={{ display: 'flex', justifyContent: 'center' }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          borderRadius: "8px",
          boxShadow: "0 3px 6px rgba(0,0,0,0.1)",
          overflow: "hidden",
          backgroundColor: "white",
          width: { xs: '100%', sm: '240px' }, // Full width for mobile, fixed width for larger screens
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: 150,
            backgroundImage: `url(${course.banner_image || course.creatorAvatar || SoopraIcon})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
        <Box p={1.5}>
          <Typography fontSize="0.9rem" fontWeight="600" mb={1} noWrap>
            {course.name}
          </Typography>
          <Typography fontSize="0.8rem" color="text.secondary" noWrap>
            {course.subject}
          </Typography>
          <Box display="flex" alignItems="center" mt={1}>
            <Avatar src={course.creatorAvatar} alt={course.creator_name} sx={{ width: 28, height: 28, mr: 1 }} />
            <Typography fontSize="0.85rem" color="text.secondary" noWrap>
              {course.creator_name}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Grid>
  );  


  const CreatedCourses = () =>
    {
      return (courses.data.length || courses.isLoading ) ?
      <Box mt={4} display='flex' flexDirection='column' width='100%' gap={1}>
        <Box display='flex' alignItems='center' justifyContent='space-between'>
          <Typography color={'text.secondary'}  variant='body2'>
            {`${authUser?.userName === profileData.userName ? 'My ' : ''}Courses`}
          </Typography>
          <Box display="flex" alignItems="center">
            <Link
              id={index ? `href-create-course-${userName}` : ''}
              to={index ? `/createCourse` : ''}
              aria-disabled={!index}
              style={{
                cursor: index ? 'pointer' : 'not-allowed',
                textDecoration: 'none',
              }}
            >
              <Button
                id="button-create-course"
                variant="contained"
                size="small"
                sx={{
                  py: 1,
                  fontWeight: 400,
                  backgroundColor: 'black',
                  color: 'white',
                  width: '200px',
                  display: { xs: 'none', sm: 'block' }, // Hide on mobile (xs), show on larger screens (sm and up)
                  '&:hover': {
                    backgroundColor: 'black',
                    color: 'white',
                  },
                }}
              >
                Create New Course
              </Button>
            </Link>
          </Box>
        </Box>
        {courses.isLoading ? (
          <Typography>Loading...</Typography>
        ) : courses.data.length > 0 ? (
          <Grid container spacing={3}>
            {courses.data.map((course) => (
              <CourseCard key={course.course_id} course={course} isEnrolled={false} />
            ))}
          </Grid>
        ) : (
          <Typography>No created courses found.</Typography>
        )}
      </Box>
      : <></>
    };

  const EnrolledCourses = () => {
    return (courses.data.length || courses.isLoading ) ?
      <Box mt={4} px={0}>
        <Typography variant="body1" fontWeight="600" color={'text.secondary'} gutterBottom>
          Enrolled Courses
        </Typography>
        {enrolledCourses.isLoading ? (
          <Typography>Loading...</Typography>
        ) : filteredEnrolledCourses.data.length > 0 ? (
          <Grid2 container spacing={3}>
            {filteredEnrolledCourses.data.map((course) => (
              <CourseCard key={course.courseId} course={course} isEnrolled={true} />
            ))}
          </Grid2>
        ) : (
          <Typography>You have not enrolled in any courses.</Typography>
        )}
      </Box> : <></>
  };

  return (
    <>
      <CreatedCourses />
    </>
  );
};

export default UserCourseData;
