import { Box, Button, Grid2 as Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Lottie from "lottie-react";
import LoadingFace from '../../assets/animations/LoadingFace.json'
import { fetchFeaturedCreators } from "../../utils/api";
import FanCreatorProfileItem from "../fanCreatorList/fanCreatorProfileItem";
import { useQuery } from "@tanstack/react-query";

const FeaturedCreators: React.FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const xl = useMediaQuery(theme.breakpoints.up("xl"));
  const lg = useMediaQuery(theme.breakpoints.up("lg"));
  const sm = useMediaQuery(theme.breakpoints.up("sm"));

  const { data: creators, isLoading } = useQuery({
    queryKey: ['featured-creators'],
    queryFn: async () => {
      return await fetchFeaturedCreators()
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            return {data: []}
          }
        })
        .catch((err) => {
          console.log(err)
          return {data: []}
        })
    },
    staleTime: Infinity,
  })

  return (
    <Box
      display='flex'
      flexDirection='column'
      width='100%'
    >
      <Box
        width='100%'
        display='flex'
        flexDirection='column'
        position='relative'
        sx={{
          px: {
            xs: 2,
            md: 0
          },
          pb: {xs: 2, sm: 1.75, md: 1},
        }}
      >
        <Button
          href="/creators"
          sx={{
            position:'absolute',
            right: 0,
            top: -8,
          }}
          onClick={(event) => {
            event.preventDefault()
            event.stopPropagation()
            navigate('/creators')
          }}
        >
          <Typography variant="h7"
            sx={{
              paddingLeft: 1,
            }}
          >
            See All
          </Typography>
          < ChevronRightIcon />
        </Button>
        <Typography
          variant='h6'
          pb={0.5}
        >
          Featured AI Personas
        </Typography>
        <Typography
          variant='body2'
          color={theme.palette.text.secondary}
        >
          Chat with the AI Personas of leading experts and knowledge-workers in various fields of expertise on the topic of your choice.
        </Typography>
      </Box>
      {
        isLoading ?
        <Box
          margin='auto'
          marginTop={1}
          sx={{
            '& path': {
              fill: (theme) => theme.palette.primary.main
            }
          }}
        >
          <Lottie
            animationData={LoadingFace}
            loop={true}
            style={{
              width: '100px',
              height: '100px',
            }}
          />
        </Box> :
        <Grid container rowSpacing={{xs: 1, sm: 0.5, md: -1}} columnSpacing={0}
          sx={{
            mx: {
              xs: 0.5,
              md: -1.5
            },
          }}
        >
          {
            creators.data.map((creatorItem, index) => {
              let limit = 8;
              if (xl) limit = 8;
              else if (lg) limit = 6;
              else if (sm) limit = 4;
              else limit = 4;
              return (
                <Grid
                  size={{
                    xs: 12,
                    sm: 6,
                    lg: 4,
                    xl: 3
                  }}
                  sx={{
                    display: index+1 > limit ? 'none' : 'flex'
                  }}
                  key={index}
                >
                  <FanCreatorProfileItem profileData={creatorItem} />
                </Grid>
              )
            })
          }
        </Grid>

      }
    </Box>
  )
}

export default FeaturedCreators;
