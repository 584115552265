import React, { useEffect, useState, useContext } from 'react';
import { useAuth } from '../../hooks/useAuth';
import { StripeContext } from '../../context/StripeContext';
import { createCheckoutSession } from '../../utils/api';
import { EmbeddedCheckout, EmbeddedCheckoutProvider } from '@stripe/react-stripe-js';
import Dialog from '@mui/material/Dialog';
import { DialogContent } from '@mui/material';
import CloseIcon from '../../svg/CloseIcon';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';

interface StripeCheckoutFormProps {
  item: string,
  isOpen: boolean,
  setIsOpen: any,
  itemId?: string,
  redirectURL?: string,
  isPremium?: boolean,
  trialPeriod?: number,
}

const StripeCheckoutForm: React.FC<StripeCheckoutFormProps> = (props) => {
  const [clientSecret, setClientSecret] = useState(null);
  const [referral, setReferral] = useState<string>("");
  const { authUser } = useAuth();
  const { stripePromise } = useContext(StripeContext)
  const { item, itemId, isOpen=false, setIsOpen, redirectURL, isPremium=false, trialPeriod=0 } = props;

  useEffect(() => {
    // @ts-ignore
    setReferral(window.Rewardful?.referral || '')
  }, []);

  useEffect(() => {
    // To-do: redirect if authUser doesnt exist...
    // what to do if stripe isn't loaded?
    if (item && isOpen){
      createCheckoutSession(
        authUser?.documentId,
        authUser?.email,
        item,
        authUser?.customerId,
        itemId,
        redirectURL,
        isPremium,
        trialPeriod,
        referral
      )
        .then((res) => res.json())
        .then((data) => setClientSecret(data.clientSecret))
        .catch((err) => {
          console.log(err)
          handleClose();
        })
    }
  }, [item, referral]) //eslint-disable-line react-hooks/exhaustive-deps

  const handleClose = () => {
    setClientSecret(null)
    setIsOpen(false);
  };

  const options = {
    clientSecret,
  } as const

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      fullWidth={true}
      maxWidth={'lg'}
      PaperProps={{
        sx: {
          background: 'white',
        }
      }}
    >
      <DialogContent>
        {
            (
              <Box>
                <IconButton
                  id='button-close-stripe-checkout'
                  onClick={handleClose}
                  color='primary'
                  sx={{
                    position: 'absolute',
                    alignSelf: 'flex-end',
                    minWidth: '36px',
                    minHeight: '36px',
                    height: '36px',
                    width: '36px',
                    top: '16px',
                    right: '16px',
                  }}
                >
                  <CloseIcon height='30px' width='30px' />
                </IconButton>
                {
                  clientSecret ?
                    <EmbeddedCheckoutProvider stripe={stripePromise} options={options}>
                      <EmbeddedCheckout />
                    </EmbeddedCheckoutProvider> :
                    <Box
                      sx={{display: 'flex', justifyContent: 'center'}}
                    >
                      <CircularProgress size={30}/>
                    </Box>
                }
              </Box>
            )
        }
      </DialogContent>
    </Dialog>
  )
}

export default StripeCheckoutForm;
