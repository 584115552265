import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Avatar from '@mui/material/Avatar';
import { IconButton } from '@mui/material/';
import { Grid2 as Grid } from "@mui/material";

import { useAuth } from '../../hooks/useAuth';
import FanCreatorItem from './fanCreatorItem';
import {
  getSortedCreatorList,
  getCorporateAccounts,
} from '../../utils/api';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import CircleIcon from '@mui/icons-material/Circle';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SortIcon from '@mui/icons-material/Sort';
import { SortByContext } from '../../context/SortByContext';
import { useParams } from 'react-router-dom';

const PAGE_LIMIT = 14;

const enableCreatorSortBy =
  process.env.REACT_APP_ENABLE_CREATOR_SORT_BY === 'true';

const SORT_BY_OPTIONS = {
  topFans: 'Most Fans',
  topMessages: 'Most Messages',
  aToz: 'Alphabetical (A-Z)',
  zToa: 'Alphabetical (Z-A)',
  oldestFirst: 'Oldest Accounts',
  trending: 'Trending',
};

export default function FanCreatorList() {
  const { enterpriseName } = useParams();
  const [refresh, setRefresh] = React.useState<boolean>(false);
  const isLoading = React.useRef<boolean>(false);
  const { authUser } = useAuth();
  const [creatorsData, setCreatorsData] = React.useState([]);
  const [showSortByMenu, setShowSortByMenu] = React.useState(false);
  const [menuAnchor, setMenuAnchor] = React.useState<HTMLElement>(null);
  const currentPage = React.useRef<number>(1);
  const fullyLoaded = React.useRef<boolean>(false);
  const { sortBy } = React.useContext(SortByContext);
  const isMobile = React.useRef<boolean>(false);
  const bottom = React.useRef(null);

  React.useEffect(() => {
    checkMobile();

    window.addEventListener('resize', checkMobile, false);

    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          handleFetchData();
        }
      },
      {
        rootMargin: '100px', // Load more content before reaching the bottom
      }
    );
    observer.observe(bottom.current);

    return () => {
      window.removeEventListener('resize', checkMobile, false);
      observer.disconnect();
    };
  }, [creatorsData]); //eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    // refetch data any time sortBy is changed
    if (authUser?.uid && !isLoading.current) {
      currentPage.current = 1;
      fullyLoaded.current = false;
      handleFetchData();
    }
  }, [sortBy.current]); //eslint-disable-line react-hooks/exhaustive-deps

  let checkMobile = () => {
    if (window.screen.availWidth < 900) {
      isMobile.current = true;
    } else {
      isMobile.current = false;
    }
  };

  const handleFetchData = async () => {
    if (!fullyLoaded.current && !isLoading.current) {
      isLoading.current = true;
      setRefresh((curState) => !curState);
      if (currentPage.current === 1) {
        setCreatorsData([]);
      }

      // Determine if we're on a corporate account page
      const isCorporateAccountPage = !!enterpriseName;
      const isStanford = window.location.hostname === 'stanford.soopra.ai';
      const effectiveSortBy = isCorporateAccountPage ? 'aToz' : sortBy.current;

      const fetchData = isStanford
        ? getSortedCreatorList(
            authUser?.uid,
            currentPage.current,
            10000,
            effectiveSortBy
          )
        : isCorporateAccountPage
        ? getCorporateAccounts(
            enterpriseName,
            currentPage.current,
            PAGE_LIMIT,
            effectiveSortBy
          )
        : getSortedCreatorList(
            authUser?.uid,
            currentPage.current,
            PAGE_LIMIT,
            effectiveSortBy
          );

      fetchData
        .then((resp) => {
          if (resp.ok) {
            return resp.json();
          } else {
            isLoading.current = false;
            throw new Error('Failed to fetch data');
          }
        })
        .then((data) => {
          currentPage.current += 1;
          if (!data.data.length || effectiveSortBy === 'trending') {
            fullyLoaded.current = true;
          }
          isLoading.current = false;

          setCreatorsData((prevData) => [...prevData, ...data.data]);
        })
        .catch((err) => {
          console.error('Error in creator fetch:', err);
          isLoading.current = false;
          fullyLoaded.current = true;
          setRefresh((curState) => !curState);
        });
    }
  };

  return (
    <Box
      width='100%'
      display='flex'
      flex={1}
      bgcolor={(theme) => theme.palette.background.default}
    >
      <Box
        width='100%'
        display='flex'
        flexDirection='column'
        alignItems='center'
        textAlign='center'
      >
        <Grid container
        sx={{
            width: '100%',
            margin: 'auto',
            maxWidth: 'lg',
          }}>
          {(window.location.href.startsWith('https://stanford.soopra.ai') ||
            !window.location.href.startsWith('https://sambatv.soopra.ai')) && (
            <Box
              sx={{
                pt: { xs: 2, sm: 2.5, md: 4.5 },
                px: { xs: 2.5, md: 4 },
                margin: 'auto',
                maxWidth: 'lg',
              }}
              display='flex'
              width='100%'
              flexDirection='column'
              textAlign='left'
            >
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: enableCreatorSortBy
                    ? 'space-between'
                    : 'center',
                  alignItems: 'center',
                }}
              >
                <Typography variant='h7' color='text.primary' align='left'>
                  Chat with Experts
                </Typography>
                {enableCreatorSortBy && (
                  <>
                    {isMobile.current ? (
                      <IconButton
                        id='button-sort-menu'
                        sx={{
                          padding: '8px',
                        }}
                        onClick={(e) => {
                          setShowSortByMenu(true);
                          setMenuAnchor(e?.currentTarget);
                        }}
                      >
                        <SortIcon height='20px' width='20px' />
                      </IconButton>
                    ) : (
                      <Box
                        id='button-sort-menu'
                        onClick={(e) => {
                          setShowSortByMenu(true);
                          setMenuAnchor(e?.currentTarget);
                        }}
                        sx={{
                          width: '200px',
                          display: isMobile.current ? 'none' : 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          padding: 1,
                          ml: 1,
                          border: '1px solid',
                          borderColor: 'text.secondary',
                          '&:hover': {
                            cursor: 'pointer',
                            // backgroundColor: `${grey[100]}`,
                            // borderRadius: '8px',
                          },
                        }}
                      >
                        <Typography
                          ml={1}
                          mr={1}
                          fontSize='0.9rem'
                          component='h5'
                          variant='h6'
                          color='text.secondary'
                        >
                          {SORT_BY_OPTIONS[sortBy.current]}
                        </Typography>
                        <ExpandMoreIcon
                          height='20px'
                          width='20px'
                          color='action'
                        />
                      </Box>
                    )}
                    <Menu
                      id='menu-sort-by'
                      open={Boolean(showSortByMenu)}
                      onClose={() => {
                        setShowSortByMenu(false);
                      }}
                      anchorEl={menuAnchor}
                      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                      transformOrigin={{ vertical: 0, horizontal: 'right' }}
                      PaperProps={{
                        style: {
                          display: 'flex',
                          justifyContent: 'flex-start',
                          alignItems: 'center',
                          borderRadius: '10px',
                          overflow: 'hidden',
                        },
                      }}
                    >
                      {Object.entries(SORT_BY_OPTIONS).map(
                        ([value, label], index) => (
                          <MenuItem
                            id={`button-sortby-option-${value}`}
                            key={index}
                            onClick={() => {
                              if (sortBy.current !== value) {
                                sortBy.current = value; // triggers a useEffect
                              }
                              setShowSortByMenu(false);
                            }}
                            sx={{
                              display: 'flex',
                              justifyContent: 'flex-start',
                              alignItems: 'center',
                              textAlign: 'center',
                              p: 1,
                              pt: 0.2,
                              pb: 0.2,
                              borderRadius: '.7em',
                              minHeight: 0,
                            }}
                          >
                            <CircleIcon
                              sx={{
                                height: '10px',
                                width: '10px',
                                // color: 'mainBlue.main',
                                visibility:
                                  sortBy.current === value
                                    ? 'visible'
                                    : 'hidden',
                              }}
                            />
                            <Typography
                              ml={1}
                              mr={1}
                              fontSize='0.8rem'
                              component='h5'
                              variant='h6'
                              fontWeight={
                                sortBy.current === value ? 'bold' : 'normal'
                              }
                            >
                              {label}
                            </Typography>
                            <CircleIcon
                              sx={{
                                height: '10px',
                                width: '10px',
                                // color: 'mainBlue.main',
                                visibility: 'hidden',
                              }}
                            />
                          </MenuItem>
                        )
                      )}
                    </Menu>
                  </>
                )}
              </div>
            </Box>
          )}

          {window.location.hostname === 'stanford.soopra.ai' ? (
            creatorsData
              ?.filter(
                (creatorItem) =>
                  creatorItem.userName === 'ronjon' ||
                  creatorItem.userName === 'sortino-chat' ||
                  creatorItem.userName === 'carlos_seligo'
              )
              .map((creatorItem, i, arr) => (
                <FanCreatorItem
                  creatorItem={creatorItem}
                  key={creatorItem?.creatorId}
                />
              ))
          ) : creatorsData?.length >= 1 ? (
            // removing code of temp commit
            <Box width='100%'>
              <Grid
                container
                sx={{
                  px: { xs: 1.25, md: 2.5 },
                  pt: { xs: 1, sm: 1.25, md: 1 },
                  pb: { xs: 1, sm: 1.25, md: 2 },
                  margin: 'auto',
                  maxWidth: 'lg',
                }}
              >
                {creatorsData?.map((creatorItem, i, arr) => (
                  <FanCreatorItem
                    creatorItem={creatorItem}
                    key={creatorItem?.creatorId}
                  />
                ))}
              </Grid>
              {fullyLoaded.current ||
              !isLoading.current ||
              window.location.hostname === 'sambatv.mimio.ai' ? (
                <></>
              ) : (
                <Box
                  display='flex'
                  width='100%'
                  padding='1.5rem'
                  alignItems='center'
                  justifyContent='center'
                >
                  <CircularProgress size={30} />
                </Box>
              )}
            </Box>
          ) : isLoading.current && creatorsData?.length === 0 ? (
            <Box
              display='flex'
              width='100%'
              padding='1.5rem'
              alignItems='center'
              justifyContent='center'
            >
              <CircularProgress size={30} />
            </Box>
          ) : (
            <Box
              mt={2}
              rowGap={2}
              sx={{
                flexDirection: 'column',
                alignItems: 'center',
                display: 'flex',
                width: '100%',
                justifyContent: 'center',
              }}
            >
              <Avatar
                sx={{
                  width: 80,
                  height: 80,
                }}
              />
              <Typography
                component='h5'
                variant='body2'
                fontSize='1rem'
                fontWeight={500}
              >
                No persona found
              </Typography>
            </Box>
          )}
        </Grid>
        <div className='custom-bottom' ref={bottom} />
      </Box>
    </Box>
  );
}
