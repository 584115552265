import { useProfileData } from '../hooks/useProfileData';
import React, { Suspense, lazy } from 'react';
import {
  Avatar,
  Box,
  Typography,
  IconButton,
  Divider,
  Stack,
} from '@mui/material';
import VerifiedIcon from '@mui/icons-material/Verified';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import DefaultUserImage from "../assets/images/default-avatar.png"

interface PremiumCreatorInfoProps {
  anchorEl: any;
  requiredPremiumKey: string;
  premiumAccess: boolean;
  setShowLogin: React.Dispatch<React.SetStateAction<boolean>>;
}

const PremiumCreatorInfo: React.FC<PremiumCreatorInfoProps> = (props) => {
  const { anchorEl, requiredPremiumKey, premiumAccess, setShowLogin } = props;
  const { creatorProfileData } = useProfileData();
  const FrankSortinoNav = lazy(
    () => import('../views/customUIElements/FrankSortino')
  );
  const navigate = useNavigate();
  const theme = useTheme();

  if (!creatorProfileData) {
    return <></>;
  }

  if (
    creatorProfileData.userName === 'srinivasjaini' ||
    creatorProfileData.userName === 'sortino-chat'
  ) {
    return (
      <Suspense>
        <FrankSortinoNav
          anchorEl={anchorEl}
          featureKey={requiredPremiumKey}
          hasAccess={premiumAccess}
          setShowLogin={setShowLogin}
        />
      </Suspense>
    );
  }

  return (
    <Box
      sx={{
        display: {xs : 'none', md: 'flex'},
        flexDirection: { xs: 'row', md: 'column' },
        justifyContent: { xs: 'center', md: 'flex-start' },
        alignItems: { xs: 'center', md: 'start' },
        width: '100%',
        height: { xs: '66px', md: '100%' },
        flexGrow: 1,
        boxShadow: `0 4px 4px ${theme.palette.primary.main}33`,
        maxHeight: '100%',
      }}
    >
      <IconButton
        onClick={() => navigate(-1)}
        sx={{
          width: { xs: '40px', md: '27px' },
          height: { xs: '40px', md: '27px' },
          backgroundColor: (theme) => theme.palette.secondary.light,
          borderRadius: { xs: '16px', md: '11px' },
          mt: 3,
          mx: 2.5,
        }}
      >
        <ArrowBackIcon
          sx={{
            width: { xs: '22px', md: '18px' },
            height: { xs: '22px', md: '18px' },
          }}
        />
      </IconButton>

      <Box
        width={'100%'}
        justifyContent={'center'}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          width: '100%',
          maxHeight: '100%',
          overflowY: 'auto',
          pb: 3,
        }}
      >
        <Avatar
          src={creatorProfileData?.profilePhotoURL || DefaultUserImage}
          alt={creatorProfileData?.fullName}
          sx={{
            mx: 2.5,
            width: { xs: '40px', md: 'auto' },
            height: { xs: '40px', md: 'auto' },
            fontSize: '1.25rem',
            aspectRatio: 1,
            borderRadius: '40%',
            cursor: 'pointer',
          }}
          onClick={() => navigate(`/${creatorProfileData.userName}`, { replace: true })}
        >
          {creatorProfileData?.fullName ? creatorProfileData?.fullName : ''}
        </Avatar>

        <Stack
          direction='column'
          mt={{ xs: 0, md: 3 }}
          mx={2.5}
          justifyContent={{ xs: 'center', md: 'flex-start' }}
        >
          <Box display='flex' alignItems='center'>
            <Typography variant='h7' color='primary' align='left'>
              {creatorProfileData?.fullName}
            </Typography>
            <VerifiedIcon
              sx={{ ml: 0.5, fill: '#3EACEF', width: '18px', height: '18px' }}
            />
          </Box>
          <Typography
            variant='body2'
            color='textSecondary'
            align='left'
            sx={{ m: 0, p: 0, lineHeight: 1.25 }}
          >
            @{creatorProfileData?.userName}
          </Typography>
        </Stack>

        <Divider
          sx={{
            display: { xs: 'none', md: 'block' },
            width: '100%',
            height: '0.5px',
            backgroundColor: 'text.primary',
            opacity: 0.1,
            my: 1,
          }}
        />

        <Typography
          variant='body2'
          color='textPrimary'
          align={'left'}
          display={{ xs: 'none', md: 'block' }}
          sx={{
            flexGrow: 1,
            overflowY: 'auto',
            px: 2.5,
          }}
        >
          {creatorProfileData?.shortBio}
        </Typography>
      </Box>
    </Box>
  );
};

export default PremiumCreatorInfo;
