import { Box, Button, CircularProgress, IconButton, InputAdornment, TextField, Typography, useTheme } from "@mui/material";
import { toast } from 'react-toastify';
import { useEffect, useState } from "react";
import SoopraLogo from "../../svg/SoopraLogo";
import CloseIcon from '../../svg/CloseIcon';
import GoogleIcon from '../../svg/GoogleIcon';
import { USER_TYPE } from "../../config/const";
import { useAuth } from "../../hooks/useAuth";
import { COMMON_FIREBASE_ERROR_CODE } from "../../config/errors";
import { FirebaseError } from "firebase/app";
import { LoginFormProps } from ".";
import { getAuth } from 'firebase/auth';
import { Visibility, VisibilityOff } from "@mui/icons-material";
import TwitterIcon from '@mui/icons-material/Twitter';

const DEFAULT_ERROR_STATE = {email: null, password: null};
Object.freeze(DEFAULT_ERROR_STATE);

const SignIn: React.FC<LoginFormProps> = ({setFormType, handleClose, setCurrentStep}) => {
  const [ email, setEmail ] = useState<string>("");
  const [ password, setPassword ] = useState<string>("");
  const [ showPassword, setShowPassword ] = useState<boolean>(false);
  const [ errors, setErrors ] = useState(DEFAULT_ERROR_STATE);
  const [ isGoogleLoading, setIsGoogleLoading ] = useState<boolean>(false);
  const [ isSubmitting, setIsSubmitting ] = useState<boolean>(false);
  const theme = useTheme();
  const { authUser, signInSignUpWithGoogle, checkUserExistWithEmail, signInWithEmailPassword, signInSignUpWithTwitter } = useAuth();
  const auth = getAuth();

  useEffect(() => {
    if (authUser?.documentId) {
      if (authUser.email) setEmail(authUser.email);
      // Retrieve the provider IDs (e.g., ["password", "google.com", "twitter.com"])
      const providers = auth.currentUser.providerData.map(p => p.providerId);
      // If the user is not a Twitter user and their email is not verified, require email verification.
      if (!providers.includes("twitter.com") && auth.currentUser.emailVerified === false) {
        setCurrentStep(3); // show verify email step
      } else if (!authUser?.userName) {
        setFormType('setupaccount');
      } else {
        handleClose(null, null, true);
      }
    }
  }, [authUser]); //eslint-disable-line react-hooks/exhaustive-deps

  const handleUserSignIn = (email: string, password: string) => {
    signInWithEmailPassword(email, password)
      .then(() => {
        toast.success('Signed in successfully');
      })
      .catch((error) => {
        const firebaseError = (error as FirebaseError)?.code;
        toast.error(
          firebaseError && COMMON_FIREBASE_ERROR_CODE[firebaseError]
            ? COMMON_FIREBASE_ERROR_CODE[firebaseError]
            : 'Invalid login details'
        );
      })
      .finally(() => {
        setIsSubmitting(false);
      })
  };

  const handleGoogleLogin = async () => {
    setIsGoogleLoading(true);
    signInSignUpWithGoogle({userType: USER_TYPE.FOLLOWER})
      .then(() => {
        toast.success('User signed in successfully');
      })
      .catch((error) => {
        const firebaseError = (error as FirebaseError)?.code;
        toast.error(
          firebaseError && COMMON_FIREBASE_ERROR_CODE[firebaseError]
            ? COMMON_FIREBASE_ERROR_CODE[firebaseError]
            : 'Invalid login details'
        );
      })
      .finally(() => {
        setIsGoogleLoading(false);
      })
  }

  const handleSubmit = async () => {
    setErrors(DEFAULT_ERROR_STATE)
    setIsSubmitting(true)
    if (email && password) {
      const isEmailExists = await checkUserExistWithEmail(email);
      if (isEmailExists) {
        handleUserSignIn(email, password);
      } else {
        toast.error('Invalid login details');
        setIsSubmitting(false);
      }
    } else {
      let err = {email: null, password: null};
      if (!email) err.email = 'Email is required';
      if (!password) err.password = 'Password is required';
      setErrors(err)
      setIsSubmitting(false);
    }
  }

  return (
    <Box
      p={4}
      display='flex'
      flexDirection='column'
      width='100%'
      maxWidth={'sm'}
      gap={4}
    >
      <Box
        display='flex'
        justifyContent='space-between'
        alignItems='flex-start'
        width='100%'
      >
        <Box // just to take up equal space
          sx={{
            mt: -1,
            visibility: 'hidden',
          }}
        >
          <IconButton
            onClick={handleClose}
            color='primary'
          >
            <CloseIcon
              height='24px'
              width='24px'
              stroke={theme.palette.text.primary}
              strokeWidth={2}
            />
          </IconButton>
        </Box>
        <SoopraLogo color={theme.palette.primary.main} width='129px' height='34px'/>
        <Box
          sx={{
            mt: -1,
          }}
        >
          <IconButton
            onClick={handleClose}
            color='primary'
          >
            <CloseIcon
              height='24px'
              width='24px'
              stroke={theme.palette.text.primary}
              strokeWidth={2}
            />
          </IconButton>
        </Box>
      </Box>
      <Box
        display='flex'
        flexDirection='column'
        width='100%'
        gap={0.75}
      >
        <Typography variant="h5" textAlign='center'>
          Sign In
        </Typography>
        <Typography variant="body2"
          display='flex'
          gap={0.5}
          justifyContent='center'
          alignItems='center'
        >
          {"New Here?"}
          <Typography variant="h8"
            onClick={() => {
              setCurrentStep(1);
              setFormType('signup');
            }}
            sx={{
              cursor: 'pointer',
              textDecoration: 'underline',
            }}
          >
            Create Account
          </Typography>
        </Typography>
      </Box>

      <Box
        display='flex'
        flexDirection='column'
        width='100%'
        gap={2}
      >
        <Button
          variant="contained"
          color="secondary"
          startIcon={
            <Box display='flex' alignItems='center'>
              {isGoogleLoading && (
                <CircularProgress
                  size={20}
                  color='inherit'
                  sx={{
                    marginRight: 2,
                    marginLeft: -4.5,
                  }}
                />
              )}
              <GoogleIcon height={'30px'} width='30px' />
            </Box>
          }
          sx={{
            border: 'none !important',
            // bgcolor: (theme) => `${theme.palette.grey[50]} !important`,
            boxShadow: (theme) => `${theme.shadows[2]} !important`,
          }}
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();

            handleGoogleLogin();
          }}
        >
          Continue With Google
        </Button>
        <Button
          variant="contained"
          color="secondary"
          startIcon={<TwitterIcon height={'30px'} width='30px' />}
          sx={{
            border: 'none !important',
            // bgcolor: (theme) => `${theme.palette.grey[50]} !important`,
            boxShadow: (theme) => `${theme.shadows[2]} !important`,
          }}
          onClick={(event) => {
            event.preventDefault();
            signInSignUpWithTwitter({ userType: USER_TYPE.FOLLOWER });
          }}
        >
          Continue With Twitter
        </Button>
        <Typography
          variant="body1"
          sx={{
            width: '100%',
            textAlign: 'center',
          }}
        >
          or
        </Typography>
        <TextField
          type='email'
          fullWidth
          margin='none'
          label='Email address'
          placeholder="Email address"
          value={email}
          error={!!errors.email}
          helperText={errors.email}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setEmail(event.target.value);
          }}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              event.preventDefault();
              handleSubmit();
            }
          }}
        />
        <TextField
          type={showPassword ? 'text' : 'password'}
          fullWidth
          margin='none'
          label='Password'
          placeholder="Password"
          value={password}
          error={!!errors.password}
          helperText={errors.password}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setPassword(event.target.value);
          }}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              event.preventDefault();
              handleSubmit();
            }
          }}
          slotProps={{
            input: {
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    // onClick={() => setFieldValue('showPassword', !values.showPassword)}
                    // onMouseDown={(event) => event.preventDefault()}
                    onMouseDown={() => setShowPassword(true)}
                    onMouseUp={() => setShowPassword(false)}
                    onMouseLeave={() => setShowPassword(false)}
                    onTouchStart={() => setShowPassword(true)}
                    onTouchEnd={() => setShowPassword(false)}
                    onTouchCancel={() => setShowPassword(false)}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              )
            }
          }}
          autoComplete='off'
        />
        <Typography
          variant="body2"
          sx={{
            cursor: 'pointer',
            textDecoration: 'underline',
          }}
          onClick={() => {
            setFormType('forgotpassword');
          }}
        >
          Forgot Password?
        </Typography>
        <Button
          variant="contained"
          size="large"
          sx={{
            boxShadow: (theme) => `${theme.shadows[2]} !important`,
          }}
          disabled={!email || !password}
          onClick={handleSubmit}
          startIcon={
            <Box display='flex' alignItems='center'>
              {isSubmitting && (
                <CircularProgress
                  size={20}
                  color='inherit'
                  sx={{
                    marginRight: 2,
                    marginLeft: -4.5,
                  }}
                />
              )}
            </Box>
          }
        >
          Sign In
        </Button>
      </Box>

    </Box>
  )
}

export default SignIn;
