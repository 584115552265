import React from 'react';
import {
  Box,
  Typography,
  CircularProgress,
  Stack,
  Grid,
  useTheme,
} from '@mui/material';
import JournalListItem from './JournalListItem';
import DetectBottomScrollDiv from './DetectBottomScrollDiv';

const JournalList = ({
  journalEntries,
  isLoading,
  isFullyLoaded,
  selectedJournalEntry,
  handleJournalTitleClick,
  handleFetchData,
  loadingRef,
  isScreenMdOrLarger
}) => {
  const theme = useTheme();

  return (
    <Grid
      item
      xs={12}
      md={3}
      sx={{
        height: '100%',
        overflowY: 'auto',
        scrollbarWidth: 'none',
        '&::-webkit-scrollbar': { display: 'none' },
        paddingRight: { xs: '0px', md: '8px' },
      }}
    >
      {journalEntries.length !== 0 ? (
        <Stack>
          <Typography
            variant='body2'
            color={theme.palette.text.primary}
            sx={{
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: 2,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              paddingBottom: '8px',
            }}
          >
            Most Recent
          </Typography>
          {journalEntries.map((entry, index) => (
            <Box
              key={index}
              onClick={() => handleJournalTitleClick(entry)}
              sx={{ cursor: 'pointer' }}
            >
              <JournalListItem
                entry={entry}
                selected={selectedJournalEntry === entry}
                isScreenMdOrLarger = {isScreenMdOrLarger}
              />
            </Box>
          ))}
          {isLoading && (
            <Box
              ref={loadingRef}
              display='flex'
              width='100%'
              padding='8px'
              alignItems='center'
              justifyContent='center'
            >
              <CircularProgress size={30} />
            </Box>
          )}
        </Stack>
      ) : (
        <></>
      )}
      <DetectBottomScrollDiv
        onReachBottom={handleFetchData}
        isLoading={isLoading}
        isFullyLoaded={isFullyLoaded}
        className='custom-bottom'
      />
    </Grid>
  );
};

export default JournalList;
