import { Form, Formik } from "formik"
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import DocumentsForm from "./Documents/documentsForm";
import { useAuth } from "../../hooks/useAuth";
import { addBasicTraining, addDocumentsFormData, addFooter, addGoogleDriveFormData, addIntroAndSuggestedQuestionsFormData, addIntroMessageFormData, addJournaling, addPremiumTraining, addSaveButton, addSocialMediaFormData, addSuggestedQuestionsFormData, addTwitterFormData, addVoiceSampleFormData, addWeblinksFormData, addYoutubeFormData, newFormPartsType } from "./helper";
import { USER_TYPE } from "../../config/const";
import { get_google_drive_links } from "../../utils/helper";
import { genericFormProps, IMMUTABLE_USER_KEYS, UserFormType } from "./signup.types";
import { toast } from "react-toastify";
import { Box } from "@mui/material";
import * as Yup from 'yup';
import SaveButton from "./saveButton";
import GoogleDriveForm from "./Documents/googleDriveForm";
import WebLinksForm from "./WebLinks/webLinksForm";
import SocialMediaForm from "./SocialMedia/socialMediaForm";
import TwitterForm from "./SocialMedia/twitterForm";
import YoutubeLinksForm from "./SocialMedia/youtubeLinksForm";
import VoiceUploadForm from "./VoiceUpload/voiceUploadForm";
import Journaling from "../journaling";
import { BasicTraining } from "./onboardingOnly/basicTraining";
import { PremiumTraining } from "./onboardingOnly/premiumTraining";
import { Footer } from "./onboardingOnly/stepOneFooter";
import { Timestamp } from "firebase/firestore";
import { handleGoogleDriveResync } from "../../utils/api";
import { FirebaseError } from "firebase/app";
import { COMMON_FIREBASE_ERROR_CODE } from "../../config/errors";
import IntroductionForm from "./Introduction/introductionForm";
import SuggestedQuestionsForm from "./Introduction/suggestedQuestionsForm";
import LoadingPage from "../LoadingPage";

const enableDocRequired = process.env.REACT_APP_ENABLE_DOC_REQUIRED === 'true';

const FormBuilder: React.FC = () => {
  const [formParts, setFormParts] = useState<newFormPartsType>({
    validationSchema: null,
    components: [],
    showSteps: false,
  });
  const [formSubmitting, setFormSubmitting] = useState<boolean>(false);
  const [loading, setIsLoading] = useState<boolean>(false);
  const initialValues = useRef<any>(null);
  const tempStorage = useRef<any>(null);
  const formRef: any = useRef(null);
  const location = useLocation();
  const {
    authUser,
    authLoading,
    token,
    fetchAndSetAuthUserData,
    updateUserProfileData
  } = useAuth();
  const params = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    setFormSubmitting(true)
    fetchAndSetAuthUserData(authUser.uid, token, true)
      .then(() => {
        setFormSubmitting(false)
      })
  }, [])

  useEffect(() => {
    if (!authLoading) {
      setInitialUserData(authUser);
    }
  }, [authUser, authLoading]);

  useEffect(() => {
    setIsLoading(true);
    setupForm(location)
    .finally(() => {
      setIsLoading(false);
    })
  }, [location])

  const setupForm = async (location: any) => {
    let newFormParts = {
      validationSchema: {},
      components: [],
      showSteps: false,
    };

    if (location.pathname.includes('/update/')) {
      if (!authUser?.index) {
        // redirect user to creator onboarding if they dont have an index set up
        navigate('/creator-onboarding')
        return;
      }
      switch (params?.formType) {
        case ('documents'):
          addSaveButton(newFormParts);
          addDocumentsFormData(newFormParts);
          addGoogleDriveFormData(newFormParts);
          break;
        case ('weblinks'):
          addSaveButton(newFormParts);
          addWeblinksFormData(newFormParts);
          break;
        case ('social'):
          addSaveButton(newFormParts);
          addSocialMediaFormData(newFormParts);
          addTwitterFormData(newFormParts);
          addYoutubeFormData(newFormParts);
          break;
        case ('voice'):
          addSaveButton(newFormParts);
          addVoiceSampleFormData(newFormParts);
          break;
        case ('introduction'):
          addIntroAndSuggestedQuestionsFormData(newFormParts);
          addSaveButton(newFormParts);
          break;
        case ('personality'):
          break;
        case ('journaling'):
          addJournaling(newFormParts);
          break;
        default:
          // redirect if route not found?
          navigate(authUser ? `/${authUser.userName}` : '/')
          break;
      }
    } else if (location.pathname.includes('/creator-onboarding')) {
      newFormParts.showSteps = true;
      addBasicTraining(newFormParts);
      addDocumentsFormData(newFormParts);
      addWeblinksFormData(newFormParts);
      addPremiumTraining(newFormParts);
      addYoutubeFormData(newFormParts);
      addGoogleDriveFormData(newFormParts);
      addVoiceSampleFormData(newFormParts);
      addFooter(newFormParts);
    }
    setFormParts(newFormParts)
  }

  const setInitialUserData = async (userData: any) => {
    // add disabled key for existing user web links
    if (userData?.userWebPage?.length >= 1) {
      userData.userWebPage = userData?.userWebPage?.map((item: any) => ({
        ...item,
        disabled: true,
      }));
    }
    let values = {
      documentId: userData?.documentId || '',
      fullName: userData?.fullName || '',
      userName: userData?.userName || '',
      shortBio: userData?.shortBio || '',
      email: userData?.email || '',
      twitterURL: userData?.twitterURL || '',
      linkedInURL: userData?.linkedInURL || '',
      instagramURL: userData?.instagramURL || '',
      youtubeURL: userData?.youtubeURL || '',
      tiktokURL: userData?.tiktokURL || '',
      userWebPage:
        userData?.userWebPage?.length >= 1
          ? userData?.userWebPage
          : [{ web_link: '', disabled: false }],
      youtube_links:
        userData?.youtube_links?.length >= 1
          ? userData?.youtube_links
          : [],
      google_drive_links: get_google_drive_links(userData),
      anythingElse: userData?.anythingElse || '',
      profilePhoto: userData?.profilePhotoURL || '',
      bannerPhoto: userData?.bannerPhotoUrl || '',
      userType: USER_TYPE.CREATOR,
      index: userData?.index || '',
      age: userData?.age || '',
      occupation: userData?.occupation || '',
      gender: userData?.gender || '',
      birthPlace: userData?.birthPlace || '',
      residencePlace: userData?.residencePlace || '',
      existingProfilePhoto: userData?.profilePhoto || '',
      existingProfilePicUrl: userData?.profilePicUrl || '',
      userDocument: [],
      existingUserDocument: userData?.userDocument || [],
      deletedUserDocument: [],
      // parse the file path in voiceSample to get the file name
      voiceSample:
        typeof userData?.voiceSample === 'string'
          ? userData?.voiceSample.replace(/^.*[\\/]/, '')
          : userData?.voiceSample,
      existingVoiceSample: userData?.voiceSample || '',
      existingBannerPhoto: userData?.bannerPhoto || '',
      existingBannerUrl: userData?.bannerPhotoUrl || '',
    }
    if (userData?.introMessage) {
      if (typeof userData?.introMessage === 'string') {
        values['introMessage'] = {message: userData?.introMessage, chips: ['','','']};
      } else {
        values['introMessage'] = userData?.introMessage;
      }
    }
    initialValues.current = (values);
    if (formRef?.current) {
      formRef.current.setValues(values);
    }
  };

  const handleSubmit = async (values: UserFormType) => {
    let userFormData = { ...values };

    // Prevent mutation of specific keys
    for (let key of IMMUTABLE_USER_KEYS) {
      delete userFormData[key];
    }

    // Proceed with the rest of the validation and submission
    await validateLinkAndDocument(userFormData).then();
    setFormSubmitting(true);

    // This logic should say:
    // if profile step only, skip index all, just modify data and move on

    if (params?.formType !== 'personality') {
      // indexing handled async
      const google_drive_folder_link = userFormData.google_drive_links.map((link) => link.url).filter(Boolean);
      handleGoogleDriveResync(authUser?.uid, google_drive_folder_link)
        .then((res) => {
          // merge google drive userDocuments with existing ones
          // make sure documents are unique by path
          let data = {}
          for (let doc of res.userDocument) {
            // need to update indexed_on and uploadedAt to have correct type
            if (doc.indexed_on) doc.indexed_on = Timestamp.fromDate(new Date(doc.indexed_on))
            if (doc.uploadedAt) doc.uploadedAt = Timestamp.fromDate(new Date(doc.uploadedAt))
            data[doc["path"]] = doc;
          }
          for (let doc of userFormData.existingUserDocument) {
            data[doc["path"]] = doc;
          }
          userFormData.existingUserDocument = Object.values(data);
        }
        ).catch(() => {
        })
        .finally(() => {
          updateUserData(userFormData);
        })

      // redirect user immediately
      navigate(`/${values?.userName}`);
      setFormSubmitting(false);
      toast.success('Data processing, this may take a few moments...');
    }

  };

  const updateUserData = async (values: UserFormType) => {
    updateUserProfileData(values, values?.index ? 'update' : 'create')
      .then(() => {
        // if (!skipRedirect) navigate(`/${values?.userName}`);
        // setFormSubmitting(false);
        // if (!skipRedirect) toast.success('Data processing, this may take a few moments...');
      })
      .catch((error) => {
        setFormSubmitting(false);
        const firebaseError = (error as FirebaseError)?.code;
        toast.error(
          firebaseError && COMMON_FIREBASE_ERROR_CODE[firebaseError]
            ? COMMON_FIREBASE_ERROR_CODE[firebaseError]
            : 'There is some error with the user updating. Please try again'
        );
      });
  };

  const validateLinkAndDocument = async (userFormData: UserFormType) => {
    //check web links data remove delete key and remove empty links
    return new Promise<void>((resolve, reject) => {
      if (userFormData?.userWebPage?.length >= 0) {
        userFormData.userWebPage = userFormData?.userWebPage?.filter((link) => {
          delete link?.disabled;
          return link?.web_link?.trim().length > 0;
        });
      }
      if (enableDocRequired) {
        if (
          (!userFormData?.userWebPage ||
            userFormData?.userWebPage?.length === 0 ||
            userFormData?.userWebPage?.[0]?.web_link === '') &&
          userFormData.userDocument.length === 0 &&
          userFormData.existingUserDocument.length === 0
        ) {
          toast.error('Please provide at least one document or content link');
          reject();
        }
      }
      resolve();
    });
  };

  return (
    (loading || !formParts.components.length) ?
    <LoadingPage /> :
    <Formik
      initialValues={initialValues.current}
      validationSchema={Yup.object(formParts.validationSchema)}
      onSubmit={handleSubmit}
      innerRef={formRef}
      enableReinitialize
    >
      {(props: genericFormProps) => (
        <Form
          id={'form-creator-onboarding'}
          autoComplete='off'
          noValidate
          style={{
            width: '100%',
          }}
        >
          <Box
            display='flex'
            flexDirection='column'
            p={3}
            pb={9} // extra cushion for scrolling
            gap={3}
          >
            {formParts.components.map((component) => {
              switch (component) {
                case ('documents'):
                  return <DocumentsForm key={component} {...props} formSubmitting={formSubmitting} showSteps={formParts.showSteps}/>
                case ('saveButton'):
                  return <SaveButton
                    key={component}
                    {...props}
                    formSubmitting={formSubmitting}
                    validationSchema={Yup.object(formParts.validationSchema)}
                  />
                case ('googleDrive'):
                  return <GoogleDriveForm key={component} {...props} formSubmitting={formSubmitting} showSteps={formParts.showSteps}/>
                case ('weblinks'):
                  return <WebLinksForm key={component} {...props} formSubmitting={formSubmitting} showSteps={formParts.showSteps}/>
                case ('socialMedia'):
                  return <SocialMediaForm key={component} {...props} formSubmitting={formSubmitting} showSteps={formParts.showSteps}/>
                case ('twitter'):
                  return <TwitterForm key={component} {...props} formSubmitting={formSubmitting} showSteps={formParts.showSteps}/>
                case ('youtube'):
                  return <YoutubeLinksForm key={component} {...props} formSubmitting={formSubmitting} showSteps={formParts.showSteps}/>
                case ('voiceSample'):
                  return <VoiceUploadForm key={component} {...props} formSubmitting={formSubmitting} showSteps={formParts.showSteps}/>
                case ('journaling'):
                  return <Journaling key={component}/>
                case ('introMessage'):
                  return <IntroductionForm key={component} {...props} formSubmitting={formSubmitting} showSteps={formParts.showSteps} tempStorage={tempStorage}/>
                case ('suggestedQuestions'):
                  return <SuggestedQuestionsForm key={component} {...props} formSubmitting={formSubmitting} showSteps={formParts.showSteps} tempStorage={tempStorage}/>
                case ('basicTraining'):
                  return <BasicTraining key={component}/>
                case ('premiumTraining'):
                  return <PremiumTraining key={component}/>
                case ('footer'):
                  return <Footer key={component} {...props} formSubmitting={formSubmitting}/>
                default:
                  return <Box key={component}></Box>
              }
            })}
          </Box>
        </Form>
      )}
    </Formik>

  )
}

export default FormBuilder;
