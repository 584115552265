import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate } from 'react-router-dom';
import { useProfileData } from '../../hooks/useProfileData';
import { useAuth } from '../../hooks/useAuth';
import UserProfileData from './userProfileData';
import { usePreloadedIndex } from '../../hooks/usePreloadedIndex';
import LoadingPage from '../LoadingPage';

interface CreatorProfileProps {
  userName: string;
}

export default function CreatorProfile(props: CreatorProfileProps) {
  const { userName } = props;
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [ , ] = usePreloadedIndex();
  const navigate = useNavigate();
  const { authUser } = useAuth();
  const { fetchProfileDataFromUserName, creatorProfileData, isLoadingProfile } =
    useProfileData();

  useEffect(() => {
    // if authUser is updated, also update creatorProfileData
    if (authUser?.userName === creatorProfileData?.userName.toLowerCase()) {
      checkValidUserAndFetchProfileData();
    }
  }, [authUser])

  useEffect(() => {
    if (userName && creatorProfileData?.userName.toLowerCase() !== userName.toLowerCase()) {
      checkValidUserAndFetchProfileData();
    } else {
      setIsLoading(false);
    }
  }, [userName, authUser]); //eslint-disable-line react-hooks/exhaustive-deps

  const checkValidUserAndFetchProfileData = async () => {
    let checkCreatorActive = false;
    if (authUser?.normalizedUserName !== userName) {
      checkCreatorActive = true;
    }
    fetchProfileDataFromUserName(
      userName,
      true,
      checkCreatorActive,
      navigate
    )
    .finally(() => {
      setIsLoading(false);
    })
  };

  return (
    <Box
      sx={{
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      {(
        (userName &&
        creatorProfileData?.userName.toLowerCase() !== userName.toLowerCase()) ||
        (!creatorProfileData?.index && isLoading)
      ) ? (
        <LoadingPage />
      ) : (
        <UserProfileData />
      )}
    </Box>
  );
}
