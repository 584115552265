import { SVGProps, memo } from 'react';
const Aristo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={props.width || '220'}
    height={props.height || '191'}
    viewBox='0 0 220 191'
    fill={props.fill || 'none'}
    stroke={props.stroke || 'none'}
    xmlns='http://www.w3.org/2000/svg'
  >
    <rect
      x='33.5503'
      y='173.012'
      width='150.651'
      height='17.9882'
      rx='8.99408'
      fill='#D9D9D9'
    />
    <path
      d='M173.973 60.811C177.864 65.1243 177.086 69.4377 176.697 73.3589C176.308 77.2801 173.584 85.9068 165.023 85.9068C156.462 85.9068 142.453 74.5353 144.788 66.3007C145.896 62.3923 148.67 62.1057 151.403 62.9671C150.495 58.0657 150.08 46.224 155.684 38.0678C162.688 27.8726 167.747 33.7545 167.747 34.9308C167.747 36.1072 168.136 38.8521 165.023 45.126C162.533 50.1452 162.429 52.9685 162.688 53.7527C164.634 53.848 168.603 54.9313 168.914 58.5028C170.591 58.3472 172.317 58.9756 173.973 60.811Z'
      fill='white'
    />
    <path
      d='M172.806 65.1243C172.806 71.7904 169.693 74.5353 165.023 74.1432C160.353 73.751 159.575 65.9086 162.688 62.3795C164.477 60.352 166.65 58.7128 168.914 58.5028M149.847 71.0062C151.953 73.8366 156.911 76.3478 159.575 74.7953M159.575 74.7953C159.864 74.6272 160.125 74.4114 160.353 74.1432C162.688 71.3983 159.186 67.4771 158.019 66.6928C157.83 66.5662 157.419 66.2557 156.851 65.8586M159.575 74.7953C159.316 76.1464 159.264 79.2408 161.132 80.8092M151.403 62.9671C148.67 62.1057 145.896 62.3923 144.788 66.3007C142.453 74.5353 156.462 85.9068 165.023 85.9068C173.584 85.9068 176.308 77.2801 176.697 73.3589C177.086 69.4377 177.864 65.1243 173.973 60.811C172.317 58.9756 170.591 58.3472 168.914 58.5028M151.403 62.9671C150.495 58.0657 150.08 46.224 155.684 38.0678C162.688 27.8726 167.747 33.7545 167.747 34.9308C167.747 36.1072 168.136 38.8521 165.023 45.126C162.533 50.1452 162.429 52.9685 162.688 53.7527M151.403 62.9671C153.499 63.6276 155.572 64.9633 156.851 65.8586M162.688 53.7527C161.91 53.4913 159.886 53.439 158.019 55.3212C156.151 57.2034 156.462 63.1304 156.851 65.8586M162.688 53.7527C164.634 53.848 168.603 54.9313 168.914 58.5028'
      stroke='black'
      stroke-width='1.32667'
    />
    <path
      d='M130.527 144.192C126.53 143.502 114.984 129.418 118.549 109.451C119.43 104.516 124.581 101.839 129.213 103.754C138.95 107.778 150.581 115.433 151.874 127.038C154.003 146.157 135.068 144.975 130.527 144.192Z'
      fill='#0810FF'
      stroke='black'
      stroke-width='1.32667'
    />
    <path
      d='M113.99 167.165L101.746 122.216C101.667 121.927 101.405 121.727 101.106 121.727H95.4011C95.0013 121.727 94.6924 122.078 94.7431 122.474L101.538 175.541C101.581 175.872 101.862 176.12 102.196 176.12H147.816C148.14 176.12 148.418 175.889 148.447 175.567C148.782 171.823 147.189 162.866 137.87 161.858C128.052 160.797 117.97 161.858 113.99 167.165Z'
      fill='white'
    />
    <path
      d='M113.99 167.165L101.746 122.216C101.667 121.927 101.405 121.727 101.106 121.727H95.4011C95.0013 121.727 94.6924 122.078 94.7431 122.474L101.538 175.541C101.581 175.872 101.862 176.12 102.196 176.12H147.816C148.14 176.12 148.418 175.889 148.447 175.567C148.782 171.823 147.189 162.866 137.87 161.858C128.052 160.797 117.97 161.858 113.99 167.165ZM113.99 167.165L111.005 170.15'
      stroke='black'
      stroke-width='1.32667'
    />
    <path
      d='M89.3449 167.165L87.8155 122.413C87.8027 122.038 88.1032 121.727 88.4784 121.727H94.1732C94.507 121.727 94.7888 121.975 94.8312 122.306L101.626 175.373C101.677 175.769 101.368 176.12 100.968 176.12H55.519C55.1947 176.12 54.9166 175.889 54.8876 175.567C54.5525 171.823 56.1457 162.866 65.4649 161.858C75.2822 160.797 85.3649 161.858 89.3449 167.165Z'
      fill='white'
    />
    <path
      d='M89.3449 167.165L87.8155 122.413C87.8027 122.038 88.1032 121.727 88.4784 121.727H94.1732C94.507 121.727 94.7888 121.975 94.8312 122.306L101.626 175.373C101.677 175.769 101.368 176.12 100.968 176.12H55.519C55.1947 176.12 54.9166 175.889 54.8876 175.567C54.5525 171.823 56.1457 162.866 65.4649 161.858C75.2822 160.797 85.3649 161.858 89.3449 167.165ZM89.3449 167.165L92.3299 170.15'
      stroke='black'
      stroke-width='1.32667'
    />
    <path
      d='M110.368 157.215H89.4731V152.572L110.368 151.577V157.215Z'
      fill='black'
      stroke='black'
      stroke-width='1.32667'
      stroke-linecap='round'
    />
    <path
      d='M62.884 104.519L60.6464 140.097C60.3575 144.69 63.2578 148.878 67.7153 150.022C75.2912 151.966 87.0741 154.562 95.9738 154.562C104.485 154.562 115.634 152.188 123.217 150.28C128.131 149.044 131.084 144.164 130.144 139.185L123.373 103.299C122.486 98.5979 118.379 95.1936 113.596 95.1936H72.8144C67.5616 95.1936 63.2137 99.2767 62.884 104.519Z'
      fill='#0810FF'
      stroke='black'
      stroke-width='1.32667'
    />
    <path
      d='M55.6527 127.224L106.169 112.135C106.227 112.117 106.28 112.093 106.331 112.061C110.511 109.447 119.563 105.876 123.351 111.859C123.449 112.014 123.471 112.215 123.417 112.391C109.149 159.466 72.5755 159.636 55.6087 153.719C55.345 153.628 55.1792 153.381 55.1792 153.102V127.859C55.1792 127.566 55.3717 127.308 55.6527 127.224Z'
      fill='white'
      stroke='black'
      stroke-width='1.32667'
    />
    <path
      d='M108.246 126.371C103.934 133.336 89.8049 147.266 67.7822 147.266'
      stroke='black'
      stroke-width='1.32667'
    />
    <path
      d='M101.071 123.662C96.5683 129.035 82.9184 139.1 64.3438 136.375'
      stroke='black'
      stroke-width='1.32667'
    />
    <path
      d='M32.9575 140.3C30.9135 136.796 34.2681 118.896 51.8482 108.78C56.1929 106.28 61.5558 108.5 63.0704 113.278C66.2537 123.322 67.8761 137.151 59.8225 145.607C46.5558 159.537 35.2792 144.28 32.9575 140.3Z'
      fill='#0810FF'
      stroke='black'
      stroke-width='1.32667'
    />
    <path
      d='M141.589 57.0652C140.42 65.8309 137.198 65.6666 134.276 63.8404C152.158 111.777 133.955 130.35 91.7943 130.35C49.5397 130.35 34.6156 108.46 49.5406 62.7447C47.9579 64.3274 46.0426 65.4657 42.2442 59.6219C39.4734 55.3589 46.2264 44.0776 46.9927 40.6295C48.0479 35.8814 48.8101 27.6818 53.9234 30.6037C55.9152 20.6446 66.7152 12.5062 79.8638 13.9672C84.9857 15.0629 87.1686 6.2972 81.69 5.20148C89.36 -4.29467 111.274 3.74053 108.718 15.0629C123.692 15.0629 127.701 27.824 127.701 30.969C129.406 29.021 133.545 29.6724 134.276 36.2467C135.006 42.821 142.026 53.7869 141.589 57.0652Z'
      fill='white'
      stroke='black'
      stroke-width='1.32667'
    />
    <path
      d='M81.4799 33.5373C77.8136 32.7513 63.1484 31.1792 60.2068 42.4813C65.4863 65.6581 59.7179 61.3461 56.1738 56.2931L59.4655 79.0521C59.7827 81.2456 62.3309 82.3011 64.1063 80.9744L78.2013 70.4417H110.831L123.539 77.0828C125.484 78.0995 127.72 76.6933 127.548 74.505C127.182 69.8607 126.567 62.9943 126.567 52.5875C120.709 66.214 116.677 58.8028 120.709 43.3066C120.709 43.3066 118.152 29.6464 108.611 33.5373C104.586 37.0576 89.5544 38.2535 81.4799 33.5373Z'
      fill='white'
      stroke='black'
      stroke-width='1.32667'
    />
    <path
      d='M60.4307 62.7448C60.4307 66.7625 60.4307 69.6844 64.4483 71.8758'
      stroke='black'
      stroke-width='1.32667'
      stroke-linecap='round'
    />
    <path
      d='M124.578 60.0917C124.578 64.1094 124.578 67.0313 120.56 69.2227'
      stroke='black'
      stroke-width='1.32667'
      stroke-linecap='round'
    />
    <rect
      x='69.5132'
      y='49.0547'
      width='17.2467'
      height='5.97'
      rx='2.985'
      transform='rotate(-20.0854 69.5132 49.0547)'
      fill='white'
      stroke='black'
      stroke-width='1.32667'
    />
    <rect
      width='17.2467'
      height='5.97'
      rx='2.985'
      transform='matrix(-0.933239 -0.359256 -0.359256 0.933239 118.326 48.3235)'
      fill='white'
      stroke='black'
      stroke-width='1.32667'
    />
    <path
      d='M71.8886 90.0311C75.8686 96.0011 93.7786 97.3278 99.5591 83.9055C107.709 92.6844 123.297 92.6844 125.95 83.9055'
      stroke='black'
      stroke-width='1.32667'
      stroke-linecap='round'
      stroke-linejoin='round'
    />
    <rect
      x='94.4973'
      y='54.4551'
      width='20.0502'
      height='23.104'
      rx='10.0251'
      fill='white'
      stroke='black'
      stroke-width='1.32667'
    />
    <rect
      x='98.905'
      y='60.1632'
      width='10.6133'
      height='11.94'
      rx='5.30667'
      fill='black'
      stroke='black'
      stroke-width='1.32667'
    />
    <path
      d='M101.46 65.8552L97.8663 66.6309C97.4843 66.7134 97.1142 66.4495 97.0677 66.0616L96.6904 62.9179C96.6247 62.371 97.2157 61.987 97.6887 62.2691L101.659 64.6371C102.177 64.9456 102.048 65.7281 101.46 65.8552Z'
      fill='white'
    />
    <path
      d='M114.311 70.1632C113.973 74.4572 110.381 77.8365 106 77.8365H103C98.6191 77.8365 95.0274 74.4572 94.6893 70.1632H114.311Z'
      fill='white'
      stroke='black'
      stroke-width='1.32667'
    />
    <path
      d='M100.946 78.3253C102.261 76.4129 101.778 73.7958 99.8653 72.48L95.4785 69.4617L90.7134 76.3873L95.1003 79.4056C97.0127 80.7214 99.6298 80.2378 100.946 78.3253Z'
      fill='white'
    />
    <path
      d='M94.5565 68.8273L99.8653 72.48C101.778 73.7958 102.261 76.4129 100.946 78.3253V78.3253C99.6298 80.2378 97.0127 80.7214 95.1003 79.4056L89.7914 75.7529'
      stroke='black'
      stroke-width='1.32667'
    />
    <rect
      x='75.4104'
      y='54.4552'
      width='20.0502'
      height='23.104'
      rx='10.0251'
      fill='white'
      stroke='black'
      stroke-width='1.32667'
    />
    <rect
      x='79.6687'
      y='60.1632'
      width='10.6133'
      height='11.94'
      rx='5.30667'
      fill='black'
      stroke='black'
      stroke-width='1.32667'
    />
    <path
      d='M95.4319 70.6504C95.096 74.5217 91.847 77.5591 87.8886 77.5591H82.9824C79.024 77.5591 75.775 74.5217 75.4391 70.6504H95.4319Z'
      fill='white'
      stroke='black'
      stroke-width='1.32667'
    />
    <path
      d='M82.4596 65.8553L78.8663 66.631C78.4843 66.7134 78.1142 66.4496 78.0677 66.0616L77.6904 62.918C77.6247 62.3711 78.2157 61.9871 78.6887 62.2692L82.6594 64.6372C83.1766 64.9456 83.0483 65.7282 82.4596 65.8553Z'
      fill='white'
    />
    <path
      d='M46.2242 133.223V162.522C46.2242 165.087 44.1453 167.165 41.5808 167.165C39.0164 167.165 36.9375 165.087 36.9375 162.522V161.527V135.069C36.9375 126.634 42.139 119.072 50.0165 116.055L50.7587 115.771C51.873 115.344 53.1223 115.458 54.1405 116.081C56.3225 117.414 56.6204 120.466 54.7375 122.196L48.5837 127.851C47.08 129.232 46.2242 131.181 46.2242 133.223Z'
      fill='white'
      stroke='black'
      stroke-width='1.32667'
    />
    <path
      d='M49.2085 133.223V162.522C49.2085 165.087 47.1297 167.165 44.5652 167.165C42.0008 167.165 39.9219 165.087 39.9219 162.522V161.527V135.069C39.9219 126.634 45.1233 119.072 53.0008 116.055L53.743 115.771C54.8574 115.344 56.1067 115.458 57.1249 116.081C59.3068 117.414 59.6047 120.466 57.7219 122.196L51.5681 127.851C50.0644 129.232 49.2085 131.181 49.2085 133.223Z'
      fill='white'
      stroke='black'
      stroke-width='1.32667'
    />
    <path
      d='M63.5922 143.514C59.3361 144.854 59.1755 138.598 58.4212 136.613C58.012 135.536 57.8895 134.741 57.3965 133.78C49.1708 134.016 47.954 128.898 48.8335 124.796C49.7129 120.694 54.46 116.563 61.2069 113.611C67.9538 110.66 73.4184 111.188 76.4436 112.587C76.7906 112.748 77.1962 112.938 77.6486 113.156C82.2966 111.804 86.1723 105.234 90.5231 108.955C94.874 112.677 92.5674 116.707 87.7539 119.259C88.9777 120.228 90.1186 121.272 91.0787 122.375C95.2097 127.122 93.9274 130.602 92.4109 133.173C90.8943 135.744 87.1951 135.487 84.441 132.322C82.2378 129.791 78.7359 127.197 77.2604 126.217C83.561 135.183 80.2035 142.923 77.5322 144.035C74.4701 145.309 72.4418 143.343 71.1158 137.94C70.2107 134.252 67.8927 132.524 66.497 132.061C67.9208 137.3 66.773 142.512 63.5922 143.514Z'
      fill='white'
    />
    <path
      d='M54.9642 130.723C56.3014 132.04 56.9807 132.969 57.3965 133.78M64.1505 126.9C65.2404 128.516 66.0156 130.29 66.497 132.061M66.497 132.061C67.9208 137.3 66.773 142.512 63.5922 143.514C59.3361 144.854 59.1755 138.598 58.4212 136.613C58.012 135.536 57.8895 134.741 57.3965 133.78M66.497 132.061C67.8927 132.524 70.2107 134.252 71.1158 137.94C72.4418 143.343 74.4701 145.309 77.5322 144.035C80.2035 142.923 83.561 135.183 77.2604 126.217M73.8422 122.22C75.21 123.567 76.3386 124.905 77.2604 126.217M77.2604 126.217C78.7359 127.197 82.2378 129.791 84.441 132.322C87.1951 135.487 90.8943 135.744 92.4109 133.173C93.9274 130.602 95.2097 127.122 91.0787 122.375C90.1186 121.272 88.9777 120.228 87.7539 119.259M57.3965 133.78C49.1708 134.016 47.954 128.898 48.8335 124.796C49.7129 120.694 54.46 116.563 61.2069 113.611C67.9538 110.66 73.4184 111.188 76.4436 112.587C76.7906 112.748 77.1962 112.938 77.6486 113.156M77.6486 113.156C82.2966 111.804 86.1723 105.234 90.5231 108.955C94.874 112.677 92.5674 116.707 87.7539 119.259M77.6486 113.156C80.2238 114.395 84.3159 116.535 87.7539 119.259'
      stroke='black'
      stroke-width='1.32667'
    />
    <rect
      x='118.659'
      y='156.884'
      width='40.4634'
      height='20.5633'
      rx='1.99'
      fill='white'
      stroke='black'
      stroke-width='1.32667'
    />
    <rect
      x='113.353'
      y='176.783'
      width='51.0767'
      height='8.62334'
      rx='4.31167'
      fill='white'
      stroke='black'
      stroke-width='1.32667'
    />
    <rect
      x='113.353'
      y='149.587'
      width='51.0767'
      height='8.62334'
      rx='4.31167'
      fill='white'
      stroke='black'
      stroke-width='1.32667'
    />
    <path d='M126.288 161.195V172.803' stroke='black' stroke-width='1.32667' />
    <path d='M138.56 161.195V172.803' stroke='black' stroke-width='1.32667' />
    <path d='M150.831 161.195V172.803' stroke='black' stroke-width='1.32667' />
    <rect
      x='96.1062'
      y='142.29'
      width='60.3634'
      height='8.62334'
      rx='4.31167'
      fill='white'
      stroke='black'
      stroke-width='1.32667'
    />
    <path
      d='M153.768 86.3643C155.877 82.0265 160.278 79.2734 165.101 79.2734H211.07C214.009 79.2734 215.934 82.3506 214.649 84.9941L186.037 143.822C183.927 148.16 179.527 150.913 174.703 150.913H128.735C125.795 150.913 123.87 147.836 125.156 145.193L153.768 86.3643Z'
      fill='white'
      stroke='black'
      stroke-width='1.32667'
    />
    <path
      d='M179.464 115.687C178.869 117.292 177.883 118.55 176.803 119.297C175.721 120.046 174.601 120.251 173.657 119.902C172.714 119.552 171.998 118.666 171.665 117.393C171.333 116.122 171.405 114.526 172 112.921C172.595 111.315 173.58 110.057 174.661 109.31C175.743 108.561 176.863 108.356 177.807 108.706C178.75 109.055 179.466 109.941 179.799 111.214C180.131 112.485 180.059 114.081 179.464 115.687Z'
      fill='#0810FF'
      stroke='black'
      stroke-width='1.32667'
    />
    <path
      d='M159.123 86.5699L131.594 143.285'
      stroke='black'
      stroke-width='1.32667'
    />
  </svg>
);

export default memo(Aristo);
