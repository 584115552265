import {
  Box,
  CircularProgress,
  Stack,
  Divider,
  Grid,
  useTheme,
} from '@mui/material';
import { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
import {
  getxistingJournalEntries,
  handleDeleteJournalChat,
} from '../../utils/api';
import EmptyOrDefaultListHeader from './journalingHeaders/EmptyOrDefaultListHeader';
import ViewEntryHeader from './journalingHeaders/ViewEntryHeader';
import EditEntryHeader from './journalingHeaders/EditEntryHeader';
import JournalList from './JournalList';
import JournalDetail, { JournalEntryItemRef } from './JournalDetail';
import EmptyListPage from './EmptyListPage';
import ConfirmDeleteModal from './ConfirmDeleteModal';
import DetectScreenSize from './DetectScreenSize';
import { toast } from 'react-toastify';

const PAGE_LIMIT = 30;

const ScreenModes = {
  EMPTY_LIST: 'emptyList',
  DEFAULT_LIST: 'defaultList',
  VIEW_ENTRY: 'viewEntry',
  EDIT_ENTRY: 'editEntry',
};

const ScreenSizes = {
  XS: 'xs',
  SM: 'sm',
  MD: 'md',
  LG: 'lg',
  XL: 'xl',
};

const Journaling = () => {
  const { authUser } = useAuth();
  const [journalEntries, setJournalEntries] = useState([]);
  const [selectedJournalEntry, setSelectedJournalEntry] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleting, setIsDeletingEntry] = useState(false);
  const [isFullyLoaded, setIsFullyLoaded] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [isSaveEnabled, setIsSaveEnabled] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [hasFetchedData, setHasFetchedData] = useState(false);
  let saveDetailRef = null;
  const currentPage = useRef(1);
  const bottom = useRef(null);
  const loadingRef = useRef(null);
  const [screenMode, setScreenMode] = useState(ScreenModes.DEFAULT_LIST);
  const screenSize = DetectScreenSize();
  const theme = useTheme();
  const navigate = useNavigate();

  const isScreenMdOrLarger = [
    ScreenSizes.MD,
    ScreenSizes.LG,
    ScreenSizes.XL,
  ].includes(screenSize);

  useEffect(() => {
    handleFetchData();
  }, []);

  const handleFetchData = () => {
    if (!isFullyLoaded && !isLoading) {
      setIsLoading(true);

      getxistingJournalEntries(authUser?.uid, currentPage.current, PAGE_LIMIT)
        .then((resp) => {
          if (!resp.ok) throw new Error('Failed to fetch data');
          return resp.json();
        })
        .then((data) => {
          currentPage.current += 1;

          if (
            !data.history ||
            !Array.isArray(data.history) ||
            data.history.length === 0
          ) {
            setIsFullyLoaded(true);
            setIsLoading(false);
            setHasFetchedData(true);
            return; 
          }

          const updatedEntries = data.history.map((entry, index) => ({
            ...entry,
            selected: currentPage.current === 2 && index === 0,
          }));

          setJournalEntries((prevData) => [...prevData, ...updatedEntries]);

          if (currentPage.current === 2 && updatedEntries.length > 0) {
            setSelectedJournalEntry(updatedEntries[0]);
          }

          setIsLoading(false);
          setHasFetchedData(true);
        })
        .catch(() => {
          setIsFullyLoaded(true);
          setIsLoading(false);
          setHasFetchedData(true);
        });
    }
  };

  const postJournalEntry = async (authUserId, journalId) => {
    try {
      let resp = await handleDeleteJournalChat(authUserId, journalId);
      if (resp) {
        toast.success('Deleted journal entry successfully');
        setIsDeletingEntry(false);
        navigate(0);
      } else {
        toast.error('Unable to save journal entry');
      }
    } catch (err) {
      setIsDeletingEntry(false);
      console.log(err);
    }
  };

  const handleBackClick = () => {
    if (screenMode === ScreenModes.VIEW_ENTRY) {
      if (!isScreenMdOrLarger) {
        setScreenMode(ScreenModes.DEFAULT_LIST);
      }
    } else if (screenMode === ScreenModes.EDIT_ENTRY) {
      if (isScreenMdOrLarger) {
        setScreenMode(ScreenModes.DEFAULT_LIST);
      } else {
        setScreenMode(ScreenModes.VIEW_ENTRY);
      }
    }
  };

  const handleJournalTitleClick = (entry) => {
    setSelectedJournalEntry(entry);
    if (!isScreenMdOrLarger) {
      setScreenMode(ScreenModes.VIEW_ENTRY);
    }
  };

  const handleNewEntryClick = () => {
    navigate(`/${authUser?.userName}/update/journaling/newEntry`);
  };

  const handleEditClick = () => {
    setScreenMode(ScreenModes.EDIT_ENTRY);
  };

  const handleDeleteClick = () => {
    setShowDeleteDialog(true);
  };

  const handleConfirmedDeleteClick = async (e) => {
    e.preventDefault();
    setIsDeletingEntry(true);
    try {
      await postJournalEntry(authUser?.uid, selectedJournalEntry.docId);
    } catch (error) {
      console.log(error);
    }
  };

  const detailsRef = useRef<JournalEntryItemRef>(null);

  // Handler for save button click
  const handleSave = () => {
    // Call the saveDetails method exposed by the Details component
    if (detailsRef.current) {
      detailsRef.current.saveDetails();
    }
  };

  return (
    <Box
      sx={{
        height: { xs: `calc(100vh - 106px)`, md: '100vh' },
        margin: `calc(-3 * var(--mui-spacing))`,
        marginBottom: `calc(-9 * var(--mui-spacing))`,
        overflow: 'hidden',
        scrollbarWidth: 'none', // Firefox
        '&::-webkit-scrollbar': { display: 'none' },
      }}
    >
      <Stack
        direction='column'
        sx={{
          width: '100%',
          height: { xs: `calc(100vh - 106px)`, md: '100vh' },
          margin: `auto`,
          maxWidth: 'lg',
          overflow: 'hidden',
          scrollbarWidth: 'none', // Firefox
          '&::-webkit-scrollbar': { display: 'none' },
        }}
      >
        <>
          {screenMode === ScreenModes.EMPTY_LIST ||
          screenMode === ScreenModes.DEFAULT_LIST ? (
            <EmptyOrDefaultListHeader
              onNewEntryClick={handleNewEntryClick}
              isScreenMdOrLarger={isScreenMdOrLarger}
            />
          ) : screenMode === ScreenModes.VIEW_ENTRY ? (
            isScreenMdOrLarger ? (
              <EmptyOrDefaultListHeader
                onNewEntryClick={handleNewEntryClick}
                isScreenMdOrLarger={isScreenMdOrLarger}
              />
            ) : (
              <ViewEntryHeader
                handleBackClick={handleBackClick}
                handleEditClick={handleEditClick}
              />
            )
          ) : screenMode === ScreenModes.EDIT_ENTRY ? (
            <EditEntryHeader
              handleBackClick={handleBackClick}
              isSaveEnabled={isSaveEnabled}
              isSaving={isSaving}
              handleSave={handleSave}
            />
          ) : (
            <></>
          )}
        </>
        <Divider sx={{ borderColor: 'divider' }} />
        <>
          {currentPage.current === 1 && isLoading ? (
            <Box sx={{ margin: 'auto' }}>
              <CircularProgress size={30} />
            </Box>
          ) : journalEntries.length !== 0 || isLoading || !hasFetchedData ? (
            <Box
              sx={{
                width: '100%',
                flex: 1,
                p: '20px',
                maxHeight: isScreenMdOrLarger
                  ? `calc(100vh - 56px)`
                  : `calc(100vh - 162px)`,
              }}
            >
              {isScreenMdOrLarger ? (
                <Grid
                  container
                  direction='row'
                  height='100%'
                  style={{ flexGrow: 1 }}
                >
                  <JournalList
                    journalEntries={journalEntries}
                    isLoading={isLoading}
                    isFullyLoaded={isFullyLoaded}
                    selectedJournalEntry={selectedJournalEntry}
                    handleJournalTitleClick={handleJournalTitleClick}
                    handleFetchData={handleFetchData}
                    loadingRef={loadingRef}
                    isScreenMdOrLarger={isScreenMdOrLarger}
                  />
                  {selectedJournalEntry && (
                    <JournalDetail
                      ref={detailsRef}
                      entry={selectedJournalEntry}
                      screenMode={screenMode}
                      handleEditClick={handleEditClick}
                      authUserId={authUser?.uid}
                      isSaveEnabled={isSaveEnabled}
                      setIsSaveEnabled={setIsSaveEnabled}
                      isSaving={isSaving}
                      setIsSaving={setIsSaving}
                      isScreenMdOrLarger={isScreenMdOrLarger}
                      isDeleting={isDeleting}
                      handleDeleteClick={() => setShowDeleteDialog(true)}
                    />
                  )}
                </Grid>
              ) : (
                <Grid container direction='row' height='100%'>
                  {screenMode === ScreenModes.DEFAULT_LIST ? (
                    <JournalList
                      journalEntries={journalEntries}
                      isLoading={isLoading}
                      isFullyLoaded={isFullyLoaded}
                      selectedJournalEntry={selectedJournalEntry}
                      handleJournalTitleClick={handleJournalTitleClick}
                      handleFetchData={handleFetchData}
                      loadingRef={loadingRef}
                      isScreenMdOrLarger={isScreenMdOrLarger}
                    />
                  ) : screenMode === ScreenModes.VIEW_ENTRY ||
                    screenMode === ScreenModes.EDIT_ENTRY ? (
                    selectedJournalEntry && (
                      <JournalDetail
                        ref={detailsRef}
                        entry={selectedJournalEntry}
                        screenMode={screenMode}
                        handleEditClick={handleEditClick}
                        authUserId={authUser?.uid}
                        isSaveEnabled={isSaveEnabled}
                        setIsSaveEnabled={setIsSaveEnabled}
                        isSaving={isSaving}
                        setIsSaving={setIsSaving}
                        isScreenMdOrLarger={isScreenMdOrLarger}
                        isDeleting={isDeleting}
                        handleDeleteClick={() => setShowDeleteDialog(true)}
                      />
                    )
                  ) : (
                    <></>
                  )}
                </Grid>
              )}
            </Box>
          ) : (
            <EmptyListPage onNewEntryClick={handleNewEntryClick} />
          )}
        </>
      </Stack>
      <ConfirmDeleteModal
        isDeleting={isDeleting}
        handleCloseClick={() => setShowDeleteDialog(false)}
        handleConfirmedDeleteClick={handleConfirmedDeleteClick}
        showDeleteDialog={showDeleteDialog}
      />
    </Box>
  );
};

export default Journaling;
