import React, { useRef, useEffect } from 'react';
import { TextField, Box } from '@mui/material';
import { styled } from '@mui/material/styles';

const HighlightedSpan = styled('span')(({ theme }) => ({
  fontWeight: 'bold',
  color: theme.palette.primary.main,
}));

interface ScrollingTextFieldProps {
  chunks: string[];
  activeChunkIndex: number;
  placeholder?: string;
}

const ScrollingTextField = React.forwardRef<
  HTMLDivElement,
  ScrollingTextFieldProps
>(({ chunks, activeChunkIndex, placeholder, ...props }, ref) => {
  const textFieldRef = useRef<HTMLDivElement>(null);
  const chunkRefs = useRef<(HTMLSpanElement | null)[]>([]);

  useEffect(() => {
    if (activeChunkIndex >= 0 && chunkRefs.current[activeChunkIndex]) {
      const element = chunkRefs.current[activeChunkIndex];
      const container = textFieldRef.current;

      if (element && container) {
        container.scrollTo({
          top: element.offsetTop - container.offsetTop - 30,
          behavior: 'smooth',
        });
      }
    }
  }, [activeChunkIndex]);

  const renderChunks = () => {
    return chunks.map((chunk, index) => (
      <span
        key={index}
        ref={(el) => {
          chunkRefs.current[index] = el;
          console.log(`Ref set for index ${index}:`, el);
        }}
        style={{ display: 'block', marginBottom: '8px' }}
      >
        {index === activeChunkIndex ? (
          <HighlightedSpan>{chunk}</HighlightedSpan>
        ) : (
          chunk
        )}
      </span>
    ));
  };

  return (
    <Box
      ref={textFieldRef}
      sx={(theme) => ({
        border: `1px solid ${theme.palette.text.primary}`,
        borderRadius: '4px',
        padding: '8px',
        overflowY: 'auto',
        maxHeight: '140px',
        minHeight: '140px',
        whiteSpace: 'pre-wrap',
        width: '100%',
        color: theme.palette.text.primary,
      })}
    >
      {chunks.length > 0 ? renderChunks() : placeholder}
    </Box>
  );
});

export default ScrollingTextField;
