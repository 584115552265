import React from 'react';
import { Box, Typography, Stack, useTheme } from '@mui/material';

interface JournalTitleProps {
  entry: {
    docId?: string;
    title?: string;
    message?: string;
    character_count?: number;
    emotion?: string[];
    noted_at?: string;
    response?: string;
    room_id?: string;
    sentiment?: string;
    summary?: string;
    tags?: string[];
    updatedAt?: string;
    user_id?: string;
    word_count?: number;
  };
  selected: boolean;
  isScreenMdOrLarger: boolean;
}

const JournalListItem: React.FC<JournalTitleProps> = ({ entry, selected, isScreenMdOrLarger }) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        backgroundColor: selected && isScreenMdOrLarger
        ? theme.palette.primary._states.selected
        : theme.palette.background['paper-elevation-1'],
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: '4px',
        py: 1,
        px: 1.5,
        marginBottom: '12px',
      }}
    >
      <Stack direction='column' spacing={0.5}>
        {entry.noted_at && (
          <Typography variant='caption' color={theme.palette.text.secondary}>
            {/* {entry.noted_at} */}
            {new Date(entry.noted_at).toLocaleDateString('en-US', {
              month: 'short',
              day: 'numeric',
              year: 'numeric',
            })}
          </Typography>
        )}
        {entry.title && (
          <Typography
            variant='body2'
            color={theme.palette.text.primary}
            sx={{
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: 2,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {entry.title}
          </Typography>
        )}
        {!entry.title && entry.message && (
          <Typography
            variant='body2'
            color={theme.palette.text.primary}
            sx={{
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: 2,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {entry.message}
          </Typography>
        )}
      </Stack>
    </Box>
  );
};

export default JournalListItem;
