import {
  Timestamp,
  addDoc,
  collection,
  doc,
  documentId,
  getDocs,
  getDocsFromCache,
  limit,
  orderBy,
  query,
  updateDoc,
  where,
  startAt,
  getDoc,
} from 'firebase/firestore';
import { UserFormType } from '../views/creatorOnBoarding/signup.types';
import { firestore } from './firebase';
import { MessageDataType } from '../views/userChat/messages.types';
import { USER_TYPE, SPECIAL_DOMAINS } from '../config/const';
import { deleteCourse } from './api';

const enableDevTools = process.env.REACT_APP_ENABLE_DEVTOOLS === 'true';

//return preview url for firebase media
export const returnFireStoreURL = (mediaURL: string) => {
  return `${process.env.REACT_APP_FIREBASE_APP_URL}${
    process.env.REACT_APP_FIREBASE_API_VERSION
  }/b/${process.env.REACT_APP_FIREBASE_STORAGE_BUCKET}/o/${encodeURIComponent(
    mediaURL
  )}?alt=media`;
};

//return share profile url
export const returnShareLink = (userName: string) => {
  return `${window.location.origin}/${userName}/chat/`;
};

//modify user data with firebase media url and share link
export const modifyUserData = (userProfileData: any) => {
  let shareLink: string = returnShareLink(userProfileData?.userName);
  const profilePhotoURL: string = userProfileData?.profilePicUrl; // uses profilePicUrL from user profile
  let userData: any = userProfileData;
  if (userProfileData?.profilePhoto) {
    userData = {
      ...userData,
      profilePhotoURL,
    };
  }
  return { ...userData, shareLink };
};

//get first 100 messages data from firestore using room id
export const fetchMessagesData = async (roomId: number | string) => {
  const messageQuery = query(
    collection(firestore, 'messages'),
    where('roomId', '==', roomId),
    orderBy('sentTime', 'desc'),
    limit(100)
  );

  return await getDocs(messageQuery);
};

// save messages data to firestore
export const saveMessagesData = async (messageData: MessageDataType) => {
  try {
    const docRef = await addDoc(collection(firestore, 'messages'), messageData);
    return { ...messageData, documentId: docRef?.id };
  } catch (error) {
    console.log(error);
  }
};

// update particular messages data to firestore
export const updateMessagesData = async (
  documentId: string,
  messageData: Record<any, any>
) => {
  try {
    await updateDoc(doc(firestore, 'messages', documentId), messageData);
    return { ...messageData, documentId: documentId };
  } catch (error) {
    console.log(error);
  }
};

// fetch creator type user data from the username
export const getCreatorDataFromUserName = async (userName: string) => {
  const result = await getDocs(
    query(
      collection(firestore, 'users'),
      where('normalizedUserName', '==', userName.toLowerCase()),
      where('userType', '==', USER_TYPE.CREATOR),
      limit(1)
    )
  );
  if (result?.docs.length >= 1) {
    return { ...result?.docs?.[0]?.data(), documentId: result?.docs?.[0]?.id };
  }
  return;
};

export const fetchUserDataFromID = async (id: string) => {
  const result = await getDocs(
    query(
      collection(firestore, 'users'),
      where(documentId(), '==', id),
      limit(1)
    )
  );
  if (result?.docs.length >= 1) {
    return { ...result?.docs?.[0]?.data(), documentId: result?.docs?.[0]?.id };
  }
  return;
};

// get fan list from user documentIds
export const getFanListFromIds = async (userIds: string[]) => {
  const fanUserData = {};
  const fanUserIds = sliceIntoChunks(userIds, 5); //divide array into sub array
  // for (let i = 0; i < fanUserIds?.length; i++) {
  //   const fanUserResult = await getDocs(
  //     query(
  //       collection(firestore, 'users'),
  //       where(documentId(), 'in', fanUserIds[i])
  //     )
  //   );
  //   fanUserResult.forEach((fanUser) => {
  //     if (fanUser?.id) {
  //       fanUserData[fanUser?.id] = {
  //         documentId: fanUser?.id,
  //         ...modifyUserData(fanUser?.data()),
  //       };
  //     }
  //   });
  // }
  return Promise.all(fanUserIds.map((val) => {
    return getDocs(
      query(
        collection(firestore, 'users'),
        where(documentId(), 'in', val)
      )
    ).then((fanUserResult) => {
      fanUserResult.forEach((fanUser) => {
        if (fanUser?.id) {
          fanUserData[fanUser?.id] = {
            documentId: fanUser?.id,
            ...modifyUserData(fanUser?.data()),
          };
        }
      });
    })
    .catch((error) => {
      if (enableDevTools) console.log(val, error);
    })
  }))
  .then(() => {
    return fanUserData;
  })
};

export function getFormattedDate(date: Date) {
  var year = date.getFullYear();

  var month = (1 + date.getMonth()).toString();
  month = month.length > 1 ? month : '0' + month;

  var day = date.getDate().toString();
  day = day.length > 1 ? day : '0' + day;

  return month + '/' + day + '/' + year;
}

// divide an array to the chunk and return the combination of array ex. arr:[1,2,3,4,5,6,8] chunkSize:2 => [[1,2],[3,4],[5,6],[7,8]]
export const sliceIntoChunks = (arr: any, chunkSize: number) => {
  const res = [];
  for (let i = 0; i < arr.length; i += chunkSize) {
    const chunk = arr.slice(i, i + chunkSize);
    res.push(chunk);
  }
  return res;
};

// fetch user streaks data and return it
export const getCreatorsFanStreaksData = async (queryParams: any) => {
  const creatorId = queryParams?.queryKey?.[1]; // creator Id
  const returnTopStreaks = queryParams?.queryKey?.[2]; // return top streaks if true only top streaks data will return else all steaks data return
  const creatorsData = {};
  let queryLimit: any;
  let queryWhereOne: any;
  const queryCollection = collection(firestore, 'streaks');
  const queryOrder = orderBy('lastInteractionDate', 'desc');
  const queryOrderOne = orderBy('streaksCount', 'desc');
  // if returnTopStreaks true return 3 streak data only as top streaks with streaks count greater or equal one
  if (returnTopStreaks) {
    queryWhereOne = where('streaksCount', '>=', 1);
    queryLimit = limit(3);
  }
  if (creatorId) {
    const queryWhere = where('creatorId', '==', creatorId);
    // if returnTopStreaks true set conditional when and limit query params
    const queryRef = returnTopStreaks
      ? query(
          queryCollection,
          queryWhere,
          queryWhereOne,
          queryLimit,
          queryOrderOne,
          queryOrder
        )
      : query(queryCollection, queryWhere, queryOrderOne, queryOrder);
    // check data exists in react query cache if empty means nothing is catch and write latest data from firebase
    let fanStreaksResult = await getDocsFromCache(queryRef);
    if (fanStreaksResult?.empty) {
      if (enableDevTools) console.log('no fan streak data in cache, fetching now...')
      fanStreaksResult = await getDocs(queryRef);
    } else {
      if (enableDevTools) console.log('found fan streak data in cache')
    }
    if (fanStreaksResult?.docs?.length >= 1) {
      fanStreaksResult.forEach((fanUser) => {
        if (fanUser?.id) {
          creatorsData[fanUser?.data()?.fanId] = {
            ...fanUser?.data(),
          };
        }
      });
    }

    //combine streaks data with creators/personality list data
    if (Object.keys(creatorsData).length >= 1) {
      const fanUserIds = Object.keys(creatorsData).map(
        (userId) => creatorsData[userId]?.fanId
      );

      if (fanUserIds.length >= 1) {
        // let fanUsersProfileData: any = await getFanListFromIds(fanUserIds);
        // // filter fans to remove any broken user accounts
        // let filteredFanIds = Object.keys(fanUsersProfileData).filter((id: any) => {
        //                           return fanUsersProfileData[id].fullName && fanUsersProfileData[id].userName
        //                         });

        // if (filteredFanIds.length >= 1) {
        //   return filteredFanIds.map((userId: any) => ({
        //     ...creatorsData?.[userId],
        //     fanProfile: fanUsersProfileData?.[userId],
        //   }));
        // }
        return getFanListFromIds(fanUserIds)
          .then((fanUsersProfileData) => {
            //filter fans to remove any broken user accounts
            let filteredFanIds = Object.keys(fanUsersProfileData).filter((id: any) => {
                                      return fanUsersProfileData[id].fullName && fanUsersProfileData[id].userName
                                    });

            if (filteredFanIds.length >= 1) {
              return filteredFanIds.map((userId: any) => ({
                ...creatorsData?.[userId],
                fanProfile: fanUsersProfileData?.[userId],
              }));
            }
            return []
          })
          .catch((err) => {
            if (enableDevTools) console.log(err)
            return []
          })
      }
    }
  }
  return [];
};

// function check the current route path is chat or not
export const returnIsLocationChatScreen = (location: any) => {
  if (location?.pathname) {
    const path = location?.pathname?.split('/') || '';
    if (path?.[2] === 'chat') {
      return true;
    }
  }
  return false;
};

// fetch all creator/personality users list default 6 records will load, return matched records if searchText has any value
export const getAllCreatorsList = async (
  searchText = '',
  lastSnapshot?: any,
  dataLimit = 6
) => {
  if (lastSnapshot === false) return undefined; // if last snapshot is false means next set of document are not available and pagination gets disabled

  const searchValue = searchText?.toLowerCase()?.trim();
  const paginatedLimit = dataLimit + 1; // increase the limit by one to check next set of document exists or not

  try {
    const querySnapshot = lastSnapshot
      ? await getDocs(
          query(
            collection(firestore, 'users'),
            where('userType', '==', USER_TYPE.CREATOR),
            where('normalizedFullName', '>=', searchValue), // firebase not support like query so using <= => operators to perform search in string
            where('normalizedFullName', '<=', searchValue + '\uf8ff'), // firebase not support like query so using <= => operators to perform search in string
            orderBy('normalizedFullName', 'asc'),
            orderBy('normalizedUserName', 'asc'),
            startAt(lastSnapshot), // pass last snapshot to tell firebase query fetch document after this snapshot/document
            limit(paginatedLimit)
          )
        )
      : await getDocs(
          query(
            collection(firestore, 'users'),
            where('userType', '==', USER_TYPE.CREATOR),
            where('normalizedFullName', '>=', searchValue),
            where('normalizedFullName', '<=', searchValue + '\uf8ff'),
            orderBy('normalizedFullName', 'asc'),
            orderBy('normalizedUserName', 'asc'),
            limit(paginatedLimit)
          )
        );

    // Get the last visible document
    const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
    const fetchedDocument: UserFormType[] = querySnapshot.docs.map(
      (doc: any) => ({
        documentId: doc.id,
        ...modifyUserData(doc.data()),
      })
    );

    const resultDocuments = fetchedDocument?.slice(0, dataLimit); // remove the last visible document from the actual result that is going to display
    return {
      items: resultDocuments,
      lastVisible:
        fetchedDocument?.length > dataLimit ? lastVisible : undefined,
    };
  } catch (e) {
    console.error(' -> error: ', e);
    return { items: [], lastVisible: undefined };
  }
};

// Query list of all creators from firestore database
export const query_creators = async () => {
  const querySnapshot = await getDocs(
    query(collection(firestore, 'users'), where('userType', '==', 'creator'))
  );
  return querySnapshot.docs.map((doc) => doc.data());
};

// Query newly created users that are fans from firestore database
export const query_data = async (startDate, endDate, creatorID: string) => {
  let querySnapshot = null;
  if (startDate === null || endDate === null) {
    querySnapshot = await getDocs(
      query(
        collection(firestore, 'users'),
        where('userType', '==', 'fan'),
        where('creators', 'array-contains', creatorID)
      )
    );
  } else {
    querySnapshot = await getDocs(
      query(
        collection(firestore, 'users'),
        where('userType', '==', 'fan'),
        where('createdAt', '>=', Timestamp.fromDate(startDate)),
        where('createdAt', '<=', Timestamp.fromDate(endDate)),
        where('creators', 'array-contains', creatorID)
      )
    );
  }
  return querySnapshot.docs.map((doc) => doc.data());
};

export const query_creator_chatlogs = async (creatorID: string) => {
  const querySnapshot = await getDocs(
    query(
      collection(firestore, 'messages'),
      where('roomId', '>', creatorID),
      where('roomId', '<=', creatorID + '\uf8ff'),
      orderBy('roomId', 'asc'),
      orderBy('sentTime', 'desc')
    )
  );

return await Promise.all(querySnapshot.docs.map(async (doc) => {
    const data = doc.data();
    const id = doc.id;
    let username = 'anonymous'
    let fullName = 'Anonymous'
    let profilePicUrl = ''
    if (data && data['senderId']) {
      const result  = await fetchUserDataFromID(data['senderId'])
      if (result) {
        username = result['userName'] ? result['userName'] : result['username'] ? result['username'] : "anonymous"
        fullName = result['fullName'] ? result['fullName'] : "Anonymous"
        profilePicUrl = result['profilePicUrl'] ? result['profilePicUrl']  : ""
      }
    }
    return { id, username, fullName, profilePicUrl, ...data }
  }));
};

export const update_creator_chatlogs = async (
  docId: string,
  message: string
) => {
  const docRef = doc(firestore, 'messages', docId);

  try {
    await updateDoc(docRef, {
      editedMessage: message,
    });
    console.log('Document successfully updated!');
  } catch (error) {
    console.error('Error updating document: ', error);
  }
};

// Removes all emojis from input string
export const removeEmojis = (str: string) => {
  return str.replace(/([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g, '')
    .replace(/\s+/g, ' ')
    .replaceAll(/[^a-zA-Z]+$/gi, "")
    .trim();
}

// Adds spaces between letters of acronyms before sending to voice
// regex catches words that are all capital letters or symbols
export const processAcronyms = (str: string) => {
  let words = str.split(/[\s-]+/);
  let arr = [];
  let acronymExceptions = ['MIMIO']
  for (let word of words){
    let isAcronym = word.match(/\b(?=.*[A-Z])[A-Z]{2,}\b/g);
    if (isAcronym && !acronymExceptions.includes(word)){
      if (word.toUpperCase() === word){
        let chars = word.split('');
        arr.push(chars.join('.'));
      } else {
        arr.push(word);
      }
    } else {
      arr.push(word);
    }
  }
  return arr.join(' ');
}

export function removeHTML(msg: string){
  let doc = new DOMParser().parseFromString(msg, 'text/html');
  return doc.body.textContent || "";
}

// Function to find and replace links in a given text
export function findAndReplaceLinks(text) {
  //////////////////////////////////////////////////////////////////////////////
  // Temporary replace emails with a token to prevent URL regex from transforming them
  const emailRegex = /[\w.-]+@[\w.-]+\.[\w.-]+/gi;
  const emails = {};
  text = text.replace(emailRegex, (match) => {
    const token = `EMAIL_TOKEN_${Object.keys(emails).length}`;
    emails[token] = `[${match}](mailto:${match})`;
    return token;
  });

  // Temporary replace markdown links with a token to prevent URL regex from transforming them
  const markdownRegex = /\[([^\[]+)\]\((https?:\/\/[^\s\)]+)\)/gi; //eslint-disable-line
  const markdowns = {};
  text = text.replace(markdownRegex, (match, label, url) => {
    const token = `MARKDOWN_TOKEN_${Object.keys(markdowns).length}`;
    markdowns[token] = `[${label}](${url})`;
    return token;
  });
  //////////////////////////////////////////////////////////////////////////////

  // Regular expression for detecting URLs which are not already in <a> tags
  const urlRegex = /(?:https?:\/\/)?(?:www\.)?([a-z0-9-]+(?:\.[a-z]+)+)(?:\/[^\s.,'">\]\)]+)?(?![^<>]*>|[^"]*?<\/a)/gi; //eslint-disable-line
  text = text.replace(urlRegex, (match, domain) => {
    const isSpecialDomain = SPECIAL_DOMAINS.test(domain);
    if (isSpecialDomain) {
      const protocol = match.toLowerCase().startsWith('http://') || match.toLowerCase().startsWith('https://') ? '' : 'http://';
      const link = protocol + match;
      return `[${match}](${link})`;
    }
    return match;
  });

  //////////////////////////////////////////////////////////////////////////////
  // Replace email tokens back to their original text
  text = text.replace(/EMAIL_TOKEN_(\d+)/g, (match, index) => { //eslint-disable-line
    return emails[match];
  });

  // Replace email tokens back to their original text
  text = text.replace(/MARKDOWN_TOKEN_(\d+)/g, (match, index) => { //eslint-disable-line
    return markdowns[match];
  });
  //////////////////////////////////////////////////////////////////////////////

  return text;
}


export function preprocessTextForVoice(msg: string){
  // remove any html from string
  let doc = new DOMParser().parseFromString(msg, 'text/html');
  let text = doc.body.textContent || "";

  let replacementMap = {};
  let replacementCount = -1;


  //////////////////////////////////////////////////////////////////////////////
  // replace all the markdown
  // Replace bold text with plain text
  text = text.replace(/\*\*(.+?)\*\*/g, (_, group) => group); //eslint-disable-line
  text = text.replace(/__(.+?)__/g, (_, group) => group); //eslint-disable-line

  // Replace italicized text with plain text
  text = text.replace(/_(.+?)_/g, (_, group) => group); //eslint-disable-line
  text = text.replace(/\*(.+?)\*/g, (_, group) => group); //eslint-disable-line

  // Replace strikethrough text with plain text
  text = text.replace(/~~(.+?)~~/g, (_, group) => group); //eslint-disable-line

  // Replace code blocks with plain text
  text = text.replace(/```([\s\S]*?)```/gm, (_, group) => group); //eslint-disable-line
  text = text.replace(/```([\s\S]*?)```/gm, (_, group) => group); //eslint-disable-line

  // Replace inline code blocks with plain text
  text = text.replace(/`(.+?)`/g, (_, group) => group); //eslint-disable-line

  // links:
  const markdownLinkRegex = /\[(.*?)\]\((.*?)\)/g; //eslint-disable-line
  text = text.replace(markdownLinkRegex, (_, __, group2) => group2)

  // Latex math:
  text = text.replace(/\\\((.+?)\\\)|\\\[(.+?)\\\]/gm, (match, mathText1, mathText2) => { //eslint-disable-line
    if (mathText1) return preprocessMathforVoice(mathText1);
    if (mathText2) return preprocessMathforVoice(mathText2);
    return match
  })

  //////////////////////////////////////////////////////////////////////////////
  // temporarily find all replace all URLs
  // replace with strings that are easy for voice to read
  const urlRegex = /(?:https?:\/\/)?(?:www\.)?([a-z0-9-]+(?:\.[a-z]+)+)(?:\/[^\s.,'">]+)?/gi; //eslint-disable-line
  text = text.replace(urlRegex, (match, domain) => {
    // Check if the domain ends with .edu, .gov, .com, .org, .net
    const isSpecialDomain = SPECIAL_DOMAINS.test(domain);
    let output = match;

    if (isSpecialDomain) {
      let editedString = match;
      editedString = editedString.replace(/\.(edu|org|ai|us|uk)$/i, (match) => {return match.toUpperCase()})
      editedString = editedString.replace(/\.(edu|org|ai|us|uk)\//i, (match) => {return match.toUpperCase()})
      editedString = editedString.replace(/https:\/\//gm, () => {return " "})
      editedString = editedString.replace(/http:\/\//gm, () => {return " "})
      editedString = editedString.replace(/\//gm, () => {return " slash "})
      editedString = editedString.replace(/-/gm, () => {return " dash "})
      editedString = editedString.replace(/\./gm, () => {return " dot "})
      editedString = editedString.replace(/www/gm, () => {return " WWW "})
      output = editedString;
    }

    replacementCount++;
    replacementMap[`REPLACEMENT_${replacementCount}TOKEN`] = output;
    return `REPLACEMENT_${replacementCount}TOKEN`
  });

  //////////////////////////////////////////////////////////////////////////////

  // replace all tokens with corresponding strings
  text = text.replace(/REPLACEMENT_(\d+)TOKEN/g, (match, _) => {
    return replacementMap[match];
  });

  return text;
}

// Function for taking latex math and converting to voice
const preprocessMathforVoice = (input: string) => {
  let output = input;

  if (output) {
    // replace fractions
    output = output.replace(/\\frac\{(.+?)\}\{(.+?)\}/g, (_, group1, group2) => `${group1} divided by ${group2}`)

    // replace functions
    output = output.replace(/([fguvxy]'?'?)\((.+?)\)/gi, (_, group1, group2) => `${group1} of ${group2}`)

    // replace derivatives
    output = output.replace(/[\']/g, () => ' prime ') //eslint-disable-line

    // replace power
    output = output.replace(/[\^]/g, () => ' to the power of ')

    // replace \cdot
    output = output.replace(/\\cdot/g, () => ' times ');

    // replace \cdot
    output = output.replace(/[\=]/g, () => ' equals '); //eslint-disable-line

    // replace parenthesis
    output = output.replace(/\\left\(/g, () => '(');
    output = output.replace(/\\right\)/g, () => ')');
  }

  return output;
}

// Function to fetch assignment results from Firestore
export const fetchAssignmentResults = async (courseId, studentId) => {
  const resultId = `${courseId}.${studentId}`;
  const docRef = doc(firestore, 'results', resultId);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    return docSnap.data();
  } else {
    console.log('No such document!');
    return null;
  }
};

export const handleDeleteCourse = (courseData, authUser) => {
  if (courseData && authUser && authUser.documentId === courseData.creator_id){
    console.log('Deleting course ', courseData.course_id)
    deleteCourse(courseData.course_id).catch(error => {
        console.error('Error deleting course on unmount:', error);
    });
  }
}

export const preprocessLaTeX = (content: string) => {
  if (!content) return content;

  //////////////////////////////////////////////////////////////////////////////
  // Temporarily replace \[ \] and \( \)
  let equations = {};
  // Replace block-level LaTeX delimiters \[ \] with $$ $$
  const blockProcessedContent = content.replace(
    /\\\[(.*?)\\\]/gi,
    (_, equation) => {
      const token = `EQUATIONS_TOKEN_${Object.keys(equations).length}`;
      equations[token] = `$$${equation}$$`;
      return token;
    },
  );
  // Replace inline LaTeX delimiters \( \) with $ $
  const inlineProcessedContent = blockProcessedContent.replace(
    /\\\((.*?)\\\)/gi,
    (_, equation) => {
      const token = `EQUATIONS_TOKEN_${Object.keys(equations).length}`;
      equations[token] = `$${equation}$`;
      return token;
    },
  );

  // Replace html a tags with markdown
  const atagsProcessedContent = inlineProcessedContent.replace(
    /<a\s+[^>]*href="([^"]*)"[^>]*>(.*?)<\/a>/gi,
    (_, link, text) => {
      const token = `EQUATIONS_TOKEN_${Object.keys(equations).length}`;
      equations[token] = `[${text}](${link})`;
      return token;
    }
  );

  // Escape all dollar signs $ so they're not latex
  const dollarProcessedContent = atagsProcessedContent.replace(
    /\$/g,
    (_) => {
      return "\\$";
    }
  );

  let text = dollarProcessedContent.replace(/EQUATIONS_TOKEN_(\d+)/g, (match, _) => {
    return equations[match];
  });

  return text;
};

export function wrapPromise(promise) {
  let status = "pending";
  let result;
  let suspender = promise.then(
    (r) => {
      status = "success";
      result = r;
    },
    (e) => {
      status = "error";
      result = e;
    }
  );
  return {
    read() {
      if (status === "pending") {
        throw suspender;
      } else if (status === "error") {
        throw result;
      } else if (status === "success") {
        return result;
      }
    },
  };
}

export function get_google_drive_links(
  userData: {
    google_drive_links?: {url: string}[],
  }): {url: string}[] {
  // // input: userData from creatorProfileData
  // // output: array of google drive links found in userData

  // if (userData?.userDocument){
  //   let drive_docs = userData.userDocument.filter((entry) => {
  //     return entry?.source === 'google_drive'
  //   })

  //   let data = new Set<string>()
  //   for (let doc of drive_docs) {
  //     if (doc){
  //       let formatted_str = doc.source_id.split('google_drive_folder_')[1]
  //       formatted_str = `https://drive.google.com/drive/folders/${formatted_str}?usp=sharing`;
  //       data.add(formatted_str)
  //     }
  //   }

  //   let all_links = Array.from(data)
  //   return all_links.map((link) => {
  //     return {url: link}
  //   });
  // }
  // return []; // return empty array by default

  let google_drive_links =
        (userData?.google_drive_links?.length >= 1) ?
          userData?.google_drive_links
          : [];

  return google_drive_links;
}
