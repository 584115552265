import { Box } from "@mui/material"
import Lottie from "lottie-react";
import LoadingFace from '../../assets/animations/LoadingFace.json'

const LoadingPage: React.FC = () => {
  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& path': {
          fill: (theme) => theme.palette.primary.main
        }
      }}
    >
      <Lottie
        animationData={LoadingFace}
        loop={true}
        style={{
          width: '100px',
          height: '100px',
        }}
      />
    </Box>
  )
}

export default LoadingPage;
