import * as React from 'react';
import { useDropzone } from 'react-dropzone';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Badge from '@mui/material/Badge';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import AddIcon from '@mui/icons-material/Add';
import UploadRounded from '@mui/icons-material/UploadRounded';
import { toast } from 'react-toastify';
import { auth } from '../../utils/firebase';
import { handleVerifyEmail } from './helper';
import { FormMode } from './signup.types';
import { SUPPORTED_IMAGE_MIME_TYPES } from '../../config/const';
import InputAdornment from '@mui/material/InputAdornment';

interface CreatorOnBoardingStepOneProps {
  values: any;
  errors: any;
  touched: any;
  handleChange: any;
  handleBlur: any;
  setFieldValue: any;
  setTouched: any;
  setErrors: any;
  isValid: any;
  dirty: any;
  formSubmitting: boolean;
  formMode: FormMode;
}

const AGE = [
  { value: '18-24', label: '18-24' },
  { value: '25-34', label: '25-34' },
  { value: '35-44', label: '35-44' },
  { value: '45-54', label: '45-54' },
  { value: '55-64', label: '55-64' },
  { value: '65-74', label: '65-74' },
  { value: '75-84', label: '75-84' },
  { value: '85+', label: '85+' },
];

const GENDER = [
  { value: 'Female', label: 'Female' },
  { value: 'Male', label: 'Male' },
  { value: 'Non-Binary', label: 'Non-Binary' },
  { value: 'Gender Fluid', label: 'Gender Fluid' },
  { value: 'A gender not listed', label: 'A gender not listed' },
  { value: 'Unsure how to describe', label: 'Unsure how to describe' },
  { value: 'Prefer not to say', label: 'Prefer not to say' },
];

export default function CreatorOnBoardingStepOne(
  props: CreatorOnBoardingStepOneProps
) {
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    setFieldValue,
    setTouched,
    formSubmitting,
    formMode,
  } = props;

  const onDrop = React.useCallback(
    async (acceptedFiles: any) => {
      const isValid = fileValidator(acceptedFiles[0]);
      if (!isValid) {
        toast.error('Please select a valid banner image');
        setTouched({ ...touched, bannerPhoto: true });
        setFieldValue('bannerPhoto', '');
      }
      if (isValid) {
        setFieldValue('bannerPhoto', acceptedFiles[0]);
      }
    },
    [touched, setTouched, setFieldValue]
  );

  const fileValidator = (file: any) => {
    if (!SUPPORTED_IMAGE_MIME_TYPES.includes(file?.type)) {
      return false;
    }
    return true;
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'image/*': ['.jpeg', '.jpg', '.png'],
    },
    maxFiles: 1,
  });

  const avatarInput = React.useCallback(
    (values, touched, setTouched, setFieldValue) => {
      const profileUploaded =
        values?.profilePhoto &&
        typeof values?.profilePhoto === 'object' &&
        values?.profilePhoto?.name
          ? URL.createObjectURL(values?.profilePhoto)
          : values?.profilePhoto && typeof values?.profilePhoto === 'string'
          ? values?.profilePhoto
          : '';

      return (
        <Box
          borderRadius='12px'
          display='flex'
          gap={1}
          flexDirection='column'
          alignItems='flex-start'
          width='100%'
          position='relative'
        >
          <input
            type='file'
            accept='image/*,image/heif,image/heic'
            id='file-input-profile-photo'
            name='profilePhoto'
            hidden
            onChange={(event) => {
              setTouched({ ...touched, profilePhoto: true });
              setFieldValue('profilePhoto', event?.currentTarget?.files?.[0]);
            }}
          />
          <label htmlFor='file-input-profile-photo' style={{ cursor: 'pointer' }}>
          <Box
            display="flex"
            alignItems="center"
            gap={2} // Add space between avatar and text
            width="100%"
          >
            <Avatar
              alt={values?.fullName}
              src={profileUploaded}
              sx={{
                width: "193px", // Explicitly set width
                height: "193px", // Explicitly set height
                borderRadius: "78px", // Custom border-radius
                border: "1px solid rgba(66, 66, 66, 0.5)", // Outlined border
                opacity: 1, // Ensure opacity is not 0
                objectFit: "cover", // Ensure the image fits properly
                fontSize: "2.375rem",
              }}
            >
              {values?.fullName ? values?.fullName[0] : ''}
            </Avatar>
          </Box>
          </label>
        </Box>
      );
    },
    []
  );

  {/* const bannerInput = React.useCallback(
    (values) => {
      const bannerUploaded =
        values?.bannerPhoto &&
        typeof values?.bannerPhoto === 'object' &&
        values?.bannerPhoto?.name
          ? URL.createObjectURL(values?.bannerPhoto)
          : values?.bannerPhoto && typeof values?.bannerPhoto === 'string'
          ? values?.bannerPhoto
          : '';

      return (
        <Box mb={2.5} width='100%' position='relative'>
          <Box
            {...getRootProps()}
            width='100%'
            height='150px'
            border='1px solid #B3B3B3;'
            borderRadius='12px'
            sx={{ cursor: 'pointer' }}
          >
            {bannerUploaded ? (
              <img
                src={bannerUploaded}
                alt='user-banner'
                height='100%'
                width='100%'
                style={{
                  borderRadius: '12px',
                  objectFit: 'cover',
                }}
              />
            ) : (
              <Box
                height='100%'
                width='100%'
                display='flex'
                flexDirection='column'
                justifyContent='center'
                alignItems='center'
                color='grey.500'
                gap={1}
              >
                <UploadRounded sx={{ fontSize: '40px' }} />
                <Typography color='grey.500' variant='body2'>
                  Choose a file or drag image here
                </Typography>
              </Box>
            )}
            <input id='file-input-banner-image' {...getInputProps()} />
            {isDragActive && <p>Drop the image here ...</p>}
            <Typography
              variant='body2'
              color='rgba(0, 0, 0, 0.6)'
              sx={{
                position: 'absolute',
                top: '-10px',
                left: '10px',
                backgroundColor: '#FFFFFF',
                px: '6px',
                zIndex: 99,
                borderRadius: '6px',
              }}
            >
              Banner Image
            </Typography>
          </Box>
        </Box>
      );
    },
    [getRootProps, getInputProps, isDragActive]
  );
  */}
  const getSocialMediaPrefix = (socialKey) => {
    switch (socialKey) {
      case 'linkedIn':
        return 'https://www.linkedin.com/in/';
      case 'youtube':
        return 'https://www.youtube.com/@';
      case 'tiktok':
        return 'https://www.tiktok.com/@';
      case 'instagram':
        return 'https://www.instagram.com/';
      default:
        return '';
    }
  };

  return (
    <Box
      display='flex'
      flex={1}
      flexDirection='column'
      alignItems='Left'
      justifyContent='left'
      width="100%"
      gap='24px'
    >
      <Box width='100%'>
        {avatarInput(values, touched, setTouched, setFieldValue)}
        {touched?.profilePhoto && Boolean(errors?.profilePhoto) && (
          <Typography
            variant='body1'
            color={'error'}
            fontSize={'0.75rem'}
            mt={1}
          >
            {errors?.profilePhoto as string}
          </Typography>
        )}
      </Box>

      {formMode === 'update' && (
        <Box width='100%'>
          {touched?.bannerPhoto && Boolean(errors?.bannerPhoto) && (
            <Typography
              variant='body1'
              color={'error'}
              fontSize={'0.75rem'}
              mt={1}
            >
              {errors?.bannerPhoto as string}
            </Typography>
          )}
        </Box>
      )}
      <Box sx={{  width: '100%' }}>
        {/* Label */}
        <Typography
            variant="h7" // Changed from "h6" to "h7" as per screenshot
            sx={{
              fontWeight: 'fontWeightSemiBold', // Ensure the weight matches as 'SemiBold'
              fontSize: '16px', // Updated to match screenshot
              lineHeight: '19.2px', // Set line-height as per screenshot
              letterSpacing: '0.15px', // Added letter spacing
              color: 'text.primary', // Use theme color for text
              mb: 1, // Space below the label
            }}
          >
            Name
          </Typography>


              {/* Text Box */}
              <TextField
                fullWidth
                id="text-input-fullName"
                name="fullName"
                value={values.fullName}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.fullName && Boolean(errors.fullName)}
                helperText={touched.fullName && errors.fullName}
              />
            </Box>



      <Box sx={{ width: '100%' }}>
        {/* Label for Username */}
        <Typography
            variant="h7" // Changed from "h6" to "h7" as per screenshot
            sx={{
              fontWeight: 'fontWeightSemiBold', // Ensure the weight matches as 'SemiBold'
              fontSize: '16px', // Updated to match screenshot
              lineHeight: '19.2px', // Set line-height as per screenshot
              letterSpacing: '0.15px', // Added letter spacing
              color: 'text.primary', // Use theme color for text
              mb: 1, // Space below the label
            }}
          >
          Username
        </Typography>

        {/* Text Field for Username */}
        <TextField
          fullWidth
          id="text-input-userName"
          name="userName"
          value={values.userName}
          disabled
        />
      </Box>

      {/*<Box sx={{ mb: 3, width: '100%' }}>
        <Typography
          variant="h6"
          sx={{
            fontWeight: '600',
            fontSize: '1rem',
            color: 'rgba(0, 0, 0, 0.87)',
            mb: 1,
          }}
        >
          Email Address
        </Typography>

        <TextField
          fullWidth
          id="text-input-email"
          name="email"
          value={values.email}
          disabled
          sx={{
            '& .MuiOutlinedInput-root': {
              borderRadius: '12px',
              fontSize: '1rem',
            },
          }}
        />
      </Box> */}

      <Box sx={{ width: '100%' }}>
        {/* Label for Occupation */}
        <Typography
            variant="h7" // Changed from "h6" to "h7" as per screenshot
            sx={{
              fontWeight: 'fontWeightSemiBold', // Ensure the weight matches as 'SemiBold'
              fontSize: '16px', // Updated to match screenshot
              lineHeight: '19.2px', // Set line-height as per screenshot
              letterSpacing: '0.15px', // Added letter spacing
              color: 'text.primary', // Use theme color for text
              mb: 1, // Space below the label
            }}
          >
          Occupation (50 Character Max)
        </Typography>

        {/* Text Field for Occupation */}
        <TextField
          type='string'
          fullWidth
          id="text-input-occupation"
          name="occupation"
          value={values.occupation}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.occupation && Boolean(errors.occupation)}
          helperText={touched.occupation && errors.occupation}
        />
      </Box>

      <Box sx={{ width: '100%' }}>
        {/* Label for Biography */}
        <Typography
            variant="h7" // Changed from "h6" to "h7" as per screenshot
            sx={{
              fontWeight: 'fontWeightSemiBold', // Ensure the weight matches as 'SemiBold'
              fontSize: '16px', // Updated to match screenshot
              lineHeight: '19.2px', // Set line-height as per screenshot
              letterSpacing: '0.15px', // Added letter spacing
              color: 'text.primary', // Use theme color for text
              mb: 1, // Space below the label
            }}
          >
          Biography
        </Typography>

          {/* Text Field for Biography */}
          <TextField
          fullWidth
          multiline
          rows={3}
          id="text-input-shortBio"
          name="shortBio"
          value={values.shortBio}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.shortBio && Boolean(errors.shortBio)}
          helperText={touched.shortBio && errors.shortBio}
        />
      </Box>


      <Box display="flex" flexDirection="column"  width="100%">
        {/* Section Title */}
        <Typography
          variant="h6"
          sx={{
            fontWeight: 600,
            fontSize: '1rem',
            color: 'rgba(0, 0, 0, 0.87)',
            mb: 0.5,
          }}
        >
          Share Social Accounts
        </Typography>

        {/* Section Description */}
        <Typography
          variant="body2"
          sx={{
            fontSize: '0.85rem',
            color: 'rgba(0, 0, 0, 0.6)',
            mb: 2,
          }}
        >
          Allow others to follow you on different channels.
        </Typography>

        {/* Social Media Inputs */}
        <Box display="flex" flexDirection="column" gap={3} width="100%">
          {Object.entries({
            linkedIn: 'LinkedIn',
            youtube: 'YouTube',
            tiktok: 'TikTok',
            instagram: 'Instagram',
          }).map(([socialKey, socialLabel]) => (
            <Box key={socialKey} sx={{ display: 'flex', flexDirection: 'column' }}>
              {/* Social Media Label */}
              <Typography
                  variant="h7" // Changed from "h6" to "h7" as per screenshot
                  sx={{
                    fontWeight: 'fontWeightSemiBold', // Ensure the weight matches as 'SemiBold'
                    fontSize: '16px', // Updated to match screenshot
                    lineHeight: '19.2px', // Set line-height as per screenshot
                    letterSpacing: '0.15px', // Added letter spacing
                    color: 'text.primary', // Use theme color for text
                    mb: 1, // Space below the label
                  }}
                >
                {socialLabel}
              </Typography>

              {/* Social Media Input Field */}
              <TextField
                disabled={formSubmitting}
                fullWidth
                id={`text-input-${socialKey}`}
                placeholder={`https://www.${socialKey}.com/`}
                name={`${socialKey}URL`}
                value={values[`${socialKey}URL`]}
                onChange={handleChange}
                onBlur={handleBlur}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Typography
                      >
                        {getSocialMediaPrefix(socialKey)}
                      </Typography>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          ))}
        </Box>
      </Box>
      



      {formMode !== "update" && (

      <Box display="flex" flexDirection="column" width="100%">
        {/* Section Title */}
        <Typography
          variant="h6"
          sx={{
            fontWeight: 600,
            fontSize: '1rem',
            color: 'rgba(0, 0, 0, 0.87)',
            mb: 0.5,
          }}
        >
          Personal Information
        </Typography>

        {/* Section Description */}
        <Typography
          variant="body2"
          sx={{
            fontSize: '0.85rem',
            color: 'rgba(0, 0, 0, 0.6)',
            mb: 2,
          }}
        >
          This information will NOT be visible on your profile but helps your AI persona understand your base personality
        </Typography>
      {/* Age */}
    <Box display="flex" flexDirection="column" mb="24px" width="100%">
      <Typography
            variant="h7" // Changed from "h6" to "h7" as per screenshot
            sx={{
              fontWeight: 'fontWeightSemiBold', // Ensure the weight matches as 'SemiBold'
              fontSize: '16px', // Updated to match screenshot
              lineHeight: '19.2px', // Set line-height as per screenshot
              letterSpacing: '0.15px', // Added letter spacing
              color: 'text.primary', // Use theme color for text
              mb: 1, // Space below the label
            }}
          >
          Age
        </Typography>
        <TextField
          fullWidth
          disabled={formSubmitting}
          name="age"
          id="text-input-age"
          value={values.age}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.age && Boolean(errors.age)}
          helperText={touched.age && errors.age}
          placeholder="Enter your age"
          sx={{
            '& .MuiOutlinedInput-root': {
              borderRadius: '12px',
              fontSize: '1rem',
              minWidth: '240px',
              height: '40px', 
              backgroundColor: 'rgba(253, 253, 253, 1)', 
              border: '1px solid rgba(66, 66, 66, 0.5)',
              '&:hover': {
                borderColor: 'rgba(66, 66, 66, 1)',
              },
            },
            '& .MuiFormHelperText-root': {
              marginLeft: '8px',
            },
          }}
        />
      </Box>


      <Box mb="24px" sx={{ width: '100%' }}>
        {/* Label for Gender */}
        <Typography
            variant="h7" // Changed from "h6" to "h7" as per screenshot
            sx={{
              fontWeight: 'fontWeightSemiBold', // Ensure the weight matches as 'SemiBold'
              fontSize: '16px', // Updated to match screenshot
              lineHeight: '19.2px', // Set line-height as per screenshot
              letterSpacing: '0.15px', // Added letter spacing
              color: 'text.primary', // Use theme color for text
              mb: 1, // Space below the label
            }}
          >
          Gender
        </Typography>

        {/* Select Field for Gender */}
        <FormControl fullWidth>
        <Select
          fullWidth
          id="select-input-gender"
          name="gender"
          value={values.gender}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.gender && Boolean(errors.gender)}
          sx={{
            borderRadius: '12px', // Rounded corners
            fontSize: '1rem',
            minWidth: '240px', // Minimum width
            height: '40px', // Fixed height
            backgroundColor: 'rgba(253, 253, 253, 1)', // Background color
            border: '1px solid rgba(66, 66, 66, 0.5)', // Border style
            '&:hover': {
              borderColor: 'rgba(66, 66, 66, 1)', // Border on hover
            },
            '.MuiSelect-outlined': {
              padding: '8px 16px', // Adjust padding
            },
          }}
          MenuProps={{
            PaperProps: {
              sx: {
                borderRadius: '12px', // Rounded dropdown menu
                boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', // Optional shadow for the dropdown
              },
            },
          }}
        >
          {GENDER.map((genderItem, index) => (
            <MenuItem key={index} value={genderItem.value}>
              {genderItem.label}
            </MenuItem>
          ))}
        </Select>
        {touched.gender && errors.gender && (
          <FormHelperText error>{errors.gender}</FormHelperText>
        )}
      </FormControl>
      </Box>

      {/* Birthplace */}
      <Box display="flex" flexDirection="column" mb="24px" width="100%">
      <Typography
            variant="h7" // Changed from "h6" to "h7" as per screenshot
            sx={{
              fontWeight: 'fontWeightSemiBold', // Ensure the weight matches as 'SemiBold'
              fontSize: '16px', // Updated to match screenshot
              lineHeight: '19.2px', // Set line-height as per screenshot
              letterSpacing: '0.15px', // Added letter spacing
              color: 'text.primary', // Use theme color for text
              mb: 1, // Space below the label
            }}
          >
          Birthplace - City, State, Country
        </Typography>
        <TextField
          fullWidth
          disabled={formSubmitting}
          name="birthPlace"
          id="text-input-birthPlace"
          value={values.birthPlace}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.birthPlace && Boolean(errors.birthPlace)}
          helperText={touched.birthPlace && errors.birthPlace}
          placeholder="Enter your birthplace"
          sx={{
            '& .MuiOutlinedInput-root': {
              borderRadius: '12px',
              fontSize: '1rem',
              minWidth: '240px',
              height: '40px', 
              backgroundColor: 'rgba(253, 253, 253, 1)', 
              border: '1px solid rgba(66, 66, 66, 0.5)',
              '&:hover': {
                borderColor: 'rgba(66, 66, 66, 1)',
              },
            },
            '& .MuiFormHelperText-root': {
              marginLeft: '8px',
            },
          }}
        />
      </Box>


      {/* Current Residence */}
      <Box display="flex" flexDirection="column" mb="24px" width="100%">
        <Typography
            variant="h7" // Changed from "h6" to "h7" as per screenshot
            sx={{
              fontWeight: 'fontWeightSemiBold', // Ensure the weight matches as 'SemiBold'
              fontSize: '16px', // Updated to match screenshot
              lineHeight: '19.2px', // Set line-height as per screenshot
              letterSpacing: '0.15px', // Added letter spacing
              color: 'text.primary', // Use theme color for text
              mb: 1, // Space below the label
            }}
          >
          Current Residence - City, State, Country
        </Typography>
        <TextField
          fullWidth
          disabled={formSubmitting}
          name="residencePlace"
          id="text-input-residencePlace"
          value={values.residencePlace}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.residencePlace && Boolean(errors.residencePlace)}
          helperText={touched.residencePlace && errors.residencePlace}
          placeholder="Enter your current residence"
          sx={{
            '& .MuiOutlinedInput-root': {
              borderRadius: '12px',
              fontSize: '1rem',
              minWidth: '240px',
              height: '40px', 
              backgroundColor: 'rgba(253, 253, 253, 1)', 
              border: '1px solid rgba(66, 66, 66, 0.5)',
              '&:hover': {
                borderColor: 'rgba(66, 66, 66, 1)',
              },
            },
            '& .MuiFormHelperText-root': {
              marginLeft: '8px',
            },
          }}
        />
      </Box>
      </Box>

    )}

    </Box>
  );
}
