import {
  Box,
  Button,
  Modal,
  Backdrop,
  Typography,
  CircularProgress,
  TextField,
  Stack,
  Divider,
  useTheme,
  IconButton,
} from '@mui/material';

import { useContext, useState, useEffect } from 'react';
import { JournalSaveContext } from '../../context/JournalSaveContext';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
import { handleSaveJournalChat } from '../../utils/api';
import { toast } from 'react-toastify';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DeleteIcon from '../../svg/DeleteIcon';

const NewJournalEntry = () => {
  const [journalTitle, setJournalTitle] = useState('');
  const [journalText, setJournalText] = useState('');
  const [isSavingEntry, setSavingEntry] = useState(false);
  const [showDiscardDialog, setShowDiscardDialog] = useState(false);
  const journalTextPlaceholderText =
    'Write or paste your journal entry here. This information will be used to train your AI Persona’s knowledge and personality.';
  const journalTitlePlaceholderText = 'Write your journal title here';
  const { authUser } = useAuth();
  const navigate = useNavigate();
  const theme = useTheme();
  const [currentTime, setCurrentTime] = useState('');

  useEffect(() => {
    const now = new Date();
    setCurrentTime(
      now.toLocaleString('en-US', {
        month: 'short',
        day: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        hour12: true,
      })
    );
  }, []);

  const handleJournalEntrySave = async (e) => {
    e.preventDefault();
    setSavingEntry(true);
    try {
      await postJournalEntry(journalTitle, journalText);
    } catch (error) {
      console.log(error);
    }
  };

  const postJournalEntry = async (journalTitle, journalText) => {
    try {
      let resp = await handleSaveJournalChat(
        authUser?.uid,
        journalTitle,
        journalText
      );
      if (resp) {
        toast.success('Saved journal entry successfully');
        setSavingEntry(false);
        navigate(-1);
      } else {
        toast.error('Unable to save journal entry');
      }
    } catch (err) {
      setSavingEntry(false);
      console.log(err);
    }
  };

  const handleJournalTitle = (e) => {
    setJournalTitle(e.target.value);
  };

  const handleJournalText = (e) => {
    setJournalText(e.target.value);
  };

  const handleDiscardClick = () => {
    setShowDiscardDialog(true);
  };

  const handleBackClick = () => {
    navigate(-1);
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'calc(100% - 32px)',
    margin: 'auto',
    maxWidth: '600px',
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: '10px',
    p: 4,
  };

  return (
    <Stack
      direction='column'
      maxWidth='lg'
      sx={{
        width: '100%',
        height: '100vh',
        margin: `auto`,
      }}
    >
      <Stack
        direction='row'
        alignItems='center'
        justifyContent='space-between'
        sx={{ width: '100%', height: '56px', px: '20px', position: 'relative' }}
      >
        <IconButton
          onClick={handleBackClick}
          sx={{
            color: 'primary.main',
            backgroundColor: 'secondary.light',
            '&:hover': {
              backgroundColor: 'secondary.main',
            },
          }}
        >
          <ArrowBackIcon />
        </IconButton>

        <Typography
          variant='h7'
          color='text.primary'
          sx={{
            position: 'absolute',
            left: '50%',
            transform: 'translateX(-50%)',
            whiteSpace: 'nowrap',
          }}
        >
          New Entry
        </Typography>

        {/* <Button
          variant='contained'
          color='primary'
          size='medium'
          startIcon={
            <DeleteIcon
              width='18px'
              height='18px'
              stroke={theme.palette.primary.contrastText}
            />
          }
          onClick={handleDiscardClick}
        >
          Discard
        </Button> */}
        <Button
            variant='contained'
            color='primary'
            size='medium'
            disabled={
              isSavingEntry || journalTitle === '' || journalText === ''
            }
            onClick={handleJournalEntrySave}
          >
            {'Save'}
            {isSavingEntry && (
              <CircularProgress size={18} sx={{ position: 'absolute' }} />
            )}
          </Button>
      </Stack>
      <Divider sx={{ borderColor: 'divider' }} />
      <Box
        display='flex'
        flexDirection='column'
        gap={2}
        width='100%'
        height='100%'
        padding='24px'
      >
        <Box
          sx={{
            border: (theme) => `1px solid ${theme.palette.divider}`,
            padding: '24px',
            display: 'flex',
            flexDirection: 'column',
            flex: { md: 1, xs: 'unset' },
          }}
        >
          <TextField
            placeholder={journalTitlePlaceholderText}
            value={journalTitle}
            onChange={handleJournalTitle}
            sx={{
              width: '100%',
              '& .MuiInputBase-input': {
                fontSize: '1.25rem',
                fontWeight: 'bold',
                padding: '0px',
              },
              '& .MuiOutlinedInput-notchedOutline': {
                border: 'none',
              },
            }}
          />

          <Typography variant='body2' color='textSecondary' paddingBottom='8px'>
            {currentTime.replace(',', ' @')}
          </Typography>

          <TextField
            multiline
            minRows={8}
            placeholder={journalTextPlaceholderText}
            value={journalText}
            onChange={handleJournalText}
            sx={{
              width: '100%',
              '& .MuiInputBase-root': {
                padding: '0px !important', // Use !important to ensure override
              },
              '& .MuiInputBase-input': {
                padding: '0px !important',
              },
              '& .MuiOutlinedInput-notchedOutline': {
                border: 'none',
              },
            }}
          />
        </Box>

        <Box
          className='journal-entry-save-btn'
          width='100%'
          display='flex'
          justifyContent='right'
        >
          {/* <Button
            variant='contained'
            size='large'
            disabled={
              isSavingEntry || journalTitle === '' || journalText === ''
            }
            onClick={handleJournalEntrySave}
          >
            {'Save'}
            {isSavingEntry && (
              <CircularProgress size={18} sx={{ position: 'absolute' }} />
            )}
          </Button> */}
          <Button
          variant='contained'
          color='secondary'
          size='large'
          disabled={isSavingEntry}
          startIcon={
            <DeleteIcon
              width='18px'
              height='18px'
              stroke={theme.palette.secondary.contrastText}
            />
          }
          onClick={handleDiscardClick}
        >
          Discard
        </Button>
        </Box>

        <Modal
          sx={{ padding: '16px' }}
          open={showDiscardDialog}
          slots={{ backdrop: Backdrop }}
          slotProps={{ backdrop: { timeout: 500 } }}
        >
          <Box
            sx={style}
            display='flex'
            flexDirection='column'
            justifyContent='center'
            gap='20px'
          >
            <Box>
              <strong>Discard Journal Entry?</strong>
              <Typography variant='body2'>
                This entry will be lost and your AI Persona will not be updated.
              </Typography>
            </Box>
            <Stack
              direction='row'
              justifyContent='flex-end'
              spacing={1.25}
              paddingTop='32px'
            >
              <Button
                variant='outlined'
                onClick={() => {
                  setShowDiscardDialog(false);
                  navigate(-1);
                }}
              >
                Discard
              </Button>
              <Button
                variant='contained'
                sx={{ borderRadius: '30px', height: '40px !important' }}
                onClick={() => setShowDiscardDialog(false)}
              >
                Continue Editing
              </Button>
            </Stack>
          </Box>
        </Modal>
      </Box>
    </Stack>
  );
};

export default NewJournalEntry;
