import { Box, Button, Divider, IconButton, Link, Typography } from "@mui/material";
import { genericFormProps } from "../signup.types";
import { useCallback, useContext } from "react";
import { TierContext } from "../../../context/TierContext";
import { InsertDriveFile, Upload } from "@mui/icons-material";
import { verifyFileSize } from "../helper";
import DeleteIcon from '@mui/icons-material/Delete';

// const enableDevTools = process.env.REACT_APP_ENABLE_DEVTOOLS === 'true';

const VoiceUploadForm: React.FC<genericFormProps> = (props) => {
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    setFieldValue,
    formSubmitting,
    isValid,
    dirty,
    showSteps
  } = props;

  const { checkPermission } = useContext(TierContext)

  const renderVoiceListItem = useCallback(
    (document: any, index: number, onDeleteHandler: () => void) => (
      <Box
        key={`voicesample-${index}`}
        display='flex'
        alignItems='center'
        justifyContent='space-between'
        columnGap='12px'
        sx={{
          borderBottom: 1,
          borderColor: (theme) => theme.palette.divider,
          py: 1.5,
        }}
      >
        <Box display='flex'>
          <InsertDriveFile sx={{ mr: 2, fontSize: '20px' }} />

          <Typography
            align='left'
            variant='body2'
            sx={{
              wordBreak: 'break-word',
            }}
          >
            {(typeof document === 'string') ? document : document.name}
          </Typography>
        </Box>
        <IconButton
          id={`button-delete-${document.name}-${index}`}
          color='error'
          size='small'
          sx={{ width: '30px', height: '30px' }}
          onClick={onDeleteHandler}
        >
          <DeleteIcon
            sx={{
              color: 'error.light',
              fontSize: '18px',
            }}
          />
        </IconButton>
      </Box>
    ),
    []
  );

  return (
    <>
      <Box
        display='flex'
        flexDirection='column'
        width='100%'
        gap={2}
      >
        <Typography variant='h6' mb={-1.5}>
          {(showSteps ? '3. ': '') + 'Upload Voice Sample'}
        </Typography>
        <Typography variant="body2"
          sx={{
            color: (theme) => theme.palette.text.secondary,
          }}
        >
          Upload an audio sample of 1-2 minutes (50 mb limit) in mp3, wav or standard audio format to train your AI persona's voice feature.
        </Typography>
      </Box>

      <Button
        id='button-upload-voice-sample'
        disabled={formSubmitting}
        endIcon={<Upload />}
        variant="contained"
        size='large'
        component='label'
        fullWidth={false}
        sx={{
          width: 'fit-content',
          boxShadow: (theme) => `${theme.shadows[2]} !important`,
        }}
        onClick={(event) => {
          if (!checkPermission("update:voice")) {
            event.preventDefault()
            event.stopPropagation()
          }
        }}
      >
        Upload Voice Sample
        <input
          type='file'
          accept='audio/*'
          id='file-input-voiceSample'
          name='voiceSample'
          hidden
          onChange={(event) => {
            if (checkPermission("update:voice")) {
              // max voice sample file size is 50MB
              const fileSize = Math.round(
                event?.currentTarget?.files?.[0].size / 1024
              );
              if (!verifyFileSize(fileSize, 50000)) {
                return;
              }

              setFieldValue('voiceSample', event?.currentTarget?.files?.[0]);
            }
          }}
        />
      </Button>


      {
        !!values?.voiceSample &&
        <Box
          display='flex'
          flexDirection='column'
        >
          <Typography variant='h7' mb={1.5}>
            My Voice Sample
          </Typography>
          <Divider />
          {
            renderVoiceListItem(values.voiceSample, 1, () => {
              setFieldValue('voiceSample', null);
            })
          }
        </Box>
      }
    </>
  )
}

export default VoiceUploadForm;
