


import { SVGProps, memo } from 'react';

const Fear = (props: SVGProps<SVGSVGElement>) => (

<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="20" cy="20" r="20" fill="url(#paint0_linear_3373_11147)"/>
<path d="M17.5 16C17.5 17.273 17.0474 18.6673 16.2442 19.735C15.4451 20.7973 14.3307 21.5 13 21.5C11.6693 21.5 10.5549 20.7973 9.75578 19.735C8.95264 18.6673 8.5 17.273 8.5 16C8.5 14.727 8.95264 13.3327 9.75578 12.265C10.5549 11.2027 11.6693 10.5 13 10.5C14.3307 10.5 15.4451 11.2027 16.2442 12.265C17.0474 13.3327 17.5 14.727 17.5 16Z" fill="white" stroke="#424242"/>
<path d="M31.5 16C31.5 17.273 31.0474 18.6673 30.2442 19.735C29.4451 20.7973 28.3307 21.5 27 21.5C25.6693 21.5 24.5549 20.7973 23.7558 19.735C22.9526 18.6673 22.5 17.273 22.5 16C22.5 14.727 22.9526 13.3327 23.7558 12.265C24.5549 11.2027 25.6693 10.5 27 10.5C28.3307 10.5 29.4451 11.2027 30.2442 12.265C31.0474 13.3327 31.5 14.727 31.5 16Z" fill="white" stroke="#424242"/>
<rect x="15" y="22" width="10" height="11" rx="4" fill="#424242"/>
<path d="M4.5 27.5C8.5 24 10.5 24 11.5 25C12.5 26 13 28.5 9.5 33.5" stroke="#424242" stroke-linecap="round"/>
<path d="M35.7366 27.5C31.7366 24 29.7366 24 28.7366 25C27.7366 26 27.2366 28.5 30.7366 33.5" stroke="#424242" stroke-linecap="round"/>
<defs>
<linearGradient id="paint0_linear_3373_11147" x1="20" y1="0" x2="20" y2="40" gradientUnits="userSpaceOnUse">
<stop stop-color="#45BEF2"/>
<stop offset="0.45" stop-color="#F7F37D"/>
</linearGradient>
</defs>
</svg>

);

export default memo(Fear);
