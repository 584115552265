import { SVGProps, memo } from 'react';

const Negative = (props: SVGProps<SVGSVGElement>) => (

<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="20" cy="20" r="20" fill="#FF6161"/>
<circle cx="13" cy="16" r="3" fill="#424242"/>
<circle cx="27" cy="16" r="3" fill="#424242"/>
<path d="M30 31C30 26.0294 25.7467 22 20.5 22C15.2533 22 11 26.0294 11 31" stroke="#424242" stroke-width="2" stroke-linecap="round"/>
</svg>

);

export default memo(Negative);