

import { SVGProps, memo } from 'react';

const Sad = (props: SVGProps<SVGSVGElement>) => (

<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="20" cy="20" r="20" fill="#91BFFF"/>
<circle cx="13" cy="16" r="3" fill="#424242"/>
<path d="M11 24C11 25.6569 9.65685 27 8 27C6.34315 27 5 25.6569 5 24C5 22.3431 8 18 8 18C8 18 11 22.3431 11 24Z" fill="#1476FF"/>
<circle cx="27" cy="16" r="3" fill="#424242"/>
<path d="M14 25C14.9878 23.2371 17.3023 22 20 22C22.6977 22 25.0122 23.2371 26 25" stroke="#424242" stroke-width="2" stroke-linecap="round"/>
</svg>

);

export default memo(Sad);