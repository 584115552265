

import { SVGProps, memo } from 'react';

const Surprise = (props: SVGProps<SVGSVGElement>) => (

<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="20" cy="20" r="20" fill="#F7F37D"/>
<path d="M14 24C14 26.3012 12.0501 29 9 29C5.94991 29 4 26.3012 4 24C4 21.6988 5.94991 19 9 19C12.0501 19 14 21.6988 14 24Z" fill="#FFAAAA"/>
<path d="M36 24C36 26.3012 34.0501 29 31 29C27.9499 29 26 26.3012 26 24C26 21.6988 27.9499 19 31 19C34.0501 19 36 21.6988 36 24Z" fill="#FFAAAA"/>
<path d="M17.5 18C17.5 20.0744 15.727 22.5 13 22.5C10.273 22.5 8.5 20.0744 8.5 18C8.5 15.9256 10.273 13.5 13 13.5C15.727 13.5 17.5 15.9256 17.5 18Z" fill="white" stroke="#424242"/>
<path d="M13.5 18C13.5 18.0972 13.4548 18.2299 13.3576 18.3375C13.2671 18.4378 13.1471 18.5 13 18.5C12.8529 18.5 12.7329 18.4378 12.6424 18.3375C12.5452 18.2299 12.5 18.0972 12.5 18C12.5 17.9028 12.5452 17.7701 12.6424 17.6625C12.7329 17.5622 12.8529 17.5 13 17.5C13.1471 17.5 13.2671 17.5622 13.3576 17.6625C13.4548 17.7701 13.5 17.9028 13.5 18Z" fill="white" stroke="#424242"/>
<path d="M31.5 18C31.5 20.0744 29.727 22.5 27 22.5C24.273 22.5 22.5 20.0744 22.5 18C22.5 15.9256 24.273 13.5 27 13.5C29.727 13.5 31.5 15.9256 31.5 18Z" fill="white" stroke="#424242"/>
<path d="M24.5 28C24.5 30.0744 22.727 32.5 20 32.5C17.273 32.5 15.5 30.0744 15.5 28C15.5 25.9256 17.273 23.5 20 23.5C22.727 23.5 24.5 25.9256 24.5 28Z" fill="#424242" stroke="#424242"/>
<path d="M27.5 18C27.5 18.0972 27.4548 18.2299 27.3576 18.3375C27.2671 18.4378 27.1471 18.5 27 18.5C26.8529 18.5 26.7329 18.4378 26.6424 18.3375C26.5452 18.2299 26.5 18.0972 26.5 18C26.5 17.9028 26.5452 17.7701 26.6424 17.6625C26.7329 17.5622 26.8529 17.5 27 17.5C27.1471 17.5 27.2671 17.5622 27.3576 17.6625C27.4548 17.7701 27.5 17.9028 27.5 18Z" fill="white" stroke="#424242"/>
</svg>

);

export default memo(Surprise);