import { Stack, Typography, Button, IconButton, useTheme } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EditIcon from '@mui/icons-material/Edit';

const ViewEntryHeader = ({
  handleBackClick,
  handleEditClick,
}) => {
  const theme = useTheme();

  return (
    <Stack
      direction='row'
      alignItems='center'
      justifyContent='space-between'
      sx={{ width: '100%', height: '56px', px: '20px', position: 'relative' }}
    >
      <IconButton
        onClick={handleBackClick}
        sx={{
          color: 'primary.main',
          backgroundColor: 'secondary.light',
          '&:hover': {
            backgroundColor: 'secondary.main', 
          },
        }}
      >
        <ArrowBackIcon />
      </IconButton>

      <Typography
        variant='h7'
        color='text.primary'
        sx={{
          position: 'absolute',
          left: '50%',
          transform: 'translateX(-50%)',
          whiteSpace: 'nowrap',
        }}
      >
        View Entry
      </Typography>

      <Button
        variant='contained'
        color='secondary'
        size='medium'
        startIcon={<EditIcon />}
        onClick={handleEditClick}
      >
        Edit
      </Button>
    </Stack>
  );
};

export default ViewEntryHeader;
