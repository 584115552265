import { SVGProps, memo } from 'react';

const Anger = (props: SVGProps<SVGSVGElement>) => (

<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="20" cy="20" r="20" fill="#FF6161"/>
<path d="M14 28C15.0701 25.6495 17.5775 24 20.5 24C23.4225 24 25.9299 25.6495 27 28" stroke="#424242" stroke-width="2" stroke-linecap="round"/>
<path d="M9.5 12L17.5 17.5" stroke="#424242" stroke-width="2" stroke-linecap="round"/>
<path d="M30.5 12L22.5 17.5" stroke="#424242" stroke-width="2" stroke-linecap="round"/>
<circle cx="13" cy="18" r="3" fill="#424242"/>
<circle cx="27" cy="18" r="3" fill="#424242"/>
</svg>

);

export default memo(Anger);