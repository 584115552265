import {
  Avatar, Box, Button, ClickAwayListener, Dialog, DialogContent,
  Divider, IconButton, List, ListItemButton, ListItemIcon,
  Popover, useTheme
} from "@mui/material";
import MuiDrawer from '@mui/material/Drawer';
import { styled, Theme, CSSObject } from '@mui/material/styles';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";

import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import LoginIcon from '@mui/icons-material/Login';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import LogoutIcon from '@mui/icons-material/Logout';
import SoopraIcon from '../../assets/images/soopra-icon.png';

import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom";
// import Navbar from "../navbar";
import SoopraLogo from "../../svg/SoopraLogo";
import SoopraSmile from "../../svg/SoopraSmile";
import { useAuth } from "../../hooks/useAuth";
import LeftNavItem from "./leftNavItem";
import ThemeModeToggle from "./themeModeToggle";
import ExploreIcon from "../../svg/ExploreIcon";
import ExpertsIcon from "../../svg/ExpertsIcon";
import CoursesIcon from "../../svg/CoursesIcon";
import ChatsIcon from "../../svg/ChatsIcon";
import SearchIcon from "../../svg/SearchIcon";
import AccountIcon from "../../svg/AccountIcon";
import NotificationsIcon from "../../svg/NotificationsIcon";
import MoreIcon from "../../svg/MoreIcon";
import { getAuth } from 'firebase/auth';
import ProfileNav from "./ProfileNav";
import { useMediaQuery } from '@mui/material';
import { USER_TYPE } from "../../config/const";
import { CreatePersonaButton } from "./createPersonaButton";
import { TierContext } from "../../context/TierContext";
import CloseIcon from '@mui/icons-material/Close';
import TiersCard from "../Purchases/TiersCard";
import { NavContext } from "../../context/NavContext";
import DefaultUserImage from "../../assets/images/default-avatar.png"
import useAnalyticsEventTracker from "../../hooks/useAnalyticsEventTracker";

export const drawerWidth = 240;
export const drawerWidthClosed = 118;

const monetizationEnabled =
  process.env.REACT_APP_ENABLE_MONETIZATION === 'true';

///// transition functions for submenus
export const transitionOpen = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('max-width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
    delay: theme.transitions.duration.leavingScreen,
  }),
});

export const transitionClose = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('max-width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
});
////

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(4, 0, 2),
  height: '100px',
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    [theme.breakpoints.down('md')]: {
      display: 'none'
    },
    variants: [
      {
        props: ({ open }) => open,
        style: {
          ...openedMixin(theme),
          '& .MuiDrawer-paper': openedMixin(theme),
        },
      },
      {
        props: ({ open }) => !open,
        style: {
          ...closedMixin(theme),
          '& .MuiDrawer-paper': closedMixin(theme),
        },
      },
    ],
  }),
);

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
    delay: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: drawerWidthClosed,
  },
});

const LeftNav: React.FC = () => {
  const [isOpen, setIsOpen] = useState<boolean>(window.innerWidth >= 1200);
  const [moreMenuVisible, setMoreMenuVisible] = useState<boolean>(false);
  const [showTiersCard, setShowTiersCard] = useState<boolean>(false);
  const isMobile = useRef<boolean>(false);
  const location = useLocation();
  const navigate = useNavigate();
  const { mountedComponent, setMountedComponent,
    showProfileNav, setShowProfileNav, setHandleContactModal,
  } = useContext(NavContext)
  const {
    authUser,
    authLoading,
    setLoginModalVisible }
    = useAuth();
  const auth = getAuth();
  const theme = useTheme();
  const { upgradeFeature, setUpgradeFeature } = useContext(TierContext);
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('md'));
  const anchorEl = useRef(null);
  const setAnchorEl = useCallback((val: any) => {
    anchorEl.current = val;
  }, []) //eslint-disable-line react-hooks/exhaustive-deps

  const eventTracker = useAnalyticsEventTracker();
  const [searchParams, _] = useSearchParams();

  let checkMobile = useCallback(() => {
    if (window.innerWidth <= 900) {
      isMobile.current = true;
      setIsOpen(false);
    } else {
      isMobile.current = false;
      if (
        authUser &&
        location.pathname.includes(authUser?.userName) &&
        !location.pathname.endsWith('/chat')
      ) {
        setIsOpen((currentState) => {
          if (currentState) return false;
          return currentState;
        })
      } else {
        if (!mountedComponent) {
          setIsOpen(window.innerWidth >= 1200);
        } else {
          setIsOpen(false)
        }
      }
    }
  }, [mountedComponent, authUser, location])

  useEffect(() => {
    checkMobile();
    window.removeEventListener('resize', checkMobile, false);
    window.addEventListener('resize', checkMobile, false);

    return () => {
      window.removeEventListener('resize', checkMobile, false);
    }
  }, [authUser, location]) //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // force unverified users to see email verification
    // force users with no username to finish create account step
    if (authUser?.documentId) {
      if (auth.currentUser.emailVerified === false) {
        setLoginModalVisible({
          isOpen: true,
          formType: 'signup',
          currentStep: 3,
        })
      } else if (!authUser?.userName) { // logged in but no userName set up
        // show create profile step
        setLoginModalVisible({
          isOpen: true,
          formType: 'setupaccount',
          currentStep: 1,
        })
      }
    }
  }, [authUser]) //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    let shouldShowProfileNav = location.pathname.includes(`/${authUser?.userName}`);
    shouldShowProfileNav = shouldShowProfileNav && !location.pathname.endsWith('/chat')
    setShowProfileNav(shouldShowProfileNav && !isMobileScreen);

    if (
      authUser &&
      location.pathname.includes(authUser.userName) &&
      !location.pathname.endsWith('/chat')
    ) {
      setIsOpen((currentState) => {
        if (currentState) return false;
        return currentState;
      });
    } else {
      if (authUser) {
        setNavOpen(true);
      }
    }
  }, [location.pathname, authUser, isMobileScreen]); //eslint-disable-line react-hooks/exhaustive-deps
  // Include `isMobileScreen` as a dependency

  useEffect(() => {
    if (upgradeFeature && upgradeFeature !== 'dashboard') {
      setShowTiersCard(true);
    }
  }, [upgradeFeature])

  useEffect(() => {
    if (!showTiersCard) {
      if (upgradeFeature) setUpgradeFeature(null);
    }
  }, [showTiersCard]) //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!mountedComponent) {
      setNavOpen(true)
    }
  }, [mountedComponent])

  useEffect(() => {
    if (searchParams.get("createAccount")) {
      setLoginModalVisible({
        isOpen: true,
        formType: 'signup',
        currentStep: 1,
      })
    }

    if (searchParams.get("refSource")) {
      eventTracker('referral-source', {
        'source': searchParams.get("refSource")
      })
    }
  }, [searchParams])

  const setNavOpen = useCallback((state: boolean) => {
    if (
      authUser &&
      location.pathname.includes(authUser?.userName) &&
      !location.pathname.endsWith('/chat')
    ) {
      setIsOpen(false);
    } else if (!isMobile.current) {
      setIsOpen(state && window.innerWidth >= 1200);
    }
  }, [authUser, location]);

  const NavItems = useMemo(() => [
    {
      'name': 'Discover',
      'icon': <ExploreIcon color='inherit' fill='inherit' />,
      'link': '/',
    },
    {
      'name': 'Experts',
      'icon': <ExpertsIcon width={24} height={22} color='inherit' fill='inherit' />,
      'link': '/creators'
    },
    {
      'name': 'Courses',
      'icon': <CoursesIcon width={24} height={22} color='inherit' fill='inherit' />,
      'link': '/courses'
    },
    {
      'name': 'Chats',
      'icon': <ChatsIcon className='chats' stroke='inherit' />,
      'link': null,
      'isHidden': true,
    },
    {
      'name': 'Search',
      'icon': <SearchIcon color='inherit' fill='inherit' />,
      'link': null,
      'onDismount': () => {
        setMountedComponent(null)
        setNavOpen(true);
      },
      'onMount': () => {
        setMountedComponent({ name: 'Search' })
        setNavOpen(false);
      },
    },
    {
      'name': 'Account',
      'icon': authUser?.profilePicUrl ?
        <Avatar
          src={authUser.profilePicUrl || DefaultUserImage || SoopraIcon}
          alt={authUser.fullName}
          sx={[{
            width: { xs: '26px', md: '40px' },
            height: { xs: '26px', md: '40px' },
            objectFit: 'cover',
            aspectRatio: '1.78',
            borderRadius: 0,
          },
          ]}
        /> : <AccountIcon color='inherit' fill='inherit' />,
      'link': authUser ? `/${authUser?.userName}` : null,
      'onMount': () => {
        if (!authUser) {
          setLoginModalVisible({
            isOpen: true,
            formType: 'signin',
            currentStep: 1,
          })
        }
      },
      'onDismount': () => {
      },
    },
    {
      'name': 'Notifications',
      'icon': <NotificationsIcon color='inherit' fill='inherit' />,
      'link': null,
      'isHidden': (process.env.REACT_APP_ENABLE_NOTIFICATIONS !== 'true' || !authUser),
      'onDismount': () => {
        setMountedComponent(null)
        setNavOpen(true);
      },
      'onMount': () => {
        setMountedComponent({ name: 'Notifications' })
        setNavOpen(false);
      },
    },
    {
      'name': 'More',
      'icon': <MoreIcon color='inherit' fill='inherit' />,
      'link': null,
      'onMount': () => {
        setMountedComponent({ component: <></>, name: 'more-menu' })
        setMoreMenuVisible(true)
      },
      'onDismount': () => {
      },
    }], [authUser]); //eslint-disable-line react-hooks/exhaustive-deps

  const moreMenuItems = useMemo(() => [
    {
      'name': 'FAQ',
      'icon': <HelpOutlineOutlinedIcon />,
      'link': '/faq'
    },
    {
      'name': 'Terms & Conditions',
      'icon': <ArticleOutlinedIcon />,
      'link': '/terms'
    },
    {
      'name': 'Contact Us',
      'icon': <EmailOutlinedIcon />,
      'link': null,
      'onMount': () => {
        setHandleContactModal(true);
      },
    },
    {
      'name': 'Sign Out',
      'icon': <LogoutIcon />,
      'link': '/logout-user',
      'isHidden': !authUser
    },
  ], [authUser]);

  const isSelected = (navItem) => {
    if (!mountedComponent) {
      if (navItem?.name === 'Account') {
        return location.pathname.includes(`/${authUser?.userName}`) &&
          !location.pathname.includes('/chat')
      }
      return location.pathname === navItem.link
    } else {
      if (mountedComponent.name === 'more-menu') {
        return location.pathname === navItem.link;
      }
      return mountedComponent.name === navItem.name
    }
  };

  const handleNavClick = (navItem) => {
    if (navItem.link) {
      navigate(navItem.link)
    } else if (navItem.onMount && navItem.onDismount) {
      if (!mountedComponent) {
        navItem.onMount();
      } else {
        navItem.onDismount();
      }
    }
  }

  return (
    <>
      <Box
        sx={{
          width: { xs: 0, md: (isOpen ? drawerWidth : drawerWidthClosed) },
          flexShrink: 0,
        }}
      >
        <Drawer
          variant="permanent"
          open={isOpen}
          sx={{
            '& .MuiDrawer-paper': {
              width: isOpen ? drawerWidth : drawerWidthClosed,
              boxSizing: 'border-box',
            },
          }}
        >
          <DrawerHeader
            sx={{
              minHeight: '100px !important',
            }}
          >
            <Link to='/'>
              {
                isOpen ?
                  <SoopraLogo color={theme.palette.primary.main} /> :
                  <SoopraSmile color={theme.palette.primary.main} />
              }
            </Link>
          </DrawerHeader>
          <List
            sx={{
              width: '100%'
            }}
            disablePadding
          >
            {
              NavItems.map((val) => {
                return (
                  <Box
                    key={val.name}
                  >
                    {
                      (val.name === 'More') &&
                      <Popover
                        open={moreMenuVisible}
                        anchorEl={anchorEl.current}
                        slotProps={{
                          paper: {
                            sx: {
                              background: (theme) => theme.palette.background.default,
                              borderRadius: (theme) => theme.shape.borderRadius,
                            }
                          }
                        }}
                        anchorOrigin={isOpen ? {
                          vertical: 'top',
                          horizontal: 'center',
                        } : {
                          vertical: 'bottom',
                          horizontal: 'right',
                        }}
                        transformOrigin={isOpen ? {
                          vertical: 'bottom',
                          horizontal: 'center',
                        } : {
                          vertical: 'bottom',
                          horizontal: 'left',
                        }}
                      >
                        <Box
                          display='flex'
                          justifyContent='center'
                          alignItems='center'
                          sx={{
                            borderRadius: (theme) => theme.shape.borderRadius,
                            border: (theme) => `0.74px solid ${theme.palette.divider}`,
                          }}
                        >
                          <ClickAwayListener
                            mouseEvent="onMouseDown"
                            touchEvent="onTouchStart"
                            onClickAway={() => {
                              if (moreMenuVisible) {
                                setMountedComponent(null);
                                setMoreMenuVisible(false);
                              }
                            }}
                          >
                            <List>
                              {
                                moreMenuItems.map((val) => (
                                  <LeftNavItem
                                    key={val.name}
                                    val={val}
                                    selected={isSelected(val)}
                                    onClickFunc={() => {
                                      handleNavClick(val)
                                      setMountedComponent(null);
                                      setMoreMenuVisible(false);
                                      if (val.onMount) val.onMount();
                                    }}
                                    isOpen={isOpen}
                                    isSmallItem={true}
                                  />
                                ))
                              }
                              <Divider variant="middle" />
                              <ThemeModeToggle />
                            </List>
                          </ClickAwayListener>
                        </Box>
                      </Popover>
                    }
                    <LeftNavItem
                      val={val}
                      selected={isSelected(val)}
                      onClickFunc={() => {
                        handleNavClick(val)
                      }}
                      isOpen={isOpen}
                      setAnchorEl={setAnchorEl}
                    />
                  </Box>
                )
              })
            }
          </List>
          {
            (!authUser && !authLoading) &&
            <Box
              flexGrow={1}
              display='flex'
              flexDirection='column'
              justifyContent='flex-end'
              p={2}
            >
              {
                isOpen ?
                  <>
                    <Button color='primary' variant='contained' size='large'
                      sx={{
                        mb: 1,
                        boxShadow: (theme) => `${theme.shadows[2]} !important`,
                      }}
                      onClick={(event) => {
                        event.stopPropagation();
                        event.preventDefault();
                        setLoginModalVisible({
                          isOpen: true,
                          formType: 'signup',
                          currentStep: 1,
                        })
                      }}
                    >
                      Create Account
                    </Button>
                    <Button color='primary' variant='outlined' size='large'
                      onClick={(event) => {
                        event.stopPropagation();
                        event.preventDefault();
                        setLoginModalVisible({
                          isOpen: true,
                          formType: 'signin',
                          currentStep: 1,
                        })
                      }}
                    >
                      Sign In
                    </Button>
                  </> :
                  <>
                    <ListItemButton
                      sx={{
                        maxHeight: 48 + 8,
                        height: 48,
                        px: 2.5,
                        justifyContent: 'center'
                      }}
                      onClick={(event) => {
                        event.stopPropagation();
                        event.preventDefault();
                        setLoginModalVisible({
                          isOpen: true,
                          formType: 'signup',
                          currentStep: 1,
                        })
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          justifyContent: 'center',
                          p: 1,
                          borderRadius: 4,
                          mr: 'auto',
                          backgroundColor: (theme) => theme.palette.primary.main,
                          color: (theme) => theme.palette.primary.contrastText
                        }}
                      >
                        <PersonAddAlt1Icon />
                      </ListItemIcon>
                    </ListItemButton>

                    <ListItemButton
                      sx={{
                        maxHeight: 48 + 8,
                        height: 48,
                        px: 2.5,
                        justifyContent: 'center'
                      }}
                      onClick={(event) => {
                        event.stopPropagation();
                        event.preventDefault();
                        setLoginModalVisible({
                          isOpen: true,
                          formType: 'signin',
                          currentStep: 1,
                        })
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          justifyContent: 'center',
                          p: 1,
                          borderRadius: 4,
                          mr: 'auto',
                          backgroundColor: (theme) => theme.palette.background.default,
                          color: (theme) => theme.palette.primary.main,
                          border: (theme) => `1.8px solid ${theme.palette.divider}`
                        }}
                      >
                        <LoginIcon />
                      </ListItemIcon>
                    </ListItemButton>
                  </>
              }
            </Box>
          }
          {
            (!!authUser && authUser.userType === USER_TYPE.FOLLOWER) &&
            (!location.pathname.includes(authUser.userName)) &&
            (!location.pathname.includes('/creator-onboarding')) &&
            <Box
              flexGrow={1}
              display='flex'
              flexDirection='column'
              alignItems='center'
              justifyContent='flex-end'
              p={2}
            >
              <CreatePersonaButton iconOnly={!isOpen}/>
            </Box>
          }
        </Drawer>
      </Box>

      {
        monetizationEnabled && (location?.pathname !== "/") &&
        !window.location.hostname.includes('stanford.soopra.ai') &&
        authUser &&
        <Dialog
          open={showTiersCard}
          onClose={() => setShowTiersCard(false)}
          fullWidth
          PaperProps={{
            sx: {
              borderRadius: 2,
            },
          }}
          maxWidth={(upgradeFeature && upgradeFeature !== 'justOpen') ? 'dsm' : 'lg'}
        >
          <DialogContent>
            <IconButton
              id='button-close-tiers-card'
              onClick={() => setShowTiersCard(false)}
              color='primary'
              sx={{
                position: 'absolute',
                alignSelf: 'flex-end',
                minWidth: '36px',
                minHeight: '36px',
                height: '36px',
                width: '36px',
                top: '16px',
                right: '16px',
              }}
            >
              <CloseIcon height='30px' width='30px' />
            </IconButton>
            <TiersCard upgradeFeature={upgradeFeature} setUpgradeFeature={setUpgradeFeature} />
          </DialogContent>
        </Dialog>
      }

      {showProfileNav && (
        <Box
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            scrollbarWidth: {
              xs: 'none',
            },
          }}
        >
          <ProfileNav />
        </Box>
      )}
    </>
  )
}

export default LeftNav;
