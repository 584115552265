import React, { createContext, useState, memo, useCallback } from 'react';
import { hasPermission, Permission } from '../config/roles';
import { useAuth } from '../hooks/useAuth';
import { UPGRADE_FEATURE, UPGRADE_TEXT } from '../views/Purchases/priceDescriptions';

interface TierCtxType {
  upgradeFeature: UPGRADE_FEATURE;
  setUpgradeFeature: React.Dispatch<React.SetStateAction<string>>;
  checkPermission: (permission: Permission) => boolean;
}

export const TierContext = createContext<TierCtxType>({
  upgradeFeature: null,
  setUpgradeFeature: null,
  checkPermission: (permission: Permission) => {
    return {} as any;
  },
});

export const TierProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { authUser } = useAuth();
  const [ upgradeFeature, setUpgradeFeature ] = useState<UPGRADE_FEATURE>(null);

  const checkPermission = useCallback((permission: Permission) => {
    let res = hasPermission(authUser, permission);
    if (!res) {
      let feature: UPGRADE_FEATURE;
      let feat = permission.split(':')[1];
      switch (feat) {
        case 'chathistory':
          feature = 'dashboard'
          break;
        case 'course':
        case 'ownCourse':
          feature = 'courses'
          break;
        case 'youtube':
          feature = 'youtube'
          break;
        case 'voice':
          feature = 'voice'
          break;
        case 'twitter':
          feature = 'twitter'
          break;
        case 'emails':
          feature = 'email'
          break;
        default:
          if (Object.keys(UPGRADE_TEXT).includes(feat)){
            feature = feat;
            break;
          } else {
            feature = 'justOpen';
            break;
          }
      }
      setUpgradeFeature(feature)
    }
    return res;
  }, [authUser, setUpgradeFeature]);

  return (
    <TierContext.Provider
      value={{
        upgradeFeature,
        setUpgradeFeature,
        checkPermission,
      }}
    >
      {children}
    </TierContext.Provider>
  );
};

export default memo(TierProvider);
