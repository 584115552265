import Box from '@mui/material/Box';
import { useParams } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
import { USER_TYPE } from '../../config/const';
import CreatorProfile from './creatorProfile';
import FanProfile from './fanProfile';
import LoadingPage from '../LoadingPage';
// import ProfileNav from '.././ProfileNav/Index';

export default function UserProfile() {
  const { authUser, authLoading } = useAuth();
  const { userName: userNameParam } = useParams();
  const userName = userNameParam?.toLowerCase();

  return (
    authLoading ? <LoadingPage /> :
    <Box
      display='flex'
      justifyContent='center'
      width='100%'
      height='100%'
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          height: '100%',
          px: 2.5,
          maxWidth: {xs: '100dvw', md: '1200px'},
        }}
      >
        {(authUser?.userType === USER_TYPE.FOLLOWER &&
        authUser?.normalizedUserName === userName) ? (
          <FanProfile />
        ) : (
          <CreatorProfile userName={userName} />
        )}
      </Box>
    </Box>
  );
}
