import { Stack, Typography, Button, useTheme } from '@mui/material';
import JournalIcon2 from '../../../svg/JournalIcon2';

const EmptyOrDefaultListHeader = ({ onNewEntryClick, isScreenMdOrLarger }) => {
  const theme = useTheme();

  return (
    <Stack
      direction='row'
      alignItems='center'
      justifyContent='space-between'
      sx={{ width: '100%', height: '56px', px: '20px' }}
    >
      <Stack direction='column' sx={{ mr: '12px' }}>
        <Typography variant='h7' color='text.primary'>
          My Journal Entries
        </Typography>
        { isScreenMdOrLarger && ( <Typography variant='body2' color='text.secondary'>
          Train your AI Persona with frequent journal entries.
        </Typography> )}
      </Stack>
      <Button
        variant='contained'
        color='primary'
        size='medium'
        sx={{ 
          whiteSpace: 'nowrap',
          minWidth: 'auto', 
          px: { xs: 1, sm: 2 } 
        }}
        startIcon={
          <JournalIcon2
            width='16px'
            height='16px'
            stroke={theme.palette.primary.contrastText}
          />
        }
        onClick={onNewEntryClick}
      >
        New Entry
      </Button>
    </Stack>
  );
};

export default EmptyOrDefaultListHeader;
