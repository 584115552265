import React, { useEffect, useState } from 'react';
import { Box, Typography, Button, Avatar, Card, CardContent, Grid, CircularProgress, Tabs, Tab } from '@mui/material';
import { Link } from 'react-router-dom';
import { fetchCoursesByCreator, fetchCourseResultsByStudent, getCourseInfo, fetchCourseProgress, updateCurriculum } from '../../utils/api';
import { USER_TYPE } from '../../config/const';
import SoopraIcon from '../../assets/images/soopra-icon.png';
import { useAuth } from '../../hooks/useAuth';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useTheme } from '@mui/material/styles';
import EnrollIcon from "../../svg/EnrollIcon";
import InfoIcon from "../../svg/InfoIcon";
import { deleteCourse } from '../../utils/api';

const CoursesPage = () => {
  const { authUser } = useAuth();
  const [createdCourses, setCreatedCourses] = useState([]);
  const [enrolledCourses, setEnrolledCourses] = useState([]);
  const [loadingCreated, setLoadingCreated] = useState(true);
  const [loadingEnrolled, setLoadingEnrolled] = useState(true);
  const [tabIndex, setTabIndex] = useState(0);
  const [hoveredCourse, setHoveredCourse] = useState<string | null>(null);
  const theme = useTheme();

  useEffect(() => {
    if (authUser?.userType === USER_TYPE.CREATOR) {
      fetchCoursesByCreator(authUser.documentId)
        .then(data => {
          setCreatedCourses(data.map(course => ({
            ...course,
            creator_name: authUser.fullName,
            creatorAvatar: authUser.profilePhotoURL || SoopraIcon,
            isPublished: course.is_published
          })));
          setLoadingCreated(false);
        })
        .catch(() => setLoadingCreated(false));
    }
  }, [authUser]);

  useEffect(() => {
    if (authUser) {
      fetchCourseResultsByStudent(authUser.uid)
        .then(async (results) => {
          const enrolledCoursesWithDetails = await Promise.all(results.map(async (course) => {
            try {
              const courseInfo = await getCourseInfo(course.courseId).then(res => res.json());
              if (!courseInfo || courseInfo.deleted || !courseInfo.name || !courseInfo.creator_name) return null;
              const courseProgress = await fetchCourseProgress(`course_${course.courseId}.${authUser.documentId}`);
              return {
                ...course,
                name: courseInfo.name,
                subject: courseInfo.subject,
                grade: courseInfo.grade,
                creator_name: courseInfo.creator_name,
                creatorAvatar: courseInfo.creator_avatar || SoopraIcon,
                banner_image: courseInfo.banner_image,
                module_count: courseInfo.module_count,
                course_completed: courseProgress.progress.course_completed,
                isPublished: courseInfo.is_published,
                studentCount: courseInfo.student_count,
              };
            } catch {
              return null;
            }
          }));
          setEnrolledCourses(enrolledCoursesWithDetails.filter(course => course !== null));
          setLoadingEnrolled(false);
        })
        .catch(() => setLoadingEnrolled(false));
    }
  }, [authUser]);

  // Function to toggle publish status
  const togglePublishStatus = async (course, currentStatus) => {
    if (!course) return;

    const courseId = course.courseId || course.course_id;
    if (!courseId) return;

    const shouldPublish = !currentStatus;

    try {
      await updateCurriculum(courseId, { course_id: courseId, is_published: shouldPublish }, true);

      setCreatedCourses(prevCourses =>
        prevCourses.map(c =>
          c.courseId === courseId || c.course_id === courseId
            ? { ...c, isPublished: shouldPublish }
            : c
        )
      );

      toast.success(`Course ${shouldPublish ? "published" : "unpublished"} successfully!`);
    } catch {
      toast.error(`Failed to ${shouldPublish ? "" : "un"}publish course`);
    }
  };

  const renderCourseCard = (course) => {
    const courseId = course.courseId || course.course_id;

    const handleDeleteCourse = async (event) => {
      event.stopPropagation();
      if (!authUser || authUser.documentId !== course.creator_id) {
        console.warn("Unauthorized delete attempt");
        return;
      }
      try {
        console.log('Deleting course ', courseId);
        await deleteCourse(courseId);
        setCreatedCourses(prevCourses => prevCourses.filter(c => c.courseId !== courseId));
        toast.success("Course deleted successfully!");
      } catch (error) {
        console.error("Error deleting course:", error);
        toast.error("Failed to delete course.");
      }
    };


    if (!courseId) {
      console.warn("Course ID is missing:", course);
      return null;
    }
  
    return (
      <Grid item xs={12} sm={6} md={3} key={courseId}>
        <Card
          sx={{
            width: 260,
            height: tabIndex === 0 ? 395 : 275,
            gap: '12px',
            borderRadius: '8px',
            borderColor: 'divider',
            border: '1px',
            position: 'relative',
            overflow: 'hidden',
            '&:hover .overlay': {
              opacity: 1,
              zIndex: 1,
            },
          }}
          onMouseEnter={() => tabIndex === 1 && setHoveredCourse(courseId)}
          onMouseLeave={() => tabIndex === 1 && setHoveredCourse(null)}
        >
          <Box
            sx={{
              width: '100%',
              height: 136,
              backgroundImage: `url(${course.banner_image || course.creatorAvatar || SoopraIcon})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              position: 'relative',
              borderRadius: theme.shape.borderRadius,
            }}
          >
            {hoveredCourse === courseId && tabIndex === 1 && (
              <Box
                className="overlay"
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  backdropFilter: 'blur(2px)',
                  borderRadius: theme.shape.borderRadius,
                  opacity: 0,
                  zIndex: -1,
                  transition: 'opacity 0.4s',
                  backgroundColor: theme.palette.action.imagehover,
                  pointerEvents: 'auto',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: 1.5,
                }}
              >
                <Button
                  id={`button-enroll-${courseId}`}
                  variant="contained"
                  color="primary"
                  size="medium"
                  sx={{ zIndex: 2 }}
                  startIcon={<EnrollIcon fill={theme.palette.secondary.main} />} component={Link} to={`/learn/${courseId}`}
                >
                  {course.course_completed ? "Review" : "Take Course"}
                </Button>
                <Button
                  id={`button-course-details-${courseId}`}
                  variant="contained"
                  color="secondary"
                  size="medium"
                  sx={{ zIndex: 2 }}
                  startIcon={<InfoIcon fill={theme.palette.primary.main} />} component={Link} to={`/courses/${courseId}`}
                >
                  View Details
                </Button>
              </Box>
            )}
          </Box>
          <CardContent>
            <Typography>{course.subject}</Typography>
            <Typography variant='h7' color='text.primary' sx={{ display: '-webkit-box', WebkitBoxOrient: 'vertical', WebkitLineClamp: 3, overflow: 'hidden' }}>
              {course.name}
            </Typography>
            <Box display='flex' alignItems='center' mt={1}>
              <Avatar src={course.creatorAvatar || SoopraIcon} sx={{ width: 26, height: 26, borderRadius: 8, mr: 1 }} />
              <Typography variant='body1' fontWeight='bold'>{course.creator_name}</Typography>
            </Box>
            {tabIndex === 0 && (
              <Box mt={2} display='flex' flexDirection='column' gap={1}>
                <Button
                variant={course.isPublished ? 'outlined' : 'contained'}
                color={course.isPublished ? 'error' : 'primary'}
                sx={{
                  width: '100%',
                  backgroundColor: course.isPublished ? 'transparent' : 'black',
                  color: course.isPublished ? 'red' : 'white',
                  borderRadius: 10,
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  togglePublishStatus(course, course.isPublished);
                }}
              >
                {course.isPublished ? 'Unpublish' : 'Publish'}
              </Button>
                <Box display='flex' justifyContent='space-between' gap="12px">
                  {course.isPublished ? (
                    <>
                      <Button variant='outlined' sx={{ width: '104.33px', height: '36px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', minWidth: 'fit-content' }}>
                        Students: {course.studentCount || 0}
                      </Button>
                      <Button component={Link} to={`/courses/${courseId}`} variant='outlined' sx={{ width: '104.33px', height: '36px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', minWidth: 'fit-content' }}>
                        Details
                      </Button>
                    </>
                  ) : (
                    <>
                      <Button component={Link} to={`/learn/${courseId}`} variant='outlined' sx={{ width: '104.33px', height: '36px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', minWidth: 'fit-content' }}>
                        Test
                      </Button>
                      <Button variant='outlined' color='error' onClick={handleDeleteCourse} sx={{ width: '104.33px', height: '36px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', minWidth: 'fit-content' }}>
                        Delete
                      </Button>
                    </>
                  )}
                </Box>
              </Box>
            )}
          </CardContent>
        </Card>
      </Grid>
    );
  };
  

  return (
    <Box display="flex" flexDirection="column" width="100%" py={3} px={2.5}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Tabs
          value={tabIndex}
          onChange={(e, newValue) => setTabIndex(newValue)}
          sx={{
            borderBottom: 1,
            borderColor: 'divider',
            '& .MuiTabs-indicator': {
              height: '4px', 
            },
          }}
        >
          <Tab
            label="My Courses"
            sx={{
              fontWeight: tabIndex === 0 ? 'bold' : 'normal', 
            }}
          />
          <Tab
            label="Purchased"
            sx={{
              fontWeight: tabIndex === 1 ? 'bold' : 'normal',
            }}
          />
        </Tabs>
        
        {tabIndex === 0 && (
          <Button
            component={Link}
            to="/createCourse"
            variant="contained"
            sx={{
              backgroundColor: 'black',
              color: 'white',
              borderRadius: '20px', 
              fontWeight: '500',
              display: { xs: 'none', sm: 'block' },
              padding: '8px 16px',
              height: '36px',
              whiteSpace: 'nowrap',
            }}
          >
            Create New Course
          </Button>
        )}
      </Box>
  
      {tabIndex === 0 && (
        <>
          {loadingCreated ? (
            <CircularProgress />
          ) : createdCourses.length > 0 ? (
            <Grid container spacing={3} mt={2}>
              {createdCourses.map(renderCourseCard)}
            </Grid>
          ) : (
            <Typography>No created courses found.</Typography>
          )}
        </>
      )}
  
      {tabIndex === 1 && (
        <>
          {loadingEnrolled ? (
            <CircularProgress />
          ) : enrolledCourses.length > 0 ? (
            <Grid container spacing={3} mt={2}>
              {enrolledCourses.map(renderCourseCard)}
            </Grid>
          ) : (
            <Typography>No subscribed courses found.</Typography>
          )}
        </>
      )}
    </Box>
  );
  };
  
  export default CoursesPage;
  