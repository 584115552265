
import { SVGProps, memo } from 'react';

const Neutral = (props: SVGProps<SVGSVGElement>) => (

<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="20" cy="20" r="20" fill="#F7F37D"/>
<circle cx="13" cy="16" r="3" fill="#424242"/>
<circle cx="27" cy="16" r="3" fill="#424242"/>
<path d="M29 25H11" stroke="#424242" stroke-width="2" stroke-linecap="round"/>
</svg>


);

export default memo(Neutral);