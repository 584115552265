// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
// import { getAnalytics } from "firebase/analytics";
import { getFirestore } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, } from 'firebase/storage';
import { getAuth, GoogleAuthProvider, FacebookAuthProvider, TwitterAuthProvider } from 'firebase/auth';

// Firebase configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// const analytics = getAnalytics(app);

// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app);
export const firestore = getFirestore(app);
export const firestorage = getStorage(app);
export const googleAuthProvider = new GoogleAuthProvider();
export const twitterAuthProvider = new TwitterAuthProvider();
const facebookAuthProvider = new FacebookAuthProvider();
facebookAuthProvider.addScope('public_profile,email');
export { facebookAuthProvider };
export default app;


// This function is used to upload large files to a specific folder in firebase storage
// returns documentsPath array with path, name, uploadedAt for each uploaded file
export const uploadDocuments = async (userId: string, documents: any, folderName?: string) => {
  const documentsPath: Record<any, any>[] = [];
  //check documents array has data
  if (documents && documents?.length >= 1) {
    // const timeStamp = Timestamp.fromDate(new Date());
    let documentPath = `files/${userId}`;
    if (folderName) { documentPath += `/${folderName}`; }
    // get uploaded documents upload status from firebase
    const documentsUploadStatus = await Promise.all<boolean>(
      documents.map(async (documentItem: any) => {
        const documentName = `${new Date().getTime()}_${documentItem?.name}`;
        let fullDocumentPath = documentPath + `/${documentName}`;
        const documentStorageRef = ref(firestorage, fullDocumentPath);
        try {
          await uploadBytes(documentStorageRef, documentItem);
          documentsPath.push({
            name: documentName,
            path: fullDocumentPath
          });
          return true;
        } catch (err) {
          console.error(err);
          return false;
        }
      })
    );
    const rejectedIndex = documentsUploadStatus.findIndex(
      (item: boolean) => !item
    );
    if (rejectedIndex !== -1) {
      return false;
    }
  }
  return documentsPath;
};
