import { SVGProps, memo } from 'react';

const Positive = (props: SVGProps<SVGSVGElement>) => (

<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="20" cy="20" r="20" fill="#65DD32"/>
<circle cx="13" cy="16" r="3" fill="#424242"/>
<circle cx="27" cy="16" r="3" fill="#424242"/>
<path d="M29.4456 23.9403C28.9529 28.171 25.1554 31.5 20.5 31.5C15.8446 31.5 12.0471 28.171 11.5544 23.9403C11.5296 23.7277 11.7062 23.5 12 23.5H29C29.2938 23.5 29.4704 23.7277 29.4456 23.9403Z" fill="#424242" stroke="#424242"/>
</svg>

);

export default memo(Positive);