import {
  Box,
  Typography,
  useTheme,
  Stack,
  Button,
  TextField,
  CircularProgress,
  Grid,
} from '@mui/material';
import SpeechRecognition, {
  useSpeechRecognition,
} from 'react-speech-recognition';
import {
  useContext,
  useState,
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { handleUpdateJournalChat } from '../../utils/api';
import { toast } from 'react-toastify';
import Positive from '../../svg/Positive';
import Negative from '../../svg/Negative';
import Neutral from '../../svg/Neutral';
import Happy from '../../svg/Happy';
import Sad from '../../svg/Sad';
import Anger from '../../svg/Anger';
import Fear from '../../svg/Fear';
import Surprise from '../../svg/Surprise';
import Disgust from '../../svg/Disgust';
import Stop from '../../svg/Stop';
import MicIcon from '@mui/icons-material/Mic';
import StopIcon from '@mui/icons-material/Stop';
import DeleteIcon from '../../svg/DeleteIcon';
// import DeleteIcon from '@mui/icons-material/Delete';

interface JournalEntryItemProps {
  entry: {
    docId?: string;
    title?: string;
    message?: string;
    character_count?: number;
    emotion?: string[];
    noted_at?: string;
    response?: string;
    room_id?: string;
    sentiment?: string;
    summary?: string;
    tags?: string[];
    updatedAt?: string;
    user_id?: string;
    word_count?: number;
  };
  screenMode: string;
  handleEditClick: () => void;
  authUserId: string;
  isSaveEnabled: boolean;
  setIsSaveEnabled: (enabled: boolean) => void;
  isSaving: boolean;
  setIsSaving: (saving: boolean) => void;
  isScreenMdOrLarger: boolean;
  isDeleting: boolean;
  handleDeleteClick: () => void;
}

export interface JournalEntryItemRef {
  saveDetails: () => Promise<void>;
}

const ScreenModes = {
  EMPTY_LIST: 'emptyList',
  DEFAULT_LIST: 'defaultList',
  VIEW_ENTRY: 'viewEntry',
  EDIT_ENTRY: 'editEntry',
};

const JournalDetail = forwardRef<JournalEntryItemRef, JournalEntryItemProps>(
  (props, ref) => {
    const {
      entry,
      screenMode,
      handleEditClick,
      authUserId,
      isSaveEnabled,
      setIsSaveEnabled,
      isSaving,
      setIsSaving,
      isScreenMdOrLarger,
      isDeleting,
      handleDeleteClick,
    } = props;
    const [entryTitle, setEntryTitle] = useState(entry.title || 'Title');
    const [entryMessage, setEntryMessage] = useState(entry.message);
    const [isListening, setIsListening] = useState(false);
    const [textFieldHasFocus, setTextFieldHasFocus] = useState(false);
    const textFieldRef = useRef(null);
    const navigate = useNavigate();
    const theme = useTheme();

    const saveDetails = async () => {
      // e.preventDefault();
      setIsSaving(true);
      try {
        await postUpdatedJournalEntry(entryTitle, entryMessage);
      } catch (error) {
        console.log(error);
      }
    };

    useEffect(() => {
      setEntryTitle(entry.title || 'Title');
      setEntryMessage(entry.message || '');
    }, [entry]);

    // const handleSaveClick = async (e) => {
    //   e.preventDefault();
    //   setSavingEntry(true);
    //   try {
    //     await postUpdatedJournalEntry(entryTitle, entryMessage);
    //   } catch (error) {
    //     console.log(error);
    //   }
    // };

    const {
      transcript,
      resetTranscript,
      browserSupportsSpeechRecognition,
      listening,
    } = useSpeechRecognition();

    // useEffect(() => {
    //   if (transcript && isListening) {
    //     setEntryMessage((prevMessage) => {
    //       // Get cursor position
    //       const cursorPosition =
    //         textFieldRef.current?.selectionStart || prevMessage.length;

    //       // Insert transcript at cursor position
    //       const newMessage =
    //         prevMessage.substring(0, cursorPosition) +
    //         transcript +
    //         prevMessage.substring(cursorPosition);

    //       return newMessage;
    //     });

    //     // Reset transcript to prevent duplicate text
    //     resetTranscript();
    //   }
    // }, [transcript, isListening]);

    // const toggleListening = () => {
    //   if (listening) {
    //     SpeechRecognition.stopListening();
    //     setIsListening(false);
    //   } else {
    //     resetTranscript();
    //     SpeechRecognition.startListening({ continuous: true });
    //     setIsListening(true);
    //   }
    // };

    const postUpdatedJournalEntry = async (entryTitle, entryMessage) => {
      try {
        let resp = await handleUpdateJournalChat(
          authUserId,
          entryTitle,
          entryMessage,
          entry.docId
        );
        if (resp) {
          toast.success('Updated Journal entry successfully');
          setIsSaving(false);
          navigate(0);
        } else {
          setIsSaving(true);
          toast.error('Unable to update journal entry');
        }
      } catch (err) {
        setIsSaving(false);
        console.log(err);
      }
    };

    useImperativeHandle(ref, () => ({
      saveDetails,
    }));

    const handleEntryTitle = (e) => {
      const newTitle = e.target.value;
      setEntryTitle(newTitle);
      // Check against original entry using new value from event
      const titleChanged = newTitle !== entry.title;
      const messageChanged = entryMessage !== entry.message;
      setIsSaveEnabled(titleChanged || messageChanged);
    };

    const handleEntryMessage = (e) => {
      const newMessage = e.target.value;
      setEntryMessage(newMessage);
      // Check against original entry using new value from event
      const titleChanged = entryTitle !== entry.title;
      const messageChanged = newMessage !== entry.message;
      setIsSaveEnabled(titleChanged || messageChanged);
    };

    if (!entry) {
      return (
        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
          height='100%'
        >
          <Typography variant='body1' color='text.secondary'>
            Select a journal entry to view details
          </Typography>
        </Box>
      );
    }

    const emotionsList = [
      { name: 'happy', Icon: Happy },
      { name: 'sad', Icon: Sad },
      { name: 'surprise', Icon: Surprise },
      { name: 'anger', Icon: Anger },
      { name: 'fear', Icon: Fear },
      { name: 'disgust', Icon: Disgust },
    ];

    return (
      <Grid
        item
        xs={12}
        md={9}
        sx={{
          height: '100%',
          // display: { xs: 'none', md: 'block' },
          paddingLeft: { xs: '0px', md: '8px' },
        }}
      >
        <Stack
          direction='column'
          sx={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Stack
            sx={{
              backgroundColor: theme.palette.background['paper-elevation-2'],
              border: `1px solid ${theme.palette.divider}`,
              borderRadius: '12px',
              padding: '24px',
              flex: 1,
              overflowY: 'auto',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {screenMode === ScreenModes.VIEW_ENTRY ||
            screenMode === ScreenModes.DEFAULT_LIST ? (
              <Typography
                variant='h7'
                color='text.primary'
                sx={{
                  maxLines: 1,
                  textOverflow: 'ellipsis',
                  fontSize: '1rem',
                  fontStyle: 'normal',
                  fontWeight: 600,
                  lineHeight: '120%',
                }}
              >
                {entry.title || 'Untitled Entry'}
              </Typography>
            ) : (
              <Box
                sx={{
                  ...(screenMode === ScreenModes.EDIT_ENTRY && {
                    backgroundColor: theme.palette.primary._states.selected,
                    border: `1px solid ${theme.palette.divider}`,
                    paddingX: '4px',
                    paddingY: '2px',
                  }),
                }}
              >
                <TextField
                  value={entryTitle}
                  onChange={handleEntryTitle}
                  sx={{
                    width: '100%',
                    '& .MuiInputBase-input': {
                      fontSize: '1rem',
                      fontStyle: 'normal',
                      fontWeight: 600,
                      lineHeight: '120%',
                      padding: '0px',
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                      border: 'none',
                    },
                  }}
                />
              </Box>
            )}

            <Typography
              variant='caption'
              color='text.secondary'
              sx={{
                maxLines: 1,
                // overflow: 'hidden',
                textOverflow: 'ellipsis',
                pb: '24px',
              }}
            >
              {new Date(entry.noted_at)
                .toLocaleString('en-US', {
                  month: 'short',
                  day: 'numeric',
                  year: 'numeric',
                  hour: 'numeric',
                  minute: '2-digit',
                  hour12: true,
                })
                .replace(',', ' @')}
            </Typography>

            <Typography variant='h8' color='text.secondary'>
              Summary
            </Typography>

            <Typography
              variant='body1'
              color='text.primary'
              sx={{ pb: '24px' }}
            >
              {entry.summary || 'No summary available'}
            </Typography>

            {entry.emotion?.length > 0 && (
              <Stack
                direction='column'
                spacing={0.5}
                sx={{
                  marginBottom: '24px',
                }}
              >
                <Typography variant='h8' color='text.secondary'>
                  Topics
                </Typography>

                <Stack
                  direction='row'
                  spacing={1}
                  sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: 0.25,
                  }}
                >
                  {entry.tags
                    .filter((tag, index, self) => self.indexOf(tag) === index)
                    .map((tag, index) => (
                      <Typography
                        key={index}
                        variant='body1'
                        color='text.primary'
                        sx={{
                          border: 1,
                          borderColor: 'divider',
                          borderRadius: 2,
                          py: 0.5,
                          px: 1,
                          margin: 0.5,
                        }}
                      >
                        {tag}
                      </Typography>
                    ))}
                </Stack>
              </Stack>
            )}

            {entry.sentiment && entry.sentiment !== '' && (
              <Stack
                direction={{ xs: 'column', sm: 'column', md: 'row' }}
                spacing={3}
                alignItems='left'
                marginBottom={3}
              >
                {entry.sentiment && (
                  <Stack direction='column' spacing={1} alignItems='left'>
                    <Typography variant='h8' color='text.secondary'>
                      Sentiment
                    </Typography>
                    {entry.sentiment === 'positive' && (
                      <Positive width='40px' height='40px' />
                    )}
                    {entry.sentiment === 'neutral' && (
                      <Neutral width='40px' height='40px' />
                    )}
                    {entry.sentiment === 'negative' && (
                      <Negative width='40px' height='40px' />
                    )}
                  </Stack>
                )}

                {entry.emotion?.length > 0 && (
                  <Stack direction='column' spacing={1} alignItems='left'>
                    <Typography variant='h8' color='text.secondary'>
                      Emotions
                    </Typography>
                    <Stack direction='row' spacing={1}>
                      {emotionsList
                        .filter((emotion) =>
                          entry.emotion.includes(emotion.name)
                        )
                        .map((emotion) => {
                          const Icon = emotion.Icon;
                          return (
                            <Icon
                              key={emotion.name}
                              width='40px'
                              height='40px'
                              stroke={theme.palette.text.secondary}
                            />
                          );
                        })}
                    </Stack>
                  </Stack>
                )}
              </Stack>
            )}

            <Typography variant='h8' color='text.secondary'>
              Message
            </Typography>

            {screenMode === ScreenModes.VIEW_ENTRY ||
            screenMode === ScreenModes.DEFAULT_LIST ? (
              <Typography
                variant='body1'
                color='text.primary'
                sx={{ whiteSpace: 'pre-line', pb: '24px' }}
              >
                {entry.message || 'No content available'}
              </Typography>
            ) : (
              <Box
                sx={{
                  ...(screenMode === ScreenModes.EDIT_ENTRY && {
                    backgroundColor: theme.palette.primary._states.selected,
                    border: `1px solid ${theme.palette.divider}`,
                    paddingX: '4px',
                    paddingY: '2px',
                    marginTop: '4px',
                  }),
                }}
              >
                <TextField
                  multiline
                  minRows={8}
                  value={entryMessage}
                  onChange={handleEntryMessage}
                  onFocus={() => setTextFieldHasFocus(true)}
                  onBlur={() => setTextFieldHasFocus(false)}
                  inputRef={textFieldRef}
                  sx={{
                    width: '100%',
                    '& .MuiInputBase-root': {
                      padding: '0px !important',
                    },
                    '& .MuiInputBase-input': {
                      fontSize: '1rem',
                      fontWeight: 400,
                      padding: '0px !important',
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                      border: 'none',
                    },
                  }}
                />
              </Box>
            )}
          </Stack>
          <Stack
            direction='row'
            justifyContent='space-between'
            mt='24px'
            mx='24px'
          >
            <Stack direction='row'>
              {/* <Button
                variant='contained'
                color='secondary'
                size='large'
                onClick={toggleListening}
                startIcon={isListening ? <StopIcon /> : <MicIcon />}
              >
                {isListening ? 'Stop' : 'Voice Input'}
              </Button> */}
              <Button
                variant='text'
                // color={theme.palette.text.secondary}
                size='large'
                startIcon={<DeleteIcon stroke={theme.palette.text.secondary} />}
                onClick={handleDeleteClick}
                style={{ color: theme.palette.text.secondary }} 
              >
                Delete
              </Button>
            </Stack>
            {screenMode === ScreenModes.VIEW_ENTRY ||
            screenMode === ScreenModes.DEFAULT_LIST ? (
              isScreenMdOrLarger ? (
                <Button
                  variant='contained'
                  color='secondary'
                  size='large'
                  onClick={handleEditClick}
                >
                  Edit
                </Button>
              ) : null
            ) : (
              // <Button
              //   variant='contained'
              //   color='secondary'
              //   size='large'
              //   onClick={toggleListening}
              //   startIcon={isListening ? <StopIcon /> : <MicIcon />}
              // >
              //   {isListening ? 'Stop' : 'Record'}
              // </Button>
              <></>
            )}
          </Stack>
        </Stack>
      </Grid>
    );
  }
);

export default JournalDetail;
