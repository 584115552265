import React, { useState, useEffect, useCallback, useMemo } from "react";
import {
  Box,
  Typography,
  List,
  ListItemButton,
  ListItemIcon,
  Switch,
  Drawer,
} from "@mui/material";
import { styled, useTheme } from "@mui/system";
import { useLocation, Link } from "react-router-dom";
import PersonIcon from "../../svg/Profilenavicons/ProfileIcon";
import CreditCardIcon from "../../svg/Profilenavicons/PurchasesIcon";
import AttachMoneyIcon from "../../svg/Profilenavicons/PricecardIcon";
import DashboardIcon from "../../svg/Profilenavicons/DashboardIcon";
import BookIcon from "../../svg/Profilenavicons/MycoursesIcon";
import DescriptionIcon from "../../svg/Profilenavicons/DocumentIcon";
import LanguageIcon from "../../svg/Profilenavicons/WeblinksIcon";
import FavoriteIcon from "../../svg/Profilenavicons/SocialmediaIcon";
import MicIcon from "../../svg/Profilenavicons/VoiceSampleIcon";
import EmojiPeopleIcon from "../../svg/Profilenavicons/IntroductionIcon";
import FaceIcon from "../../svg/Profilenavicons/PersonalityIcon";
import { useAuth } from "../../hooks/useAuth";
import { toast } from "react-toastify";
import { USER_TYPE } from "../../config/const";
import { CreatePersonaButton } from "./createPersonaButton";
import ChatUsage from "../HamburgerMenu/ChatUsage";

const drawerWidth = 237;

const StyledDrawer = styled(Drawer)(({ theme }) => ({
  width: drawerWidth,
  height: '100%',
  flexShrink: 0,
  "& .MuiDrawer-paper": {
    width: drawerWidth,
    height: '100%',
    boxSizing: "border-box",
    borderRight: `1px solid ${theme.palette.divider}`,
    borderLeft: 'none',
    py: 2,
    gap: 2,
    backgroundColor: theme.palette.background.default,
    position: "relative",
  },
}));

export const SectionTitle = styled(Typography)(({ theme }) => ({
  width: "100%",
  lineHeight: "20px",
  textAlign: "left",
  fontSize: "0.75rem",
  color: theme.palette.text.secondary,
}));

export const StyledListItemText = styled(Typography)<{ isselected: string }>(({ theme, isselected }) => ({
  fontSize: "0.875rem",
  fontWeight: (isselected === 'true') ? 700 : 400,
  lineHeight: "20.02px",
  letterSpacing: "0.17px",
  color: (isselected === 'true') ? theme.palette.text.primary : theme.palette.text.secondary,
}));

export const ScrollableBox = styled(Box)(({ theme }) => ({
  overflowY: "auto",
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  marginLeft: -16,
  marginRight: -16,
  gap: 2,
  // "&::-webkit-scrollbar": {
  //   width: "8px",
  // },
  // "&::-webkit-scrollbar-thumb": {
  //   backgroundColor: theme.palette.divider,
  //   borderRadius: "4px",
  // },
}));

const ProfileNav: React.FC = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [isPublished, setIsPublished] = useState(true);
  const location = useLocation();
  const { authUser, updateVerifiedStatus } = useAuth();
  const theme = useTheme();

  const menuSections = useMemo(() => {
      if (authUser?.userType === USER_TYPE.CREATOR) {
        return [
          {
            title: "Account",
            items: [
              { label: "Profile", icon: <PersonIcon color={theme.palette.primary.main}/>, path: `/${authUser?.userName}` },
              { label: "Purchases", icon: <CreditCardIcon color={theme.palette.primary.main}/>, path: `/${authUser?.userName}/purchases` },
              { label: "My Courses", icon: <BookIcon color={theme.palette.primary.main}/>, path: `/${authUser?.userName}/courses`  },
            ],
          },
          {
            title: "AI Persona",
            items: [
              { label: "Price Card", icon: <AttachMoneyIcon color={theme.palette.primary.main}/>, path: `/${authUser?.userName}/revenue`},
              { label: "Dashboard", icon: <DashboardIcon color={theme.palette.primary.main}/>, path: `/${authUser?.userName}/dashboard` },
            ],
          },
          {
            title: "Training Material",
            items: [
              { label: "Documents", icon: <DescriptionIcon color={theme.palette.primary.main}/>, path: `/${authUser?.userName}/update/documents`  },
              { label: "Journal", icon: <BookIcon color={theme.palette.primary.main}/>, path: `/${authUser?.userName}/update/journaling`},
              { label: "Weblinks", icon: <LanguageIcon color={theme.palette.primary.main}/>, path: `/${authUser?.userName}/update/weblinks`},
              { label: "Social Media", icon: <FavoriteIcon color={theme.palette.primary.main}/>, path: `/${authUser?.userName}/update/social`},
              { label: "Voice Sample", icon: <MicIcon color={theme.palette.primary.main}/>, path: `/${authUser?.userName}/update/voice`},
              { label: "Introduction", icon: <EmojiPeopleIcon color={theme.palette.primary.main}/>, path: `/${authUser?.userName}/update/introduction`},
              // { label: "Personality", icon: <FaceIcon color={theme.palette.primary.main}/>, path: `/${authUser?.userName}/update/personality`},
            ],
          },
        ]
      }
      return [
        {
          title: "Account",
          items: [
            { label: "Profile", icon: <PersonIcon color={theme.palette.primary.main}/>, path: `/${authUser?.userName}` },
            { label: "Purchases", icon: <CreditCardIcon color={theme.palette.primary.main}/>, path: `/${authUser?.userName}/purchases` }
          ],
        },
        {
          title: "AI Persona",
          items: [],
        },
      ]
  }, [authUser, theme]);

  const checkMobile = useCallback(() => {
    setIsMobile(window.innerWidth <= 900);
  }, []);

  useEffect(() => {
    window.addEventListener("resize", checkMobile);
    return () => window.removeEventListener("resize", checkMobile);
  }, [checkMobile]);

  useEffect(() => {
    if (authUser?.documentId) {
      // Fetch verified status on mount
      setIsPublished(authUser?.verified ?? false);
    }
  }, [authUser]);

  const handleToggleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const newStatus = event.target.checked;
    setIsPublished(newStatus);

    // Update backend or show a toast message on failure
    try {
      if (authUser?.documentId) {
        await updateVerifiedStatus(authUser.documentId, newStatus);
        toast.success(`Persona ${newStatus ? "published" : "unpublished"} successfully`);
      }
    } catch (error) {
      toast.error("Failed to update persona status. Please try again.");
      setIsPublished(!newStatus); // Revert on failure
    }
  };

  const getSelectedPath = () => {
    const allPaths = menuSections.flatMap((section) =>
      section.items.map((item) => item.path)
    );
    return allPaths.includes(location.pathname) ? location.pathname : `/${authUser?.userName}`;
  };

  const selectedPath = getSelectedPath();

  return (
    <StyledDrawer variant={isMobile ? "temporary" : "permanent"} anchor="right" open>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          p: 2,
          gap: 2,
        }}
      >
        <Box
          display='flex'
          flexDirection='column'
        >
          <Box
            display='flex'
            height='40px'
            alignItems='center'
          >
            <Typography
              variant="subtitle1"
              sx={{
                fontWeight: "bold",
              }}
            >
              @{authUser?.userName}
            </Typography>
          </Box>

          {
            (authUser?.userType === USER_TYPE.CREATOR) &&
            <Box
              display='flex'
              justifyContent='space-between'
              alignItems='center'
              height='40px'
              sx={{
                backgroundColor: (theme) => theme.palette.background["paper-elevation-7"],
                mx: -2,
                px: 2,
              }}
            >
              <Typography variant="body3">
                Publish my AI Persona
              </Typography>
              <Switch id="toggle-publish-persona" checked={isPublished} onChange={handleToggleChange} />
            </Box>
          }
        </Box>

        <ScrollableBox>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              mx: 2,
            }}
          >
            {menuSections.map((section, sectionIndex) => (
              <React.Fragment key={sectionIndex}>
                <SectionTitle>{section.title}</SectionTitle>
                <List>
                  {section.items.map((item, index) => (
                    <ListItemButton
                      key={index}
                      component={Link}
                      to={item.path}
                      selected={selectedPath === item.path}
                      sx={{
                        px: 0,
                        borderRadius: 1,
                        backgroundColor: selectedPath === item.path ? "rgba(0, 0, 0, 0.1)" : "transparent",
                        "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.05)" },
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 32,
                          ml: 0,
                          mr: 2,
                          display: 'flex',
                          justifyContent: 'center'
                        }}
                      >
                        {item.icon}
                      </ListItemIcon>
                      <StyledListItemText isselected={(selectedPath === item.path).toString()}>
                        {item.label}
                      </StyledListItemText>
                    </ListItemButton>
                  ))}
                </List>
              </React.Fragment>
            ))}

            {
              (authUser && authUser.userType === USER_TYPE.FOLLOWER) &&
              <Box
                flexGrow={1}
                display='flex'
                flexDirection='column'
                alignItems='center'
              >
                <CreatePersonaButton/>
              </Box>
            }
          </Box>
        </ScrollableBox>

        <Box
          display='flex'
          flexDirection='column'
          justifyContent='flex-end'
          mx={-2}
          mb={-2}
        >
          <ChatUsage />
        </Box>
      </Box>
    </StyledDrawer>
  );
};

export default ProfileNav;
