import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
// import CircularProgress from '@mui/material/CircularProgress';

import { UserFormType } from '../creatorOnBoarding/signup.types';
import CreatorFanList from './creatorFanList';
import { useQuery } from '@tanstack/react-query';
// import { getCreatorsFanStreaksData } from '../../utils/helper';
import { fetchFanList } from '../../utils/api';

interface UserStreaksDataType {
  profileData: UserFormType;
  mode: 'profile' | 'list';
}

const UserStreaksData: React.FC<UserStreaksDataType> = (props) => {
  const {
    profileData,
    mode,
  } = props;
  const { documentId = '' } = profileData || {};

  const { data: allStreaksData } = useQuery({
    queryKey: ['creators-all-streaks', documentId],
    queryFn: async () => {
      // console.log('fetching fan list...')
      return fetchFanList(documentId, 25);
      // console.log('fetched fan list ->', data)
      // return data;
    },
    staleTime: Infinity,
  });

  return (

        <>
          {mode === 'profile' && allStreaksData?.length >= 1 && (
            <Box
            >
              {/* Title with updated font style */}
              <Typography
                component="p"
                variant="body2"
                color="text.secondary"
                sx={{ userSelect: 'text' }}
              >
                Fans
              </Typography>

              {/* Fan List */}
              <CreatorFanList userStreaksData={allStreaksData} mode={mode} />
            </Box>
          )}
        </>

  );
}

export default UserStreaksData;
