
import {
  MessageInput,
  MessageList,
  MessageSeparator,
} from '@chatscope/chat-ui-kit-react';

import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Grid,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import moment from 'moment';

import MessageItem from './messageItem';
import VoiceChat from './voiceChat';

import VoiceChatToggle from '../../component/voiceChatToggle';
import TimeoutModal from '../../component/timeoutModal';
import GenericModal from '../../component/GenericModal';

import ShareMessagesSection from './shareMessagesSection';
import CollapseHeaderMessage from '../../component/CollapseHeaderMessage/CollapseHeaderMessage';

import SubscribeButton from '../Purchases/subscribeButton';
import AdditionalCreditsButton from '../Purchases/additionalCreditsButton';
import TextChatInput from './textChatInput';
import TypingAnimation from './TypingAnimation';
import CustomTypingIndicator from './CustomTypingIndicator';
import PremiumPurchaseButton from '../Purchases/premiumPurchaseButton';
import useIsDesktop from "./useIsDesktop"
import { useProfileData } from '../../hooks/useProfileData';
import { CSSProperties, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { TierContext } from '../../context/TierContext';
import { CreditsContext } from '../../context/CreditsContext';
import { useAuth } from '../../hooks/useAuth';
import { USER_TYPE } from '../../config/const';
import { checkVoicePermissions } from '../../config/roles';
import UserResponseForm from './userResponseForm';

import {
  fetchMessagesData,
  saveMessagesData,
  updateMessagesData,
  findAndReplaceLinks,
} from '../../utils/helper';
import {
  clearChat,
  editedMessageResponse,
  sendMessageToSmartAssistant,
  getIntroMessage,
} from '../../utils/api';
import {
  MessageDataType,
  SendMessageRequest,
  SendMessageResponse,
} from './messages.types';
import MicGuy from '../../assets/images/micguy.png';
import { CONTACT_US_MAIL } from '../../config/const';
import { Citation } from './messages.types';
import { DocumentData, serverTimestamp, Timestamp } from 'firebase/firestore';
import useAnalyticsEventTracker from '../../hooks/useAnalyticsEventTracker';
import { usePreloadedIndex } from '../../hooks/usePreloadedIndex';
import { isIOS, isAndroid } from 'react-device-detect';
import { useSpeechRecognition } from 'react-speech-recognition';
import { toast } from 'react-toastify';
import { UserInfoHeader } from '.';

const DEFAULT_ANONYMOUS_CHAT_LIMIT = parseInt(process.env.REACT_APP_ANON_CHAT_LIMIT) || 1; //Updating this to 1 as we have a Creator welcome message
const enableVoiceFeature = process.env.REACT_APP_ENABLE_VOICE === 'true';
const enableMonetization = process.env.REACT_APP_ENABLE_MONETIZATION === 'true';
const enableDevTools = process.env.REACT_APP_ENABLE_DEVTOOLS === 'true';
const enableIntroMessages = process.env.REACT_APP_USE_INTRO_MESSAGES === 'true';
const skipTypingAnimation =
  process.env.REACT_APP_SKIP_TYPING_ANIMATION === 'true';
const useCredits = process.env.REACT_APP_ENABLE_CREDITS === 'true';

interface MainChatInterfaceProps {
  hasLoadedCreatorProfile: () => boolean;
  containerRef: any;
  isWidget?: boolean;
  handleSignInModalWidget?: boolean;
  setHandleSignInModalWidget?: React.Dispatch<React.SetStateAction<boolean>>;
  setWidgetVoiceMode?: React.Dispatch<React.SetStateAction<boolean>>;
  shouldStopAudio?: boolean;
  setShouldStopAudio?: React.Dispatch<React.SetStateAction<boolean>>;
  requiredPremiumKey?: string;
  premiumAccess?: boolean;
}

const MainChatInterface: React.FC<MainChatInterfaceProps> = (props) => {
  const {
    hasLoadedCreatorProfile,
    containerRef,
    isWidget = false,
    handleSignInModalWidget,
    setHandleSignInModalWidget,
    setWidgetVoiceMode,
    shouldStopAudio = false,
    setShouldStopAudio,
    requiredPremiumKey = '',
    premiumAccess = false,
  } = props;

  const { userName: userNameParam, topic: topicParam } = useParams();
  const userName = userNameParam?.toLowerCase();
  const topic = topicParam?.toLowerCase();

  const {
    authUser,
    authLoading,
    isAnonymousUser,
    setIsAnonymousUser,
    setAnonymousChat,
    resetAnonymousData,
    setResetAnonymousData,
    setLoginModalVisible,
  } = useAuth();
  const {
    creatorProfileData,
  } = useProfileData();
  const location = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();
  const { browserSupportsSpeechRecognition } = useSpeechRecognition();

  const [showTopMessage, setShowTopMessage] = useState(true);
  const [useVoiceChat, setUseVoiceChat] = useState(false);
  const [preloadedIndex, prefetchPromise] = usePreloadedIndex();
  const [showTimeoutModal, setShowTimeoutModal] = useState(false);
  const [showGenericModal, setShowGenericModal] = useState(false);
  const [genericModalData, setGenericModalData] = useState(null);
  const [errorCode, setErrorCode] = useState<number>();
  const [citations, setCitations] = useState([]);
  const [clearChatLoading, setClearChatLoading] = useState(false);
  const [messages, setMessages] = useState<any>(null);
  const [isTyping, setIsTyping] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);
  const [showActionMenu, setShowActionMenu] = useState<HTMLElement>(null);
  const [formData, setFormData] = useState<Record<any, any>>(null);
  const [handleModal, setHandleModal] = useState<'edit' | 'feedback' | null>(
    null
  );
  const [initialMessage, setInitialMessage] = useState<boolean>(false);
  const [selectedMessages, setSelectedMessages] = useState<MessageDataType[]>(
    []
  );
  const [aiTypingMessage, setAiTypingMessage] = useState(null);
  const [showSelectMessages, setShowSelectMessages] = useState(false);
  const [messageVal, setMessageVal] = useState('');
  const [roomChips, setRoomChips] = useState<Record<string, string[]>>({});
  const [anonymousChatLimit, setAnonymousChatLimit] = useState(
    DEFAULT_ANONYMOUS_CHAT_LIMIT
  );


  const sendDisabled = useRef<boolean>(true);
  const inputRef = useRef(null);
  const startedTimeout = useRef<boolean>(false);
  const scrollRef = useRef(null);

  const { credits, setCredits } = useContext(CreditsContext);
  const { checkPermission } = useContext(TierContext);
  const eventTracker = useAnalyticsEventTracker();
  const isDesktop = !(isIOS || isAndroid)
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const initialPrompt = topic
    ? `Start a conversation about ${topic}`
    : 'Give me a 1 sentence summary about who you are and provide 3 topics that I can ask you about. Do not give me a numbered list';

  // Check if the URL starts with 'sambatv.mimio.ai'
  const isSambaTvUrl =
  window.location.href.startsWith('https://sambatv.mimio.ai') &&
  window.innerWidth >= 968;

  // Check if the URL starts with 'stanford.edu'
  const isStanfordUrl =
  window.location.href.startsWith('https://stanford.soopra.ai') &&
  window.innerWidth >= 968;

  const returnRoomId = () => {
    if (authUser?.documentId) {
      if (
        authUser?.normalizedUserName === userName &&
        authUser?.userType === USER_TYPE.CREATOR
      ) {
        if (topic) return `${authUser?.documentId}.${USER_TYPE.BOT}.${topic}`;
        return `${authUser?.documentId}.${USER_TYPE.BOT}`;
      } else {
        return `${creatorProfileData?.documentId}.${authUser?.documentId}`;
      }
    } else {
      return `${creatorProfileData?.documentId}.anonymous-user`;
    }
  };

  const currentRoomId = returnRoomId();

  const creatorCanUseVoice = useMemo(() => {
    return checkVoicePermissions(creatorProfileData);
  }, [creatorProfileData]);

  const scrollToBottom = useCallback(() => {
    setTimeout(
      () => scrollRef.current?.scrollIntoView({ behavior: 'auto' }),
      250
    );
  }, [scrollRef]);

  const handleSendMessageButtonClick = (
    messageVal,
    sendDisabled,
    setMessageVal,
    isTyping,
    handleMessageSend
  ) => {
    if (isTyping) {
      console.log('AI is typing. Please wait.');
      return;
    }

    const copiedText = window.getSelection()?.toString(); // Get the copied text
    const messageText = messageVal?.trim() || copiedText || ''; // Use copied text if available

    if (messageText) {
      handleMessageSend(messageText);
      sendDisabled.current = true;
      setMessageVal('');
    }
  };

  const bannerWrapperStyle: CSSProperties = {
    position: 'relative',
    zIndex: 25, // Ensures banner stays above overlay
    pointerEvents: 'auto', // Allow interaction with buttons
  };

  const setHandleSignInModal = (state: boolean) => {
    if (state) {
      setLoginModalVisible({
        isOpen: true,
        formType: 'signin',
        currentStep: 1,
      })
    } else {
      setLoginModalVisible({
        isOpen: false,
        formType: 'signin',
        currentStep: 1,
      })
    }
  }

  useEffect(() => {
    // on mount, flip use voice chat to true if necessary
    setUseVoiceChat(location?.state?.useVoiceChat || false);
  }, [location?.state?.useVoiceChat]);

  useEffect(() => {
    if (messages && isLoading) {
      setIsLoading(false);
      return;
    }

    if (
      !authLoading &&
      !isLoading &&
      !initialMessage &&
      !isAnonymousUser &&
      !aiTypingMessage &&
      !isTyping &&
      !!messages &&
      creatorProfileData?.documentId &&
      userName &&
      hasLoadedCreatorProfile()
    ) {
      setInitialMessage(true);
      if (!!location?.state?.message &&
        typeof location?.state?.message === 'string'
      ) {
        handleMessageSend(location?.state?.message, false);
      } else if (messages?.length === 0 && !useVoiceChat) {
        handleMessageSend(initialPrompt, true);
      }
    }

    if (isAnonymousUser && messages?.length >= 2 * anonymousChatLimit + 1) {
      if (isWidget) setHandleSignInModalWidget(true);
      else {
        if (!isStanfordUrl && !isSambaTvUrl) {
          location.state ||= {};
          location.state.redirectURL = location.pathname;
          setHandleSignInModal(true);
        } else {
          navigate('/signin');
        }
      }
      setAnonymousChat({
        creatorId: creatorProfileData.documentId,
        messages: messages,
      });
    }

    if (messages && showTopMessage && !startedTimeout.current) {
      startedTimeout.current = true;
      setTimeout(() => {
        setShowTopMessage(false);
      }, 5000);
    }
  }, [messages, isLoading, creatorProfileData]); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // Check if anonymous_chat_limit exists as a key for user database and update the local variable
    if (creatorProfileData?.subscription?.chat_widget && isWidget) {
      let limit =
        creatorProfileData.subscription.chat_widget.anonymous_chat_limit;
      if (typeof limit !== 'number') {
        limit = DEFAULT_ANONYMOUS_CHAT_LIMIT;
      }
      setAnonymousChatLimit(limit);
    } else {
      if (anonymousChatLimit !== DEFAULT_ANONYMOUS_CHAT_LIMIT) {
        setAnonymousChatLimit(DEFAULT_ANONYMOUS_CHAT_LIMIT);
      }
    }

    if (
      creatorProfileData?.normalizedUserName === userName &&
      ((authUser?.userType === USER_TYPE.FOLLOWER &&
        userName &&
        creatorProfileData?.documentId) ||
        (authUser?.userType === USER_TYPE.CREATOR &&
          creatorProfileData?.documentId))
    ) {
      fetchNextPage(); // refetch every time authUser or creatorProfileData is changed
    }
    if (creatorProfileData && enableDevTools) console.log(creatorProfileData);
  }, [creatorProfileData, authUser]); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (
      !authLoading &&
      !isLoading &&
      !initialMessage &&
      isAnonymousUser &&
      !authUser &&
      creatorProfileData?.documentId &&
      userName &&
      hasLoadedCreatorProfile()
    ) {
      setInitialMessage(true);
      if (!useVoiceChat) {
        if (!!location?.state?.message &&
          typeof location?.state?.message === 'string'
        ) {
          handleMessageSend(location.state.message, false);
        } else {
          handleMessageSend(initialPrompt, true);
        }
      }
      // setIsDemo(true);
    } else {
      // setIsDemo(false);
    }
  }, [isAnonymousUser, userName, creatorProfileData]); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    inputRef?.current?.focus();
  }, [isTyping]); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const storedChips = JSON.parse(
      localStorage.getItem(`roomChips_${currentRoomId}`) || '[]'
    );

    setRoomChips((prev) => ({
      ...prev,
      [currentRoomId]: storedChips,
    }));
  }, [currentRoomId]);

  const fetchNextPage = async () => {
    if (isLoading) {
      return;
    }

    setIsLoading(true);
    fetchMessagesData(returnRoomId())
      .then((querySnapshot) => {
        const nextPageDocuments: DocumentData[] = querySnapshot.docs.map(
          (doc: any) => ({
            id: doc.id,
            documentId: doc.id,
            ...doc.data(),
          })
        );
        setMessages([...nextPageDocuments.reverse()]);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        // To-do: retry after failure to fetch message history
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const convertMessageDataType = useCallback(
    (
      message: string,
      userType: string,
      userId: string,
      images?: string[],
      image_url?: string,
      image_urls?: string[],
      tempUserId?: number,
      isFinalMessage: boolean = false,
      documentId?: string,
      citations?: Citation[]
    ) => {
      const currentTimeStamp = Timestamp.fromDate(new Date());
      let messageData: MessageDataType = {
        senderId: userId,
        message,
        userType,
        sentTime: currentTimeStamp,
        direction: userType === USER_TYPE.BOT ? 'incoming' : 'outgoing',
        likeDislike: 0,
        roomId: currentRoomId,
        images: images ?? [],
        image_url: image_url ?? null,
        image_urls: image_urls ?? null,
        tempUserId: tempUserId ?? null,
        citations: citations ?? [],
      };
      if (documentId) {
        messageData.documentId = documentId;
      }
      return messageData;
    },
    [currentRoomId]
  );

  const saveMessages = useCallback(
    async (
      message: string,
      userType: string,
      userId: string,
      images?: string[],
      image_url?: string,
      image_urls?: string[],
      tempUserId?: number,
      isFinalMessage: boolean = false,
      documentId?: string,
      citations?: Citation[]
    ) => {
      const currentTimeStamp = Timestamp.fromDate(new Date());
      let messageData: MessageDataType = {
        senderId: userId,
        message,
        userType,
        sentTime: currentTimeStamp,
        direction: userType === USER_TYPE.BOT ? 'incoming' : 'outgoing',
        likeDislike: 0,
        roomId: currentRoomId,
        images: images ?? [],
        image_url: image_url ?? null,
        image_urls: image_urls ?? null,
        tempUserId: tempUserId ?? null,
        citations: citations ?? [],
        widget_message: isWidget,
      };

      if (userType === USER_TYPE.BOT && !isFinalMessage) {
        // AI typing simulation (not the final AI message)
        setMessages((prevMessages) => {
          let existingIndex = -1;
          if (prevMessages) {
            existingIndex = prevMessages.findIndex(
              (m) => m.tempUserId === tempUserId
            );
          }
          if (existingIndex !== -1) {
            // Update the existing AI message with new content
            const updatedMessages = [...prevMessages];
            updatedMessages[existingIndex].message = message;
            updatedMessages[existingIndex].documentId = documentId;
            return updatedMessages;
          } else {
            // Add as a new AI message (should rarely happen)
            return prevMessages
              ? [...prevMessages, { ...messageData, isAiTyping: true }]
              : [{ ...messageData, isAiTyping: true }];
          }
        });
      } else {
        // Save the message to the database for user messages and final AI message
        const saveMessageResponse = await saveMessagesData({
          ...messageData,
          sentTime: serverTimestamp(),
          isAiTyping: false,
        });

        // Update or add the saved message in state
        setMessages((prevMessages) => {
          prevMessages ||= [];
          if (tempUserId) {
            // Find and update the existing message with the saved message data
            return prevMessages.map((messageItem) =>
              messageItem.tempUserId === tempUserId
                ? {
                    ...messageItem,
                    ...saveMessageResponse,
                    documentId: saveMessageResponse.documentId,
                    sentTime: messageItem.sentTime,
                  }
                : messageItem
            );
          } else {
            // Add the saved message as a new message
            return [
              ...prevMessages,
              {
                ...saveMessageResponse,
                documentId: saveMessageResponse.documentId,
              },
            ];
          }
        });

        return saveMessageResponse.documentId;
      }
    },
    [isWidget, currentRoomId]
  );

  const isSelfChat = useMemo(
    () => authUser?.documentId === creatorProfileData?.documentId,
    [authUser, creatorProfileData]
  );

  const handleMessageSend = async (
    userMessage: string,
    firstMessage: boolean = false,
    isVoiceMessage: boolean = false
  ) => {
    // stop anonymous users from sending messages after limit
    if (isAnonymousUser && messages?.length >= 2 * anonymousChatLimit + 1) {
      if (isWidget) setHandleSignInModalWidget(true);
      else {
        if (!isStanfordUrl && !isSambaTvUrl) {
          location.state ||= {};
          location.state.redirectURL = location.pathname;
          setHandleSignInModal(true);
        } else {
          navigate('/signin');
        }
      }
      return;
    }

    setIsTyping(true);
    sendDisabled.current = true;
    const TempUserId = Date.now(); // Unique ID for the user's message
    const aiTempUserId = TempUserId + 1; // Unique ID for the AI's typing simulation

    try {
      // Save user message
      if (!firstMessage) {
        const userMessageData: MessageDataType = {
          senderId: authUser?.documentId || '',
          message: userMessage,
          userType: authUser?.userType || '',
          sentTime: Timestamp.fromDate(new Date()), // Convert to Firestore Timestamp if needed
          direction: 'outgoing',
          likeDislike: 0,
          roomId: returnRoomId(),
          tempUserId: TempUserId,
        };

        // Add user message to state
        setMessages((prevMessages) => {
          if (prevMessages) {
            return [...prevMessages, userMessageData]
          } else {
            return [userMessageData]
          }
        });

        // Save user message to database
        saveMessages(
          userMessage,
          authUser?.userType || '',
          authUser?.documentId || '',
          null,
          null,
          null,
          TempUserId,
          true, // Final message from the user
          null,
          null
        );

        if (!preloadedIndex) {
          if (prefetchPromise) {
            try {
              if (enableDevTools) {
                console.log('Still awaiting prefetch...');
              }
              await prefetchPromise;
              if (enableDevTools) {
                console.log('Prefetch complete, sending message now');
              }
            } catch (error) {
              console.log(error);
            }
          }
        }
      }

      let userData: SendMessageRequest = {
        index: authUser?.documentId,
        query: userMessage,
        room_id: returnRoomId(),
      };
      if (authUser?.normalizedUserName !== userName) {
        userData = {
          index: creatorProfileData?.documentId,
          query: userMessage,
          room_id: returnRoomId(),
        };
      }

      // Skip credit consumption for enterprise accounts
      if (isSambaTvUrl || isStanfordUrl || isWidget || requiredPremiumKey) {
        userData.skip_credits = true;
      }

      let response;
      if (enableIntroMessages) {
        if (firstMessage && !topic) {
          // Use intro message for first message
          response = await getIntroMessage(
            creatorProfileData?.documentId,
            false
          );
        } else {
          response = await sendMessageToSmartAssistant(
            userData,
            authUser?.userType
          );
        }
      } else {
        response = await sendMessageToSmartAssistant(
          userData,
          authUser?.userType
        );
      }

      if (response?.status === 200) {
        const messageResp: SendMessageResponse = await response.json();

        // track how messages are being sent
        if (isWidget) {
          if (isVoiceMessage) eventTracker('widget-message-voice');
          else eventTracker('widget-message-text');
        } else {
          if (isVoiceMessage) eventTracker('message-voice');
          else eventTracker('message-text');
        }

        const newChips = messageResp?.chips || [];

        setRoomChips((prev) => ({
          ...prev,
          [currentRoomId]: newChips,
        }));
        localStorage.setItem(
          `roomChips_${currentRoomId}`,
          JSON.stringify(newChips)
        );

        if (messageResp?.citations && messageResp.citations.length > 0) {
          setCitations(messageResp.citations);
        }

        const fullMessage = findAndReplaceLinks(messageResp?.response);
        const images = messageResp?.images || [];
        const image_url = messageResp?.image_url || null;
        const image_urls = messageResp?.image_urls || null;

        const senderId = creatorProfileData?.documentId;
        const citations = messageResp.citations;

        if (messageResp.decremented_credits) {
          if (useCredits) {
            setCredits((prev) => prev - messageResp.decremented_credits);
          }
        }

        if (isVoiceMessage || skipTypingAnimation) {
          const documentId = await saveMessages(
            fullMessage,
            USER_TYPE.BOT,
            senderId,
            images,
            image_url,
            image_urls,
            aiTempUserId,
            false,
            null,
            citations
          );
          await saveMessages(
            fullMessage,
            USER_TYPE.BOT,
            senderId,
            images,
            image_url,
            image_urls,
            aiTempUserId,
            true,
            documentId,
            citations
          );
          setIsTyping(false);
          sendDisabled.current = false;
          return fullMessage;
        }

        // Save the complete AI response before starting typing simulation
        const documentId = await saveMessages(
          fullMessage,
          USER_TYPE.BOT,
          senderId,
          images,
          image_url,
          image_urls,
          aiTempUserId,
          true,
          null,
          citations
        );

        setIsTyping(false);
        setAiTypingMessage(
          convertMessageDataType(
            fullMessage,
            USER_TYPE.BOT,
            senderId,
            images,
            image_url,
            image_urls,
            aiTempUserId,
            true,
            documentId,
            citations
          )
        );
      } else {
        setIsTyping(false);
        sendDisabled.current = false;

        setErrorCode(response?.status || 0);
        setShowTimeoutModal(true);
        throw new Error(`HTTP error, status = ${response?.status}`);
      }
    } catch (error) {
      eventTracker('message-send failed');
      console.log(error);
      setIsTyping(false);
      sendDisabled.current = false;
      setErrorCode(0); // Set as generic error
      setShowTimeoutModal(true);
    }
  };

  const handleChipClick = async (label: string) => {
    try {
      setRoomChips((prev) => ({
        ...prev,
        [currentRoomId]: [],
      }));
      localStorage.removeItem(`roomChips_${currentRoomId}`);

      const responseMessage = await handleMessageSend(label);

      if (responseMessage?.chips) {
        setRoomChips((prev) => ({
          ...prev,
          [currentRoomId]: responseMessage.chips,
        }));
        localStorage.setItem(
          `roomChips_${currentRoomId}`,
          JSON.stringify(responseMessage.chips)
        );
      }
    } catch (error) {
      console.error('Error handling chip click:', error);
    }
  };

  //handle like dislike message, if the new value and existing value are match the field value gets reset (i.e if user has already like a message and again user press like button the like/dislike value gets reset)
  const handleLikeDislikeMessage = async (
    documentId: string,
    value: number
  ) => {
    const updatedMsg = messages.map((messageItem: any) => {
      if (messageItem?.documentId === documentId) {
        let existingValue = messageItem.likeDislike;
        if (existingValue === value) {
          messageItem.likeDislike = 0;
          value = 0;
        } else {
          messageItem.likeDislike = value;
        }
      }
      return messageItem;
    });
    const updateMessageResp = await updateMessagesData(documentId, {
      likeDislike: value,
    });
    if (updateMessageResp?.documentId) {
      setMessages(updatedMsg);
    }
  };

  const handleFormDialogResponse = async (values) => {
    setIsFormSubmitting(true);
    const { documentId } = formData;
    const userResponse = values?.response;
    if (documentId && userResponse) {
      let userFormData: any = {};
      if (handleModal === 'feedback') {
        userFormData.feedback = userResponse;
      } else {
        userFormData.editedMessage = findAndReplaceLinks(userResponse);
        userFormData.question = returnUserQuesForEditedMsg(documentId);
        userFormData.is_indexed = false;
      }
      if (isAnonymousUser) {
        updateExistingMessages(documentId, findAndReplaceLinks(userResponse));
      } else {
        const updateMessageResp = await updateMessagesData(
          documentId,
          userFormData
        );
        if (handleModal === 'edit') {
          try {
            eventTracker('message-edit');
            editedMessageResponse({ message_id: documentId });
          } catch (e) {
            eventTracker('message-edit failed');
            throw e;
          }
        }
        if (updateMessageResp?.documentId) {
          updateExistingMessages(documentId, findAndReplaceLinks(userResponse));
        }
      }

      setIsFormSubmitting(false);
      if (handleModal === 'feedback') {
        toast.success('Feedback submitted');
      }
      handleFormDialogClose();
    }
  };

  const updateExistingMessages = (documentId: string, userResponse: any) => {
    const updatedMsg = messages.map((messageItem) => {
      if (messageItem?.documentId === documentId) {
        if (handleModal === 'feedback') {
          messageItem.feedback = userResponse;
        } else {
          messageItem.editedMessage = userResponse;
        }
      }
      return messageItem;
    });
    setMessages(updatedMsg);
  };

  const returnUserQuesForEditedMsg = (documentId: string) => {
    let question = '';
    const currentMsgInd = messages.findIndex(
      (msgItem) => msgItem.documentId === documentId
    );
    if (currentMsgInd !== -1) {
      for (let i = currentMsgInd - 1; i >= 0; i--) {
        if (messages[i]?.userType === USER_TYPE.CREATOR) {
          question = messages[i].message;
          break;
        }
      }
    }
    return question;
  };

  const handleFormDialogClose = () => {
    setShowActionMenu(null);
    setHandleModal(null);
    setFormData(null);
  };

  const handleShareButtonClick = useCallback(
    (selMessage: any) => {
      if (selectedMessages?.length >= 1) {
        const selMsgIndex = selectedMessages?.findIndex(
          (messageItem) => messageItem.documentId === selMessage?.documentId
        );
        if (selMsgIndex !== -1) {
          const updatedMsgs = selectedMessages;
          updatedMsgs.splice(selMsgIndex, 1);
          setSelectedMessages([...updatedMsgs]);
          return;
        }
      }
      setSelectedMessages([...(selectedMessages || []), selMessage]);
    },
    [selectedMessages]
  );

  const handleCurrentCheckboxChecked = (messageDocId: string) => {
    return selectedMessages?.some(
      (messageItem) => messageItem.documentId === messageDocId
    );
  };

  const resetMessageSelection = useCallback(() => {
    setSelectedMessages([]);
    setShowSelectMessages(false);
  }, []);

  const toggleVoiceChat = (newSetting: boolean) => {
    setUseVoiceChat(newSetting);
    if (isWidget) setWidgetVoiceMode(newSetting);
  };

  const handleClearChatClick = async () => {
    try {
      //This will disable the "Clear chat" button
      setClearChatLoading(true);

      const response = await clearChat(returnRoomId());
      eventTracker(`delete-conversation ${returnRoomId()}`);
      let data = await response.json();
      console.log(data?.message);

      //This will re-enable the "Clear chat" button
      setClearChatLoading(false);
      //Refreshing window
      window.location.reload();
    } catch (error) {
      console.error('API call error when deleting chat:', error);
      eventTracker(`delete-conversation failed ${returnRoomId()}`);
      setClearChatLoading(false);
    }
  };

  const showCheckPermissionsModal = () => {
      let data = {
        title: 'Check your permissions',
        message:
          'We noticed a problem. If your voice is not recording, please make sure you have given microphone permissions.',
        imgSrc: MicGuy,
        buttons: [
          {
            text: 'OK',
            action: () => {
              setShowGenericModal(false);
            },
            variant: 'contained',
          },
          {
            text: 'Email Soopra',
            action: () => {
              let link = `mailto:${CONTACT_US_MAIL}&subject=${encodeURIComponent(
                "Voice feature isn't working"
              )}`;
              window.location.href = link;
              setShowGenericModal(false);
            },
            variant: 'outlined',
          },
        ],
      };
      setGenericModalData(data);
      setShowGenericModal(true);
    };

  return (
    <Box
      ref={containerRef}
      position='relative'
      overflow='hidden'
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: {xs: 'auto', md: '100%'},
        flexGrow: 1,
        maxHeight: isWidget
          ? '100%'
          :
          (creatorProfileData?.userName === 'srinivasjaini' ||
          creatorProfileData?.userName === 'sortino-chat') ?
          { xs: 'calc(100% - 50px)', md: '100dvh' } :
          { xs: '100%', md: '100dvh' },
        overflowY: useVoiceChat ? 'auto' : 'hidden',
      }}
    >
      <TimeoutModal
        showTimeoutModal={showTimeoutModal}
        setShowTimeoutModal={setShowTimeoutModal}
        errorCode={errorCode}
      />
      <GenericModal
        showGenericModal={showGenericModal}
        setShowGenericModal={setShowGenericModal}
        data={genericModalData}
      />
      {useVoiceChat? (
        <VoiceChat
          handleMessageSend={handleMessageSend}
          creatorProfileData={creatorProfileData}
          handleLikeDislikeMessage={handleLikeDislikeMessage}
          messageDataItem={messages ? messages[messages.length - 1] : null}
          toggleVoiceChat={toggleVoiceChat}
          useVoiceChat={useVoiceChat}
          setErrorCode={setErrorCode}
          setShowTimeoutModal={setShowTimeoutModal}
          showCheckPermissionsModal={showCheckPermissionsModal}
          isWidget={isWidget}
          requiredPremiumKey={requiredPremiumKey}
          premiumAccess={premiumAccess}
          shouldStopAudio={shouldStopAudio}
          setShouldStopAudio={setShouldStopAudio}
        />
      ) : (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            flexGrow: 1,
            height: '100%',
            maxHeight: isWidget ? '100%' : '100%',
            overflowY: useVoiceChat ? 'auto' : 'hidden',
            maxWidth:
              !isSambaTvUrl && !isStanfordUrl && !isWidget
                ? '1080px'
                : 'none',
            width: '100%',
          }}
        >
          <Box
            className='messages-container'
            sx={{
              flexGrow: 1,
            }}
          >
            <MessageList
              loading={!messages}
              autoScrollToBottom={true} //not changed for widget
              scrollBehavior='auto' //not changed for widget
              style={{
                //height: 'calc(100vh - 60px - 24px - 60px)',
                height: '100%',
                backgroundColor: theme.palette.background.default,
                paddingLeft: isMobile ? '0px' : '4px',
                paddingRight: isMobile ? '-4px' : '0px',
              }}
            >
              <MessageList.Content
                className={isWidget ? 'chat-widget-content-window' : ''}
                style={{
                  minHeight: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                {isWidget ? (
                  <CollapseHeaderMessage
                    isOpen={!(messages || aiTypingMessage)}
                  >
                    <Box
                      display='flex'
                      flexDirection='column'
                      textAlign='left'
                      zIndex={1}
                      alignItems='center'
                      width='100%'
                      height='100%'
                      justifyContent='center'
                      bgcolor={'#fff'}
                      sx={{
                        top: 0,
                        overflowY: 'hidden',
                        overflowX: 'hidden',
                        position: showTopMessage ? 'absolute' : 'sticky',
                      }}
                    >
                      <UserInfoHeader
                        userData={creatorProfileData}
                        isSambaTvUrl={isSambaTvUrl}
                        isStanfordUrl={false}
                        isWidget={isWidget}
                      />
                    </Box>
                  </CollapseHeaderMessage>
                ) : (
                  <></>
                )}
                {
                  <Box>
                    {(!messages || messages?.length <= 0) &&
                    !aiTypingMessage &&
                    creatorProfileData ? (
                      isWidget ? (
                        <></>
                      ) : (
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flex: 1,
                          }}
                        >
                          <Avatar
                            alt={creatorProfileData?.fullName}
                            src={creatorProfileData?.profilePhotoURL}
                            sx={{
                              m: 1,
                              width: 100,
                              height: 100,
                              marginBottom: '1rem',
                              fontSize: '3.125rem',
                            }}
                          >
                            {creatorProfileData?.fullName
                              ? creatorProfileData?.fullName[0]
                              : ''}
                          </Avatar>
                          <Typography
                            fontSize={16}
                            fontWeight={400}
                          >{`Start chat with ${creatorProfileData?.fullName}`}</Typography>
                        </Box>
                      )
                    ) : (
                      messages?.length >= 1 &&
                      messages?.map(
                        (
                          messageDataItem: MessageDataType,
                          messageIndex: number
                        ) => (
                          <Box key={messageIndex}>
                            {(messageIndex === 0 ||
                              moment(
                                typeof messages[messageIndex - 1]
                                  ?.sentTime === 'object'
                                  ? messages[
                                      messageIndex - 1
                                    ]?.sentTime?.toDate?.()
                                  : messages[messageIndex - 1]?.sentTime
                              ).format('DD-MM-YYYY') !==
                                moment(
                                  typeof messageDataItem?.sentTime ===
                                    'object'
                                    ? messageDataItem?.sentTime?.toDate?.()
                                    : messageDataItem?.sentTime
                                ).format('DD-MM-YYYY')) && (
                              <MessageSeparator
                                content={moment(
                                  typeof messageDataItem?.sentTime ===
                                    'object'
                                    ? messageDataItem?.sentTime?.toDate?.()
                                    : messageDataItem?.sentTime
                                ).format('DD MMMM YYYY')}
                                style={{
                                  overflowY: 'auto',
                                  backgroundColor:
                                    theme.palette.background.default,
                                  color: theme.palette.text.primary,
                                  marginTop: '8px',
                                  marginBottom: '8px',
                                  paddingTop: '2px',
                                  paddingBottom: '2px',
                                }}
                              />
                            )}
                            <Box display='flex'>
                              {showSelectMessages && (
                                <Checkbox
                                  onChange={(event) =>
                                    handleShareButtonClick(messageDataItem)
                                  }
                                  checked={handleCurrentCheckboxChecked(
                                    messageDataItem?.documentId
                                  )}
                                  sx={{
                                    '&.MuiButtonBase-root.MuiCheckbox-root':
                                      {
                                        height: 'fit-content',
                                        alignSelf: 'center',
                                      },
                                  }}
                                  icon={<RadioButtonUncheckedIcon />}
                                  checkedIcon={
                                    <RadioButtonCheckedIcon
                                      sx={{ color: '#3DA7E7' }}
                                    />
                                  }
                                />
                              )}
                              <MessageItem
                                isSelfChat={isSelfChat}
                                messageDataItem={messageDataItem}
                                citations={citations.filter(
                                  (citation) =>
                                    citation.messageId ===
                                    messageDataItem.documentId
                                )}
                                creatorUserData={creatorProfileData}
                                authUserData={authUser}
                                handleLikeDislikeMessage={
                                  handleLikeDislikeMessage
                                }
                                indexKey={`message-${
                                  messageDataItem?.documentId ||
                                  messageIndex
                                }`}
                                showActionMenu={showActionMenu}
                                setShowActionMenu={setShowActionMenu}
                                handleModal={handleModal}
                                setHandleModal={setHandleModal}
                                formData={formData}
                                setFormData={setFormData}
                                userName={userName}
                                handleShareButtonClick={
                                  handleShareButtonClick
                                }
                                showSelectMessages={showSelectMessages}
                                setShowSelectMessages={
                                  setShowSelectMessages
                                }
                                resetMessageSelection={
                                  resetMessageSelection
                                }
                                scrollToBottom={scrollToBottom}
                                isWidget={isWidget}
                              />
                            </Box>
                          </Box>
                        )
                      )
                    )}

                    {isWidget ? (
                      <></>
                    ) : (
                      <CustomTypingIndicator
                        isTyping={isTyping}
                        avatarImage={`${creatorProfileData?.profilePhotoURL}`}
                        fullName={`${creatorProfileData?.fullName}`}
                      />
                    )}

                    {/* AI Typing animation */}
                    {aiTypingMessage && (
                      <TypingAnimation
                        isSelfChat={isSelfChat}
                        messageDataItem={aiTypingMessage}
                        citations={citations?.filter(
                          (citation) =>
                            citation.messageId ===
                            aiTypingMessage.documentId
                        )}
                        creatorUserData={creatorProfileData}
                        authUserData={authUser}
                        handleLikeDislikeMessage={handleLikeDislikeMessage}
                        indexKey={`message-typing`}
                        showActionMenu={showActionMenu}
                        setShowActionMenu={setShowActionMenu}
                        handleModal={handleModal}
                        setHandleModal={setHandleModal}
                        formData={formData}
                        setFormData={setFormData}
                        userName={userName}
                        handleShareButtonClick={handleShareButtonClick}
                        showSelectMessages={showSelectMessages}
                        setShowSelectMessages={setShowSelectMessages}
                        resetMessageSelection={resetMessageSelection}
                        setAiTypingMessage={setAiTypingMessage}
                        setMessages={setMessages}
                        sendDisabled={sendDisabled}
                        isWidget={isWidget}
                      />
                    )}
                  </Box>
                }
                {enableMonetization &&
                  !isSambaTvUrl &&
                  !isStanfordUrl &&
                  !isWidget &&
                  !isAnonymousUser &&
                  !isSelfChat &&
                  !authLoading &&
                  (requiredPremiumKey && !premiumAccess ? (
                    <>
                      <Box
                        sx={{
                          position: 'fixed',
                          top: {xs: 50, md: 0},
                          left: 0,
                          width: '100%',
                          height: '100%',
                          backgroundColor: `${theme.palette.action.imagehover}`,
                          zIndex: 24,
                          pointerEvents: 'auto',
                        }}
                      ></Box>
                      <div style={bannerWrapperStyle}>
                        <PremiumPurchaseButton
                          requiredPremiumKey={requiredPremiumKey}
                          redirectURL={`/${userName}/chat`}
                        />
                      </div>
                    </>
                  ) : !requiredPremiumKey && credits === 0 ? (
                    <>
                      <Box
                        sx={{
                          position: 'fixed',
                          top: {xs: 50, md: 0},
                          left: 0,
                          width: '100%',
                          height: '100%',
                          backgroundColor: `${theme.palette.action.imagehover}`,
                          zIndex: 24,
                          pointerEvents: 'auto',
                        }}
                      ></Box>
                      <div style={bannerWrapperStyle}>
                        {authUser?.isSubscribed ? (
                          <AdditionalCreditsButton
                            redirectURL={`/${userName}/chat`}
                          />
                        ) : (
                          <SubscribeButton
                            redirectURL={`/${userName}/chat`}
                          />
                        )}
                      </div>
                    </>
                  ) : (
                    <></>
                  ))
                }
                {
                  !isWidget &&
                    roomChips[returnRoomId()] &&
                    roomChips[returnRoomId()].length > 0 &&
                    !isTyping &&
                    !aiTypingMessage && (
                      <Box
                        sx={{
                          flexGrow: 1,
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'flex-end',
                        }}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            gap: '5px',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '100%',
                            m: '8px auto',
                            overflow: 'hidden',
                          }}
                        >
                          {roomChips[returnRoomId()].map((chip, index) => (
                            <Button
                              key={index}
                              variant='outlined'
                              onClick={() => handleChipClick(chip)}
                              sx={{
                                borderRadius: '20px',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                textTransform: 'none',
                                fontSize: '0.75rem',
                                flexShrink: 1,
                                minWidth: 0,
                                margin: 'auto 0',
                                maxHeight: '30px',
                                fontWeight: 'bold',
                                color: (theme) => theme.palette.text.primary,
                                backgroundColor: (theme) =>
                                  `${theme.palette.action.selected} !important`,
                                border: 'none !important',
                              }}
                            >
                              <Box
                                sx={{
                                  whiteSpace: 'nowrap',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  // maxWidth: 'calc(100% - 16px)'
                                }}
                              >
                                {chip}
                              </Box>
                            </Button>
                          ))
                        }
                        </Box>
                      </Box>
                  )
                }

                <div ref={scrollRef}></div>
              </MessageList.Content>
            </MessageList>
          </Box>

          {showSelectMessages && (
            <ShareMessagesSection
              containerRef={containerRef}
              selectedMessages={selectedMessages}
              resetMessageSelection={resetMessageSelection}
              creatorProfileData={creatorProfileData}
            />
          )}

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column', // Align children vertically
              justifyContent: 'flex-end', // Push content to the bottom
              width: '100%',
            }}
          >
            {isWidget ? (
              <CustomTypingIndicator
                isTyping={isTyping}
                avatarImage={`${creatorProfileData?.profilePhotoURL}`}
                fullName={`${creatorProfileData?.fullName}`}
                isWidget
              />
            ) : (
              <></>
            )}
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
                background: isWidget ? 'white' : '',
              }}
              className={isWidget ? 'text-input-chat-widget' : ''}
            >
              {enableVoiceFeature && isDesktop &&
              browserSupportsSpeechRecognition &&
              (creatorCanUseVoice ||
                (isWidget && creatorProfileData?.voiceId)) ? (
                <VoiceChatToggle
                  toggleVoiceChat={toggleVoiceChat}
                  useVoiceChat={useVoiceChat}
                />
              ) : isSelfChat && isDesktop &&
                !(isWidget && !creatorProfileData?.voiceId) ? (
                <VoiceChatToggle
                  toggleVoiceChat={(newSetting: boolean) => {
                    if (checkPermission('use:voice')) {
                      toggleVoiceChat(newSetting);
                    }
                  }}
                  useVoiceChat={useVoiceChat}
                />
              ) : (
                <></>
              )}

              {isWidget ? (
                <>
                  <MessageInput
                    id='text-input-chat-widget-message'
                    sendDisabled={sendDisabled.current}
                    sendButton={false}
                    ref={inputRef}
                    value={messageVal}
                    onChange={(val) => {
                      sendDisabled.current = val.length === 0;
                      setMessageVal(val);
                    }}
                    onKeyDown={(e) => {
                      if (
                        e.key === 'Enter' &&
                        sendDisabled.current &&
                        !e.shiftKey
                      ) {
                        e.preventDefault();
                      }
                    }}
                    onPaste={(e) => {
                      e.preventDefault();
                      if (e.clipboardData) {
                        let content = e.clipboardData.getData('text/plain');
                        sendDisabled.current =
                          (messageVal + content).length === 0;

                        setMessageVal(messageVal + content);
                      }
                    }}
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      padding: '0.6rem',
                      width: '60px',
                      flexGrow: '1',
                    }}
                    placeholder={`Ask ${creatorProfileData?.fullName}'s AI persona anything...`}
                    attachButton={false}
                    onSend={(innerHtml: string, textContent: string) => {
                      handleSendMessageButtonClick(
                        textContent,
                        sendDisabled,
                        setMessageVal,
                        isTyping || !!aiTypingMessage,
                        handleMessageSend
                      );
                    }}
                  />
                  <button
                    className={`cs-button--arrow ${
                      messageVal ? `cs-button--focussed` : ''
                    }`}
                    onClick={() =>
                      handleSendMessageButtonClick(
                        messageVal,
                        sendDisabled,
                        setMessageVal,
                        isTyping || !!aiTypingMessage,
                        handleMessageSend
                      )
                    }
                  >
                    <svg
                      width='8'
                      height='10'
                      viewBox='0 0 8 10'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M0.159096 3.95813L3.61591 0.174153C3.82804 -0.05805 4.17196 -0.05805 4.38409 0.174154L7.8409 3.95813C8.05303 4.19033 8.05303 4.56681 7.8409 4.79901C7.62878 5.03122 7.28485 5.03122 7.07272 4.79901L4.54319 2.03007L4.54319 9.45681C4.54319 9.75681 4.29999 10 4 10C3.70001 10 3.45681 9.75681 3.45681 9.45681L3.45681 2.03007L0.927276 4.79901C0.715149 5.03122 0.371223 5.03122 0.159096 4.79901C-0.0530316 4.56681 -0.0530316 4.19033 0.159096 3.95813Z'
                        fill='white'
                      />
                    </svg>
                  </button>
                </>
              ) : (
                <TextChatInput
                  sendDisabled={sendDisabled}
                  disabled={
                    enableMonetization &&
                    !isSambaTvUrl &&
                    !isStanfordUrl &&
                    !isWidget &&
                    !isAnonymousUser &&
                    !isSelfChat &&
                    !authLoading &&
                    !isLoading &&
                    !!(
                      (!requiredPremiumKey && credits === 0) ||
                      (requiredPremiumKey && !premiumAccess)
                    )
                  }
                  isTyping={isTyping || !!aiTypingMessage}
                  handleMessageSend={handleMessageSend}
                />
              )}
            </Box>
          </Box>
        </Box>
      )}
      <UserResponseForm
        handleModal={handleModal}
        formData={formData}
        handleFormDialogClose={handleFormDialogClose}
        handleFormDialogResponse={handleFormDialogResponse}
        isFormSubmitting={isFormSubmitting}
      />
    </Box>
  )
}

export default MainChatInterface;
