import { SVGProps, memo } from 'react';
import { useTheme } from '@mui/material/styles';

const DeleteIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={props.width || '29'}
      height={props.height || '30'}
      viewBox='0 0 29 30'
      fill={props.fill || 'none'}
      stroke={props.stroke || 'none'}
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d="M3.625 7.75H6.04167M6.04167 7.75H25.375M6.04167 7.75V24.6667C6.04167 25.3076 6.29628 25.9223 6.74949 26.3755C7.2027 26.8287 7.81739 27.0833 8.45833 27.0833H20.5417C21.1826 27.0833 21.7973 26.8287 22.2505 26.3755C22.7037 25.9223 22.9583 25.3076 22.9583 24.6667V7.75M9.66667 7.75V5.33333C9.66667 4.69239 9.92128 4.0777 10.3745 3.62449C10.8277 3.17128 11.4424 2.91667 12.0833 2.91667H16.9167C17.5576 2.91667 18.1723 3.17128 18.6255 3.62449C19.0787 4.0777 19.3333 4.69239 19.3333 5.33333V7.75M12.0833 13.7917V21.0417M16.9167 13.7917V21.0417" strokeWidth="2.41667"/>
    </svg>
  );
};

export default memo(DeleteIcon);
